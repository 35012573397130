/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetCampaignSupporterDataRequest} from '@cash-web/protos/squareup/franklin/app/campaign_supporter_data.pb'
import {SetCampaignSupporterDataResponse} from '@cash-web/protos/squareup/franklin/app/campaign_supporter_data.pb'


export const setCampaignSupporterData = (request:SetCampaignSupporterDataRequest, options?: CashRequestOptions) => post<SetCampaignSupporterDataRequest, SetCampaignSupporterDataResponse>("/2.0/cash/set-campaign-supporter-data", request, {...options, rejectOnError: true })