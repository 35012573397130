/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResetAttemptLimitsByFactorRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {ResetAttemptLimitsByFactorResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const resetPastAttemptsByFactor = (request:ResetAttemptLimitsByFactorRequest, options?: CashRequestOptions) => post<ResetAttemptLimitsByFactorRequest, ResetAttemptLimitsByFactorResponse>("/2.0/cash/reset-past-attempts-by-factor", request, {...options, rejectOnError: true })