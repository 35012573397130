/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {TriggerCampaignRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {TriggerCampaignResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const triggerCampaign = (request:TriggerCampaignRequest, options?: CashRequestOptions) => post<TriggerCampaignRequest, TriggerCampaignResponse>("/2.0/cash/web/campaign", request, {...options, rejectOnError: true })