/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SkipBlockerRequest} from '@cash-web/protos/squareup/franklin/app/blocker.pb'
import {SkipBlockerResponse} from '@cash-web/protos/squareup/franklin/app/blocker.pb'


export const skipBlocker = (request:SkipBlockerRequest, options?: CashRequestOptions) => post<SkipBlockerRequest, SkipBlockerResponse>("/2.0/cash/skip-blocker", request, {...options, rejectOnError: true })