import { CashRequestMiddleware, CashResponseMiddleware } from './types'

export const jsonSerializeRequest: CashRequestMiddleware = request => {
  request.body = JSON.stringify(request.data)
  // remove data from request
  delete request.data
  return request
}

export const jsonSerializeResponse: CashResponseMiddleware = response => {
  const contentType = response.headers.get('Content-Type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json()
  }
  return response.text()
}
