/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPasscodeAndSsnDetailsRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {GetPasscodeAndSsnDetailsResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const getPasscodeAndSsnDetails = (request:GetPasscodeAndSsnDetailsRequest, options?: CashRequestOptions) => post<GetPasscodeAndSsnDetailsRequest, GetPasscodeAndSsnDetailsResponse>("/2.0/cash/get-passcode-and-ssn-details", request, {...options, rejectOnError: true })