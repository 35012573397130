/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {TriggerWelcomeBonusRequest} from '@cash-web/protos/squareup/franklin/app/welcome_bonus.pb'
import {TriggerWelcomeBonusResponse} from '@cash-web/protos/squareup/franklin/app/welcome_bonus.pb'


export const triggerWelcomeBonus = (request:TriggerWelcomeBonusRequest, options?: CashRequestOptions) => post<TriggerWelcomeBonusRequest, TriggerWelcomeBonusResponse>("/2.0/cash/trigger-welcome-bonus", request, {...options, rejectOnError: true })