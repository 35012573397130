/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {MockSearchRequest} from '@cash-web/protos/squareup/cash/customersearch/api/MockSearch.pb'
import {MockSearchResponse} from '@cash-web/protos/squareup/cash/customersearch/api/MockSearch.pb'


export const mockSearch = (request:MockSearchRequest, options?: CashRequestOptions) => post<MockSearchRequest, MockSearchResponse>("/2.0/cash/mock-search", request, {...options, rejectOnError: true })