/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {HasBillsRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/HasBills.pb'
import {HasBillsResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/HasBills.pb'


export const hasBills = (request:HasBillsRequest, options?: CashRequestOptions) => post<HasBillsRequest, HasBillsResponse>("/2.0/cash/has-bills", request, {...options, rejectOnError: true })