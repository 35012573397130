/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishTutorialRequest} from '@cash-web/protos/squareup/franklin/app/tutorial.pb'
import {FinishTutorialResponse} from '@cash-web/protos/squareup/franklin/app/tutorial.pb'


export const finishTutorial = (request:FinishTutorialRequest, options?: CashRequestOptions) => post<FinishTutorialRequest, FinishTutorialResponse>("/2.0/cash/finish-tutorial", request, {...options, rejectOnError: true })