/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetPasscodeRequest} from '@cash-web/protos/squareup/franklin/SetPasscodeRequestProto.pb'
import {SetPasscodeResponse} from '@cash-web/protos/squareup/franklin/SetPasscodeResponseProto.pb'


export const setPasscode = (request:SetPasscodeRequest, options?: CashRequestOptions) => post<SetPasscodeRequest, SetPasscodeResponse>("/2.0/cash/set-passcode", request, {...options, rejectOnError: true })