/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyPasscodeAndExpirationRequest} from '@cash-web/protos/squareup/franklin/app/passcode.pb'
import {VerifyPasscodeAndExpirationResponse} from '@cash-web/protos/squareup/franklin/app/passcode.pb'


export const verifyPasscodeAndExpiration = (request:VerifyPasscodeAndExpirationRequest, options?: CashRequestOptions) => post<VerifyPasscodeAndExpirationRequest, VerifyPasscodeAndExpirationResponse>("/2.0/cash/verify-passcode-and-expiration", request, {...options, rejectOnError: true })