/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateSessionRequest} from '@cash-web/protos/squareup/franklin/app/initiateSession.pb'
import {InitiateSessionResponse} from '@cash-web/protos/squareup/franklin/app/initiateSession.pb'


export const initiateSession = (request:InitiateSessionRequest, options?: CashRequestOptions) => post<InitiateSessionRequest, InitiateSessionResponse>("/2.0/cash/initiate-session", request, {...options, rejectOnError: true })