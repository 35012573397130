/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ConfirmDisclosureRequest} from '@cash-web/protos/squareup/franklin/app/disclosure.pb'
import {ConfirmDisclosureResponse} from '@cash-web/protos/squareup/franklin/app/disclosure.pb'


export const confirmDisclosure = (request:ConfirmDisclosureRequest, options?: CashRequestOptions) => post<ConfirmDisclosureRequest, ConfirmDisclosureResponse>("/2.0/cash/confirm-disclosure", request, {...options, rejectOnError: true })