export const isUndefinedOrEmpty = (input?: string): input is undefined | '' => {
  return (input ?? '').trim().length === 0
}

/**
 * camel case a string
 *
 * @param {string} input
 * @returns {string}
 */
export const camelCase = (input: string): string => {
  if (isUndefinedOrEmpty(input)) {
    return ''
  }

  const wordRegex =
    /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g
  const inputArray = input.match(wordRegex) ?? []

  let result = ''
  for (let i = 0, len = inputArray.length; i < len; i++) {
    const currentStr = inputArray[i]
    let tempStr = currentStr.toLowerCase()
    if (i !== 0) {
      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1)
    }
    result += tempStr
  }

  return result
}

// Note: waiting on native uuid support https://github.com/WICG/uuid
export const uuidv4 = (): string =>
  // @ts-expect-error - borrowed code from https://github.com/WICG/uuid
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    (c ^ (window?.crypto?.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
