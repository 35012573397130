/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SelectParticipantRequest} from '@cash-web/protos/squareup/franklin/app/participant.pb'
import {SelectParticipantResponse} from '@cash-web/protos/squareup/franklin/app/participant.pb'


export const selectParticipants = (request:SelectParticipantRequest, options?: CashRequestOptions) => post<SelectParticipantRequest, SelectParticipantResponse>("/2.0/cash/select-participants", request, {...options, rejectOnError: true })