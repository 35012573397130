/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SubmitInvestmentEntitySelectionRequest} from '@cash-web/protos/squareup/cash/investflow/flows/actions.pb'
import {SubmitInvestmentEntitySelectionResponse} from '@cash-web/protos/squareup/cash/investflow/flows/actions.pb'


export const submitInvestmentEntitySelection = (request:SubmitInvestmentEntitySelectionRequest, options?: CashRequestOptions) => post<SubmitInvestmentEntitySelectionRequest, SubmitInvestmentEntitySelectionResponse>("/2.0/cash/submit-investment-entity-selection", request, {...options, rejectOnError: true })