/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPortfoliosHistoricalDataRequest} from '@cash-web/protos/squareup/cash/portfolios/service.pb'
import {GetPortfoliosHistoricalDataResponse} from '@cash-web/protos/squareup/cash/portfolios/service.pb'


export const getPortfoliosHistoricalData = (request:GetPortfoliosHistoricalDataRequest, options?: CashRequestOptions) => post<GetPortfoliosHistoricalDataRequest, GetPortfoliosHistoricalDataResponse>("/2.0/cash/investing/get-portfolios-historical-data", request, {...options, rejectOnError: true })