/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ReportClientActivityPageDisplayedRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/verification.pb'
import {ReportClientActivityPageDisplayedResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/verification.pb'


export const reportClientActivityPageDisplayed = (request:ReportClientActivityPageDisplayedRequest, options?: CashRequestOptions) => post<ReportClientActivityPageDisplayedRequest, ReportClientActivityPageDisplayedResponse>("/2.0/cash/report-client-activity-page-displayed", request, {...options, rejectOnError: true })