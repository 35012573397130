/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AddAccountToAccountHolderRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {AddAccountToAccountHolderResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const addAccountToAccountHolderInternal = (request:AddAccountToAccountHolderRequest, options?: CashRequestOptions) => post<AddAccountToAccountHolderRequest, AddAccountToAccountHolderResponse>("/2.0/cash/add-account-to-account-holder-internal", request, {...options, rejectOnError: true })