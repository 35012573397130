/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCashtagProfileRequest} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'
import {GetCashtagProfileResponse} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'


export const getCashtagProfile = (request:GetCashtagProfileRequest, options?: CashRequestOptions) => post<GetCashtagProfileRequest, GetCashtagProfileResponse>("/2.0/cash/get-cashtag-profile", request, {...options, rejectOnError: true })