/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetAppLockActivatedRequest} from '@cash-web/protos/squareup/franklin/app/app_lock.pb'
import {SetAppLockActivatedResponse} from '@cash-web/protos/squareup/franklin/app/app_lock.pb'


export const setAppLockActivated = (request:SetAppLockActivatedRequest, options?: CashRequestOptions) => post<SetAppLockActivatedRequest, SetAppLockActivatedResponse>("/2.0/cash/set-app-lock-activated", request, {...options, rejectOnError: true })