/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetNotificationPreferenceRequest} from '@cash-web/protos/squareup/franklin/SetNotificationPreferenceRequestProto.pb'
import {SetNotificationPreferenceResponse} from '@cash-web/protos/squareup/franklin/SetNotificationPreferenceResponseProto.pb'


export const setNotificationPreference = (request:SetNotificationPreferenceRequest, options?: CashRequestOptions) => post<SetNotificationPreferenceRequest, SetNotificationPreferenceResponse>("/2.0/cash/set-notification-preference", request, {...options, rejectOnError: true })