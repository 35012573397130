/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifySmsRequest} from '@cash-web/protos/squareup/franklin/app/sms.pb'
import {VerifySmsResponse} from '@cash-web/protos/squareup/franklin/app/sms.pb'


export const verifySms = (request:VerifySmsRequest, options?: CashRequestOptions) => post<VerifySmsRequest, VerifySmsResponse>("/2.0/cash/verify-sms", request, {...options, rejectOnError: true })