/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateLightningWithdrawalRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'
import {InitiateLightningWithdrawalResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'


export const initiateLightningWithdrawal = (request:InitiateLightningWithdrawalRequest, options?: CashRequestOptions) => post<InitiateLightningWithdrawalRequest, InitiateLightningWithdrawalResponse>("/2.0/cash/initiate-lightning-withdrawal", request, {...options, rejectOnError: true })