/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillCustomerBlocksRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillCustomerBlocks.pb'
import {BackfillCustomerBlocksResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillCustomerBlocks.pb'


export const backfillCustomerBlocks = (request:BackfillCustomerBlocksRequest, options?: CashRequestOptions) => post<BackfillCustomerBlocksRequest, BackfillCustomerBlocksResponse>("/2.0/cash/backfill-customer-blocks", request, {...options, rejectOnError: true })