/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyGovernmentIdRequest} from '@cash-web/protos/squareup/franklin/app/government_id.pb'
import {VerifyGovernmentIdResponse} from '@cash-web/protos/squareup/franklin/app/government_id.pb'


export const verifyGovernmentId = (request:VerifyGovernmentIdRequest, options?: CashRequestOptions) => post<VerifyGovernmentIdRequest, VerifyGovernmentIdResponse>("/2.0/cash/verify-government-id", request, {...options, rejectOnError: true })