/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetWebLoginConfigInternalRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {GetWebLoginConfigInternalResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const getWebLoginConfigInternal = (request:GetWebLoginConfigInternalRequest, options?: CashRequestOptions) => post<GetWebLoginConfigInternalRequest, GetWebLoginConfigInternalResponse>("/2.0/cash/get-web-login-config-internal", request, {...options, rejectOnError: true })