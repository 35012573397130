/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAccountsRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {GetAccountsResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const getAccounts = (request:GetAccountsRequest, options?: CashRequestOptions) => post<GetAccountsRequest, GetAccountsResponse>("/cash-app/security/1.0/get-accounts", request, {...options, rejectOnError: true })