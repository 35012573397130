import { useCallback, useEffect, useState } from 'react'

import { Profile } from '@cash-web/protos/squareup/franklin/ProfileProto.pb'
import { useOptionalAuth, useOptionalCustomerToken } from '@cash-web/shared-auth-data-access-context'
import { useCanTrack, useRegion } from '@cash-web/shared-data-access-region-provider'
import { logEvent as logCdfEvent } from '@cash-web/shared-util-cdf-analytics'
import { trackRiskSignal } from '@cash-web/shared-util-risk-signals'
import type { Event as CdfEvent } from '@squareup/customer-data-events-web'

declare const CDF_APPLICATION_NAME: string

function trackRiskSignalOnce({ customerToken }: { customerToken?: string }) {
  const RISK_SIGNAL_TRACKED = 'riskSignalTracked'

  // We leverage session storage to send risk signal tracking once per browser session for authenticated users
  if (customerToken != null && window.sessionStorage.getItem(RISK_SIGNAL_TRACKED) === null) {
    trackRiskSignal()
    window.sessionStorage.setItem(RISK_SIGNAL_TRACKED, 'true')
  }
}

function useResolvedAuth() {
  const auth = useOptionalAuth()

  const [pendingProfile] = useState(() => {
    let resolve: (data: Profile | undefined) => void
    const promise = new Promise<Profile | undefined>(res => {
      resolve = res
    })
    // We can be reasonably sure this is defined since we know the `new Promise` callback runs synchronously.
    // This is essentially equivalent to `Promise.withResolvers()` but lacks necessary browser support at this time.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { promise, resolve: resolve! }
  })

  useEffect(() => {
    if (!auth?.isLoading) {
      pendingProfile.resolve(auth?.profile)
    }
  }, [pendingProfile, auth])

  return {
    getProfile: useCallback(() => pendingProfile.promise, [pendingProfile]),
  }
}

/**
 * Sends events to CDP when allowed with environmental context.
 *
 * To optionally have `customer_token` included, be sure to render within an `<AuthProvider />`.
 */
export function useLogCashWebCdfEvent({
  riskSignalEnabled,
  waitForProfile = true,
  hostName,
}: {
  riskSignalEnabled?: boolean
  waitForProfile?: boolean
  hostName?: string
} = {}) {
  const canTrackRegion = useCanTrack()
  const region = useRegion()
  const optionalCustomerToken = useOptionalCustomerToken()
  const { getProfile } = useResolvedAuth()

  if (riskSignalEnabled) {
    trackRiskSignalOnce({ customerToken: optionalCustomerToken })
  }

  return useCallback(
    async (event: CdfEvent) => {
      const applicationName = CDF_APPLICATION_NAME
      if (canTrackRegion) {
        let profile

        if (optionalCustomerToken === undefined) {
          // Waits for the profile to be loaded (when available) before attempting to track event.
          // Otherwise, we run the risk of logging an "authed" event without a customer token.
          profile = waitForProfile ? await getProfile() : undefined
        }

        await logCdfEvent({
          applicationName,
          event,
          region,
          customerToken: optionalCustomerToken ?? profile?.customer_token,
          hostName,
        })
      }
    },
    [waitForProfile, canTrackRegion, region, getProfile, optionalCustomerToken, hostName]
  )
}
