import { com } from '@squareup/money-formatter'
const Money = com.squareup.cash.common.moneyformatter.Money
const FORMATTER = com.squareup.cash.common.moneyformatter

export interface MoneyUtil {
  formatMoney: (
    amount?: number,
    currency?: com.squareup.cash.common.moneyformatter.currency.Currency,
    formatterType?: formatterType
  ) => string
}

interface Props {
  locale?: string
  currency?: com.squareup.cash.common.moneyformatter.currency.Currency
}

type formatterType = 'STANDARD' | 'COMPACT'

export const moneyFormatter = (props?: Props): MoneyUtil => {
  const formatMoney = (
    amount = 0,
    currency?: com.squareup.cash.common.moneyformatter.currency.Currency,
    formatterType: formatterType = 'STANDARD'
  ): string => {
    const amountAndCurrency = Money.fromBigInt(
      BigInt(amount),
      currency || com.squareup.cash.common.moneyformatter.currency.Currency.USD
    )

    const formatter = formatterType === 'STANDARD' ? FORMATTER.STANDARD : FORMATTER.COMPACT

    const resolvedLocale = props?.locale ? props?.locale : 'en-US'
    return formatter.format(amountAndCurrency, resolvedLocale)
  }

  return { formatMoney }
}

const Currency = com.squareup.cash.common.moneyformatter.currency.Currency

export { Currency }
