/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ReconnectBillFlowRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/ReconnectBillFlow.pb'
import {ReconnectBillFlowResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/ReconnectBillFlow.pb'


export const reconnectBill = (request:ReconnectBillFlowRequest, options?: CashRequestOptions) => post<ReconnectBillFlowRequest, ReconnectBillFlowResponse>("/2.0/cash/reconnect-bill", request, {...options, rejectOnError: true })