import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import convert from 'color-convert'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export type DimmerVariant = 'darken' | 'blend'

export interface DimmerProps {
  variant?: DimmerVariant
}

const Dimmer = styled.div<DimmerProps>`
  width: 100%;
  inset: 0 0 0 0;
  position: fixed;
  z-index: 99999;
  animation: ${fadeIn} 150ms forwards;
  display: flex;
  flex-direction: column;
  background: rgb(0 0 0 / 45%);

  ${props =>
    props.variant === 'blend' &&
    css`
      background: rgba(${convert.hex.rgb(props.theme.colors.background).join(',')}, 90%);
    `}
`

export default Dimmer
