import { UAParser } from 'ua-parser-js'

interface DeviceInfo {
  os: {
    name?: string
    version?: string
  }
  browser: {
    major?: string
    name?: string
    version?: string
  }
  device: {
    model?: string
    type?: string
    vendor?: string
  }
}

export const getDeviceInfo = (): DeviceInfo => {
  const parser = new UAParser()
  const device = parser.getDevice()
  const browser = parser.getBrowser()
  const os = parser.getOS()

  return {
    os,
    device,
    browser,
  }
}

export const isCashAppWebView = (): boolean => {
  const parser = new UAParser()
  const userAgent = parser.getUA()

  // Logic taken from sq-cash-frontend: https://github.com/squareup/sq-cash-frontend/blob/907dc6f0c973b27f699a9681457a1af188736d26/app/utils/ua-detector.js#L114
  // and combined with Taxes logic: https://github.com/squareup/tax_web-fe/blob/a9b57d16e48e1c649d33f872228770ba26d26f65/libs/common/src/utils/browserChecker.ts#L11
  return /cash app webview|Cash App|com\.squareup\.cash|cashapp/i.test(userAgent)
}

export const isMobileDevice = () => {
  const { device } = getDeviceInfo()
  return device.type === 'mobile'
}

export const isAndroid = () => {
  const { os } = getDeviceInfo()
  return !!os.name?.match(/android/i)
}
