import * as tokens from '@cash-design-system/tokens'

import { ThemeColorsOverride } from './colors'

const violetPalette = {
  ColorPaletteViolet5: '#EDEBFF',
  ColorPaletteViolet10: '#DDD6FF',
  ColorPaletteViolet20: '#CFC4FF',
  ColorPaletteViolet30: '#AF9CFF',
  ColorPaletteViolet35: '#A18AFF',
  ColorPaletteViolet40: '#9378FF',
  ColorPaletteViolet50: '#784fff',
  ColorPaletteViolet60: '#5D00E8',
  ColorPaletteViolet70: '#4B00BA',
  ColorPaletteViolet75: '#3E0099',
  ColorPaletteViolet80: '#32007A',
  ColorPaletteViolet90: '#250059',
} as const

const taxesColors = {
  primary: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  primaryBannerBackground: {
    light: violetPalette.ColorPaletteViolet70,
    dark: violetPalette.ColorPaletteViolet80,
  },
  primaryButtonBackground: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  primaryChart: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  secondaryChart: {
    light: tokens.ColorPaletteGray85,
    dark: tokens.ColorPaletteCobalt20,
  },
  tertiaryButtonTint: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  outlineButtonSelectedBorder: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  bannerTextSuccess: {
    light: violetPalette.ColorPaletteViolet70,
    dark: violetPalette.ColorPaletteViolet30,
  },
  bannerBackgroundSuccess: {
    light: violetPalette.ColorPaletteViolet5,
    dark: violetPalette.ColorPaletteViolet90,
  },
  bannerButtonSuccess: {
    light: violetPalette.ColorPaletteViolet10,
    dark: violetPalette.ColorPaletteViolet80,
  },
  bannerButtonSuccessPressed: {
    light: violetPalette.ColorPaletteViolet20,
    dark: violetPalette.ColorPaletteViolet75,
  },
  bannerHairlineSuccess: {
    light: violetPalette.ColorPaletteViolet10,
    dark: violetPalette.ColorPaletteViolet80,
  },
  hairline: {
    light: tokens.ColorPaletteGray85,
    dark: tokens.ColorPaletteCobalt20,
  },
  progressBarFill: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  checkboxSelected: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  refundAmount: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  disabled: {
    light: violetPalette.ColorPaletteViolet40,
    dark: violetPalette.ColorPaletteViolet30,
  },
  taxesReviewAndFileBannerBackground: {
    light: violetPalette.ColorPaletteViolet70,
    dark: violetPalette.ColorPaletteViolet80,
  },
  taxesReviewAndFileBannerText: {
    light: tokens.ColorPaletteWhite,
    dark: tokens.ColorPaletteWhite,
  },
  taxesReviewAndFileBannerButtonTint: {
    light: violetPalette.ColorPaletteViolet70,
    dark: violetPalette.ColorPaletteViolet80,
  },
  taxesReviewAndFileBannerButtonBackground: {
    light: tokens.ColorPaletteWhite,
    dark: tokens.ColorPaletteWhite,
  },
  link: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  success: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  tooltip: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  taxesLogoIcon: {
    light: tokens.ColorPaletteBlack,
    dark: tokens.ColorIconDefaultDark,
  },
  taxesLogoInnerIcon: {
    light: 'background',
    dark: 'background',
  },
  taxesTimelineBubbleOutline: {
    light: violetPalette.ColorPaletteViolet10,
    dark: violetPalette.ColorPaletteViolet40,
  },
  inputFocusBorder: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  active: {
    light: violetPalette.ColorPaletteViolet75,
    dark: violetPalette.ColorPaletteViolet50,
  },
  primaryButtonActive: {
    light: violetPalette.ColorPaletteViolet75,
    dark: violetPalette.ColorPaletteViolet50,
  },
  primaryButtonHover: {
    light: violetPalette.ColorPaletteViolet50,
    dark: violetPalette.ColorPaletteViolet35,
  },
  headerIconBackground: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
  loginBackground: {
    light: tokens.ColorBackgroundSecondaryLight,
    dark: tokens.ColorBackgroundMainDark,
  },
  iconSuccess: {
    light: violetPalette.ColorPaletteViolet60,
    dark: violetPalette.ColorPaletteViolet40,
  },
} as ThemeColorsOverride

export default taxesColors
