/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RecommendSupportArticlesRequest} from '@cash-web/protos/squareup/cash/supportarticles/app/v1beta1/app.pb'
import {RecommendSupportArticlesResponse} from '@cash-web/protos/squareup/cash/supportarticles/app/v1beta1/app.pb'


export const recommendSupportArticles = (request:RecommendSupportArticlesRequest, options?: CashRequestOptions) => post<RecommendSupportArticlesRequest, RecommendSupportArticlesResponse>("/2.0/cash/recommend-support-articles", request, {...options, rejectOnError: true })