/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetIssuedCardRequest} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'
import {GetIssuedCardResponse} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'


export const getIssuedCard = (request:GetIssuedCardRequest, options?: CashRequestOptions) => post<GetIssuedCardRequest, GetIssuedCardResponse>("/2.0/cash/get-issued-card", request, {...options, rejectOnError: true })