import { v4 as uuidv4 } from 'uuid'

import { CashRequestMiddleware } from './types'

export function generateSpeleoTraceId() {
  return `WEB-${uuidv4()}`
}

export const speleoTraceIdMiddleware: CashRequestMiddleware = request => {
  const headers = request.headers as Record<string, string>

  headers['X-Speleo-Trace-Id'] = generateSpeleoTraceId()

  return request
}
