/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UnlinkBankAccountRequest} from '@cash-web/protos/squareup/franklin/app/bank_account.pb'
import {UnlinkBankAccountResponse} from '@cash-web/protos/squareup/franklin/app/bank_account.pb'


export const unlinkBankAccount = (request:UnlinkBankAccountRequest, options?: CashRequestOptions) => post<UnlinkBankAccountRequest, UnlinkBankAccountResponse>("/2.0/cash/unlink-bank-account", request, {...options, rejectOnError: true })