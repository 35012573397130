/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {HandleThreeDomainSecureRedirectRequest} from '@cash-web/protos/squareup/franklin/rpc/handle_three_domain_secure_redirect.pb'
import {HandleThreeDomainSecureRedirectResponse} from '@cash-web/protos/squareup/franklin/rpc/handle_three_domain_secure_redirect.pb'


export const handleThreeDomainSecureRedirect = (request:HandleThreeDomainSecureRedirectRequest, options?: CashRequestOptions) => post<HandleThreeDomainSecureRedirectRequest, HandleThreeDomainSecureRedirectResponse>("/2.0/cash/handle-three-domain-secure-redirect", request, {...options, rejectOnError: true })