/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillBusinessesRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillBusinesses.pb'
import {BackfillBusinessesResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillBusinesses.pb'


export const backfillBusinesses = (request:BackfillBusinessesRequest, options?: CashRequestOptions) => post<BackfillBusinessesRequest, BackfillBusinessesResponse>("/2.0/cash/backfill-businesses", request, {...options, rejectOnError: true })