import { Calculations } from '@cash-web/fetch-opaque-headers'

import { CashRequestMiddleware } from './types'

const toIsoString = (date: Date) => {
  const tzo = -date.getTimezoneOffset()

  const dif = tzo >= 0 ? '+' : '-'
  const pad = (num: number) => (num < 10 ? '0' : '') + num

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}

const getDateWithTimezone = () => {
  const timeNow = new Date()
  const isoString = toIsoString(timeNow)
  const tz = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || ''
  return isoString + ';;' + tz
}

export const signatureRequest: CashRequestMiddleware = async (request, context) => {
  const headers = request.headers as Record<string, string>
  headers['X-JS-ID'] = 'no'
  headers['Time-Zone'] = getDateWithTimezone()
  let path = context?.path ?? ''

  if (path.startsWith('https://')) {
    // if a full URL path is provided, we need to normalize it to just the relative path.
    // this can occur for apps like checkout that do use the `pay.cash.app` domain instead of the default `cash.app` domain.
    path = new URL(path).pathname // isolate the path value (removes the hostname + query params)
  }

  if (
    typeof window !== 'undefined' &&
    (window.location.host === 'cashstaging.app' || window.location.host === 'cash.app')
  ) {
    headers['Time-Zone-UTC'] = Date.now().toString()
  }

  Calculations.setContent(headers, path, request.body?.toString())
  return request
}
