/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CancelPaymentRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {CancelPaymentResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const cancelPayment = (request:CancelPaymentRequest, options?: CashRequestOptions) => post<CancelPaymentRequest, CancelPaymentResponse>("/2.0/cash/cancel-payment", request, {...options, rejectOnError: true })