/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillCustomerRelationshipsRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillCustomerRelationships.pb'
import {BackfillCustomerRelationshipsResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillCustomerRelationships.pb'


export const backfillCustomerRelationships = (request:BackfillCustomerRelationshipsRequest, options?: CashRequestOptions) => post<BackfillCustomerRelationshipsRequest, BackfillCustomerRelationshipsResponse>("/2.0/cash/backfill-customer-relationships", request, {...options, rejectOnError: true })