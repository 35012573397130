/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CompleteScenarioRequest} from '@cash-web/protos/squareup/franklin/app/scenario.pb'
import {CompleteScenarioResponse} from '@cash-web/protos/squareup/franklin/app/scenario.pb'


export const completeScenario = (request:CompleteScenarioRequest, options?: CashRequestOptions) => post<CompleteScenarioRequest, CompleteScenarioResponse>("/2.0/cash/complete-scenario", request, {...options, rejectOnError: true })