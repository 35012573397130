import { CashRequestMiddleware } from './types'

export const accessTokenRequest: CashRequestMiddleware = request => {
  //we should only add Authorization header if we don't have a csrf token
  //and only if we have an accessToken. I believe this is how we will get access
  //to a csrf token for requests that are executed with an authorization header.
  if (typeof window !== 'undefined' && window.accessToken && !window.csrfToken) {
    const headers = request.headers as Record<string, string>
    headers['Authorization'] = `Bearer ${window.accessToken}`
  }
  return request
}
