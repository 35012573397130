/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiatePaymentRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {InitiatePaymentResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const initiatePayment = (request:InitiatePaymentRequest, options?: CashRequestOptions) => post<InitiatePaymentRequest, InitiatePaymentResponse>("/2.0/cash/initiate-payment", request, {...options, rejectOnError: true })