/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UnlinkCardRequest} from '@cash-web/protos/squareup/franklin/app/card.pb'
import {UnlinkCardResponse} from '@cash-web/protos/squareup/franklin/app/card.pb'


export const unlinkCard = (request:UnlinkCardRequest, options?: CashRequestOptions) => post<UnlinkCardRequest, UnlinkCardResponse>("/2.0/cash/unlink-card", request, {...options, rejectOnError: true })