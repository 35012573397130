import { ClientScenario } from '@cash-web/protos/squareup/franklin/api/client_scenario.pb'
import logger from '@cash-web/shared/util-logger'

import { CashRequestMiddleware } from './types'

declare global {
  interface Window {
    recaptchaKeyId?: string
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void
        execute: (key: string, options: { action: string }) => Promise<string>
      }
    }
  }
}

const maxRetries = 3
const retryDelay = 500

const getToken = async (action: ClientScenario.ONBOARDING | ClientScenario.LOGIN, retry = 0) => {
  if (!window.recaptchaKeyId) {
    return undefined
  }

  try {
    return await window.grecaptcha.enterprise.execute(window.recaptchaKeyId, {
      action,
    })
  } catch (e) {
    if (retry === maxRetries) {
      logger.error(new Error(`Recaptcha failed after ${maxRetries} retries: ${e}`))
      return undefined
    } else {
      return await new Promise(r => setTimeout(() => r(getToken(action, retry + 1)), retryDelay))
    }
  }
}

export const recaptchaRequest: CashRequestMiddleware = async (request, context) => {
  if (typeof window === 'undefined' || !window.recaptchaKeyId || !window.grecaptcha || !context) {
    return request
  }

  const headers = request.headers as Record<string, unknown>
  const ClientScenarioHeader = headers['Cash-Client-Scenario']
  const isOnboarding = ClientScenarioHeader === ClientScenario.ONBOARDING
  const isLogin = ClientScenarioHeader === ClientScenario.LOGIN
  const isVerifyEmail = context.path === '/2.0/cash/verify-email'
  const isVerifySms = context.path === '/2.0/cash/verify-sms'

  if ((isOnboarding || isLogin) && (isVerifyEmail || isVerifySms)) {
    const token = await new Promise(resolve => {
      window.grecaptcha.enterprise.ready(() => resolve(getToken(ClientScenarioHeader)))
    })

    if (token) {
      headers['x-recaptcha-token'] = token
    }
  }

  return request
}
