/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResetAttemptLimitsRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {ResetAttemptLimitsResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const resetPastAttempts = (request:ResetAttemptLimitsRequest, options?: CashRequestOptions) => post<ResetAttemptLimitsRequest, ResetAttemptLimitsResponse>("/2.0/cash/reset-past-attempts", request, {...options, rejectOnError: true })