/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAppConfigRequest} from '@cash-web/protos/squareup/franklin/app/app_config.pb'
import {GetAppConfigResponse} from '@cash-web/protos/squareup/franklin/app/app_config.pb'


export const getAppConfig = (request:GetAppConfigRequest, options?: CashRequestOptions) => post<GetAppConfigRequest, GetAppConfigResponse>("/2.0/cash/get-app-config", request, {...options, rejectOnError: true })