/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateBitcoinWithdrawalRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'
import {InitiateBitcoinWithdrawalResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'


export const initiateBitcoinWithdrawal = (request:InitiateBitcoinWithdrawalRequest, options?: CashRequestOptions) => post<InitiateBitcoinWithdrawalRequest, InitiateBitcoinWithdrawalResponse>("/2.0/cash/initiate-bitcoin-withdrawal", request, {...options, rejectOnError: true })