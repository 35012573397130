/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {MatchContactsRequest} from '@cash-web/protos/squareup/franklin/app/contacts.pb'
import {MatchContactsResponse} from '@cash-web/protos/squareup/franklin/app/contacts.pb'


export const matchContacts = (request:MatchContactsRequest, options?: CashRequestOptions) => post<MatchContactsRequest, MatchContactsResponse>("/2.0/cash/match-contacts", request, {...options, rejectOnError: true })