/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SavingsCashInRequest} from '@cash-web/protos/squareup/cash/balancemover/api/v1/savings_cash_in.pb'
import {SavingsCashInResponse} from '@cash-web/protos/squareup/cash/balancemover/api/v1/savings_cash_in.pb'


export const savingsCashIn = (request:SavingsCashInRequest, options?: CashRequestOptions) => post<SavingsCashInRequest, SavingsCashInResponse>("/cash-app/internal-transfers/v1.0/add-cash", request, {...options, rejectOnError: true })