/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateInvestmentOrderRequest} from '@cash-web/protos/squareup/franklin/investing.pb'
import {InitiateInvestmentOrderResponse} from '@cash-web/protos/squareup/franklin/investing.pb'


export const initiateInvestmentOrder = (request:InitiateInvestmentOrderRequest, options?: CashRequestOptions) => post<InitiateInvestmentOrderRequest, InitiateInvestmentOrderResponse>("/2.0/cash/initiate-investment-order", request, {...options, rejectOnError: true })