/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {TrustDeviceRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {TrustDeviceResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const trustDevice = (request:TrustDeviceRequest, options?: CashRequestOptions) => post<TrustDeviceRequest, TrustDeviceResponse>("/2.0/cash/trust-device", request, {...options, rejectOnError: true })