/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCustomerNewsRequest} from '@cash-web/protos/squareup/cash/marketdata/service.pb'
import {GetCustomerNewsResponse} from '@cash-web/protos/squareup/cash/marketdata/service.pb'


export const getCustomerNews = (request:GetCustomerNewsRequest, options?: CashRequestOptions) => post<GetCustomerNewsRequest, GetCustomerNewsResponse>("/2.0/cash/get-customer-news", request, {...options, rejectOnError: true })