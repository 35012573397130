/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetServiceMessageRequest} from '@cash-web/protos/squareup/franklin/app/service_message.pb'
import {GetServiceMessageResponse} from '@cash-web/protos/squareup/franklin/app/service_message.pb'


export const getServiceMessage = (request:GetServiceMessageRequest, options?: CashRequestOptions) => post<GetServiceMessageRequest, GetServiceMessageResponse>("/2.0/cash/get-service-message", request, {...options, rejectOnError: true })