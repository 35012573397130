/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPaymentRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {GetPaymentResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const getPayment = (request:GetPaymentRequest, options?: CashRequestOptions) => post<GetPaymentRequest, GetPaymentResponse>("/2.0/cash/get-payment", request, {...options, rejectOnError: true })