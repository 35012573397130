/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillGiftCardsRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillGiftCards.pb'
import {BackfillGiftCardsResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillGiftCards.pb'


export const backfillGiftCards = (request:BackfillGiftCardsRequest, options?: CashRequestOptions) => post<BackfillGiftCardsRequest, BackfillGiftCardsResponse>("/2.0/cash/backfill-gift-cards", request, {...options, rejectOnError: true })