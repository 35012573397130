/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PlaidLinkTokenCreateRequest} from '@cash-web/protos/squareup/franklin/app/plaid.pb'
import {PlaidLinkTokenCreateResponse} from '@cash-web/protos/squareup/franklin/app/plaid.pb'


export const plaidLinkTokenCreate = (request:PlaidLinkTokenCreateRequest, options?: CashRequestOptions) => post<PlaidLinkTokenCreateRequest, PlaidLinkTokenCreateResponse>("/2.0/cash/plaid-link-token-create", request, {...options, rejectOnError: true })