/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetCardCustomizationRequest} from '@cash-web/protos/squareup/franklin/app/card_customization.pb'
import {SetCardCustomizationResponse} from '@cash-web/protos/squareup/franklin/app/card_customization.pb'


export const setCardCustomization = (request:SetCardCustomizationRequest, options?: CashRequestOptions) => post<SetCardCustomizationRequest, SetCardCustomizationResponse>("/2.0/cash/set-card-customization", request, {...options, rejectOnError: true })