/* eslint-disable @typescript-eslint/no-explicit-any */

// Redacts tokens from strings and replaces with a placeholder string
const redactTokens = (function () {
  /**
   * Matches multi-part tokens that are separated by ":", "-", and "_"
   * but avoids matching trailing line numbers in call stacks Ex: ":500"
   */
  const LEGACY_PAYMENT_TOKEN_REDACTION_REGEX =
    /\/(p|pay|payments|d|deposit|transfers|verify|receipts|crypto\/order)\/[\w$-]*(?::[\w-]*[a-zA-Z][\w-]*)*(\/.*)?/g

  /**
   * Matches universal receipt paths: `/receipt/<type>/<token>` and redacts the token but preserves the type.
   */
  const RECEIPT_TOKEN_REDACTION_REGEX = /\/(receipt)\/([A-Z,_,-]+)\/([\w$:=+-]+)?/gi

  /**
   * Matches /f/anythingupto%3Fasdfhasdf
   * Capture group 1:'/f/'
   * Capture group 2: 'anythingupto'
   * Redacted: everything including and after the first occurrence of '%3F'
   */
  const FLOW_QUERY_PARAM_REDACTION_REGEX = /(\/f\/)(.+?(?=%3F))([^"]+)/gi

  /**
   * VERIFY_EMAIL_REGEX:
   * Matches URLs that include '/app/verify-email/' followed by an alphanumeric string, which is the
   * verification token.
   * Capture group 1: The path 'app/verify-email'
   * Capture group 2: The alphanumeric verification code or token that follows the verification
   * path. This code is case-sensitive and can include digits (0-9) and uppercase letters (A-Z).
   * Usage: Used to removed email verification tokens from 'app/verify-email' URLs
   */
  const VERIFY_EMAIL_REGEX = /\/(app\/verify-email)\/([0-9A-Z]+)/gi

  /**
   * APP_REWARD_REGEX:
   * Matches URLs that start with either '/app/' or '/reward/' followed by one or more word
   * characters, excluding those specifically leading to the '/verify-email' path.
   * Capture group 1: The initial part of the path, either 'app/' or 'reward/'
   * Capture group 2: Optionally captures the remainder of the URL path that follows the initial
   * word characters
   * The negative lookahead (?!verify-email) ensures that this part of the URL is explicitly excluded
   * Usage: Used to redact app and reward codes from URLs
   */
  const APP_REWARD_REGEX = /\/(app\/|reward\/)(?!verify-email)\w+(\/.*)?/g

  /**
   * CASHTAGS_REGEX:
   * Matches URLs containing unencoded currency symbols followed by alphanumeric characters, and
   * then a separator (/, :, whitespace, or end of line).
   * Capture group 1: The currency symbol ('$', '€', '£').
   * Capture group 2: The separator (/, :, whitespace, or end of line).
   * Usage: Insert 'CASHTAG' directly after the currency symbol and before the captured separator.
   */
  const CASHTAGS_REGEX = /\/(\$|€|£)\w+(\/|:|\s|$)/g

  /**
   * CASHTAGS_ENCODED_REGEX:
   * Matches URLs containing encoded currency symbols followed by alphanumeric characters, and then
   * a separator (encoded '/', :, whitespace, or end of line).
   * Capture group 1: Captures the encoded slash ('%2F') at the start.
   * Capture group 2: The encoded currency symbol ('%24' for '$', '%C2%A3' for '£', '%E2%82%AC' for '€').
   * Capture group 3: The separator (encoded '/', :, whitespace, or end of line).
   * Usage: Insert 'CASHTAG' directly after the encoded currency symbol and before the captured separator.
   * If an encoded slash is captured at the start, it is preserved.
   */
  const CASHTAGS_ENCODED_REGEX = /(%2F|\/)(%24|%C2%A3|%E2%82%AC)\w+(%2F|:|\s|$)/g

  /**
   * CASHTAGS_BITCOIN_REGEX:
   * Matches URLs with unencoded currency symbols ('$','€','£'), followed by any characters leading
   * to '/bitcoin/' and then a sequence of digits.
   * Capture group 1: The currency symbol ('$','€','£').
   * Usage: Replace matched URLs to include 'CASHTAG' directly after the currency symbol and modify
   * the path to '/bitcoin/REDACTED', redacting the bitcoin invoice number.
   */
  const CASHTAGS_BITCOIN_REGEX = /\/(\$|€|£)[^]+\/bitcoin\/\d+/g

  /**
   * CASHTAGS_ENCODED_BITCOIN_INVOICE_REGEX:
   * Matches URLs with encoded currency symbols ('%24' for '$', '%C2%A3' for '£', '%E2%82%AC' for '€'),
   * preceded by an encoded slash ('%2F'), followed by any characters leading to '/bitcoin/'
   * and then a sequence of digits.
   * Capture group 1: Captures the encoded slash ('%2F') at the start.
   * Capture group 2: The encoded currency symbol ('%24','%C2%A3','%E2%82%AC').
   * Usage: Replace matched URLs to include 'CASHTAG' directly after the encoded currency symbol
   * and modify the path to '/bitcoin/REDACTED', redacting the bitcoin invoice number.
   */
  const CASHTAGS_ENCODED_BITCOIN_INVOICE_REGEX = /(%2F|\/)(%24|%C2%A3|%E2%82%AC)[^]+\/bitcoin\/\d+/g

  /**
   * NONCE_REGEX:
   * Matches strings that start with 'nonce-' followed by a base64 encoded value, which can include
   * characters A-Z, a-z, 0-9, plus (+), slash (/), and equals (=).
   * Usage: Used for redacting nonce values from script tags.
   */
  const NONCE_REGEX = /nonce-[A-Za-z0-9+/=]+/gi

  return function (url: string, redaction = 'REDACTED') {
    return (
      url &&
      url
        .replace(LEGACY_PAYMENT_TOKEN_REDACTION_REGEX, `/$1/${redaction}$2`)
        .replace(RECEIPT_TOKEN_REDACTION_REGEX, `/$1/$2/${redaction}`)
        .replace(FLOW_QUERY_PARAM_REDACTION_REGEX, `$1$2?${redaction}`)
        .replace(VERIFY_EMAIL_REGEX, '/$1/TOKEN')
        .replace(APP_REWARD_REGEX, '/$1REWARD_CODE$2')
        .replace(CASHTAGS_REGEX, '/$1CASHTAG$2')
        .replace(CASHTAGS_BITCOIN_REGEX, '/$1CASHTAG/bitcoin/REDACTED')
        .replace(CASHTAGS_ENCODED_REGEX, '$1$2CASHTAG$3')
        .replace(CASHTAGS_ENCODED_BITCOIN_INVOICE_REGEX, '$1$2CASHTAG/bitcoin/REDACTED')
        .replace(NONCE_REGEX, 'nonce-NONCEVALUE')
    )
  }
})()

// Recursively redacts tokens from any strings in the object and subobjects
// Modifies original object.
// Visits child arrays and objects.
const redactTokensRecursive = function (
  obj: Partial<{ [key: string]: any }> | null,
  /** @type {Array<any>}*/ visited: Array<any> = []
) {
  if (!obj) {
    return
  }
  visited.push(obj)

  Object.keys(obj).forEach(prop => {
    if (prop === 'file' && obj[prop] && obj[prop].indexOf('.js') > 0) {
      // Don't sanitize javascript file names like `/cash/app/activity.js:213123`
      return
    }
    const childObj = obj[prop]
    if (typeof childObj === 'string') {
      try {
        obj[prop] = redactTokens(childObj)
      } catch (e) {
        /* Swallow error to prevent breaking the UI.
         * Adding a logger.info() creates a circlular reference.
         */
      }
    } else if ((Array.isArray(childObj) || typeof childObj === 'object') && !visited.includes(childObj)) {
      redactTokensRecursive(childObj, visited)
    }
  })
}

export { redactTokens, redactTokensRecursive }
