/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AddOrEditAllocationDestinationRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'
import {AddOrEditAllocationDestinationResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'


export const addOrEditAllocationDestination = (request:AddOrEditAllocationDestinationRequest, options?: CashRequestOptions) => post<AddOrEditAllocationDestinationRequest, AddOrEditAllocationDestinationResponse>("/cash-app/paychecks/edit-allocation-destination", request, {...options, rejectOnError: true })