/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FlowCompleteRequest} from '@cash-web/protos/squareup/franklin/app/flow.pb'
import {FlowCompleteResponse} from '@cash-web/protos/squareup/franklin/app/flow.pb'


export const flowComplete = (request:FlowCompleteRequest, options?: CashRequestOptions) => post<FlowCompleteRequest, FlowCompleteResponse>("/2.0/cash/flow-complete", request, {...options, rejectOnError: true })