/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyInstrumentRequest} from '@cash-web/protos/squareup/franklin/app/instrument.pb'
import {VerifyInstrumentResponse} from '@cash-web/protos/squareup/franklin/app/instrument.pb'


export const verifyInstrument = (request:VerifyInstrumentRequest, options?: CashRequestOptions) => post<VerifyInstrumentRequest, VerifyInstrumentResponse>("/2.0/cash/verify-instrument", request, {...options, rejectOnError: true })