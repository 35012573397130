/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteLightningWithdrawalInternalActionRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'
import {ExecuteLightningWithdrawalInternalActionResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'


export const executeLightningWithdrawalInternalAction = (request:ExecuteLightningWithdrawalInternalActionRequest, options?: CashRequestOptions) => post<ExecuteLightningWithdrawalInternalActionRequest, ExecuteLightningWithdrawalInternalActionResponse>("/2.0/cash/execute-lightning-withdrawal-internal-action", request, {...options, rejectOnError: true })