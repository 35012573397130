/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SelectInstitutionAccountRequest} from '@cash-web/protos/squareup/franklin/bankbook.pb'
import {SelectInstitutionAccountResponse} from '@cash-web/protos/squareup/franklin/bankbook.pb'


export const selectInstitutionAccount = (request:SelectInstitutionAccountRequest, options?: CashRequestOptions) => post<SelectInstitutionAccountRequest, SelectInstitutionAccountResponse>("/2.0/cash/select-institution-account", request, {...options, rejectOnError: true })