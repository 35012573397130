/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCryptoLightningReceiveLimitsRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'
import {GetCryptoLightningReceiveLimitsResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'


export const getCryptoLightningReceiveLimits = (request:GetCryptoLightningReceiveLimitsRequest, options?: CashRequestOptions) => post<GetCryptoLightningReceiveLimitsRequest, GetCryptoLightningReceiveLimitsResponse>("/2.0/cash/get-crypto-lightning-receive-limits", request, {...options, rejectOnError: true })