/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ClearProfilePhotoRequest} from '@cash-web/protos/squareup/franklin/app/profile_photo.pb'
import {ClearProfilePhotoResponse} from '@cash-web/protos/squareup/franklin/app/profile_photo.pb'


export const clearProfilePhoto = (request:ClearProfilePhotoRequest, options?: CashRequestOptions) => post<ClearProfilePhotoRequest, ClearProfilePhotoResponse>("/2.0/cash/clear-profile-photo", request, {...options, rejectOnError: true })