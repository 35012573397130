import type { lib } from 'crypto-js'

import { btdetect } from './bot-detector'
import { initialize } from './request-setup'

// intentionally duplicated to avoid sharing
// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any -- obfuscation
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis
  }
  if (typeof self !== 'undefined') {
    return self
  }
  if (typeof window !== 'undefined') {
    return window
  }
  if (typeof global !== 'undefined') {
    return global
  }
  throw 'Unable to locate global object'
})()

initialize()

/**
 * Implement Anti Request Forgery Headers
 * https://docs.google.com/document/d/1yc7xhd_ED8xrlFY4A7COPNpAMowgIpwwkSThMut2kmA/edit#
 *
 * This file is purposefully vaguely named,
 * to aid in making it harder to reverse engineer
 * the anti-request-forgery-headers.
 */

const fromCharCode = String.fromCharCode.bind(null)

// 'toBase64' - function is defined in `request-setup.js`
const toBase64 = fromCharCode(116, 111, 66, 97, 115, 101, 54, 52)
// 'toHmacSHA256' - function is defined in `request-setup.js`
const toHmacSHA256 = fromCharCode(116, 111, 72, 109, 97, 99, 83, 72, 65, 50, 53, 54)
// Build the string 'sha256' so we can call the function from request-setup.js
const toSHA256 = fromCharCode(116, 111, 83, 72, 65, 50, 53, 54)
// 'Authorization' - header key name
const authTitle = fromCharCode(65, 117, 116, 104, 111, 114, 105, 122, 97, 116, 105, 111, 110)
// 'v1:'
const v1colon = fromCharCode(118, 49, 58)

// Sets the 'X-Request-UUID' header
const setSecondHeader = function (headersMap: Record<string, string>, diuu: string) {
  // Convert the DIUU back into the forward UUID.
  // This is a string reverse.
  // This is purposefully annoyingly complex.
  const uuid = diuu.split('').reduce(function (accumulator, char) {
    return `${char}${accumulator}`
  }, '')
  headersMap[btdetect.a] = uuid
}

const getKey = () => {
  // Pattern: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  const pattern = fromCharCode(
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    45,
    120,
    120,
    120,
    120,
    45,
    52,
    120,
    120,
    120,
    45,
    121,
    120,
    120,
    120,
    45,
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    120,
    120
  )
  const x = 'x'
  return pattern.replace(/[xy]/g, function (c) {
    const a = (Math.random() * 16) | 0
    const v = c === x ? a : (a & 0x3) | 0x8
    return v.toString(16)
  })
}

const base64 = (value: lib.WordArray) => globalThis[toBase64](value)

const hmacSha256 = (contentString: string, diuu: string) => globalThis[toHmacSHA256](contentString, diuu)

const sha256 = (value: string | CryptoJS.lib.WordArray) => globalThis[toSHA256](value)

/**
 * Expects this = window, and to have the arguments provided in reverse order.
 *
 * Used Exclusively by `calculations.js` - named obscure on purpose.
 *
 * Given the arguments: [path, uuid, authorization, bodyContent]
 * Builds a string of the format:
 * `${sha256(v1colon)}${sha256(path)}${sha256(uuid)}${sha256(authorization)}${sha256(bodyContent)}`
 *
 * ex: buildFullValue.call(window, bodyContent, authorization, uuid, path, v1colon);
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildFullValue = (...args: any[]) =>
  args.reduce((accumulator, arg) => {
    const hashedArg = sha256(arg)
    // Build the string in reverse.
    return `${hashedArg}${accumulator}`
  }, '')

export const Calculations = {
  /**
   * Sets the 'X-Request-Signature' header, and calls a
   * second function to set the 'X-Request-UUID' header.
   *
   * Note: Expected to be called with `this` bound to `window`.
   *
   * Ex: Calculations.setContent.call(window, headers, path, bodyContent);
   */
  setContent(headersMap: Record<string, string>, path: string, bodyContent = '') {
    // Generate a UUID.
    const uuid = getKey()
    // DIUU is the reverse of the UUID.
    const diuu = uuid.split('').reverse().join('')
    // Get 'Authorization' header value.
    const authorization = headersMap[authTitle] || ''
    // This helper function expects the argument order to be reversed.
    const contentString =
      v1colon +
      buildFullValue.call({ window: typeof window !== 'undefined' ? window : undefined }, authorization, uuid, path) +
      bodyContent

    const hmacValue = hmacSha256(contentString, diuu)

    headersMap[btdetect.b] = [
      fromCharCode(118, 49, 61), // 'v1='
      base64(hmacValue),
    ].join('')
    setSecondHeader(headersMap, diuu)
    headersMap[btdetect.c] = btdetect.getID()
  },
}
