import { Middleware, pipe } from './pipe'
export const createMiddlewareStore = <Request, Context>(...initialMiddleware: Middleware<Request, Context>[]) => {
  type MiddlewareListener = (store: Middleware<Request, Context>[]) => Middleware<Request, Context>[]
  let listeners: MiddlewareListener[] = []
  return {
    execute: (data: Request, context?: Context, optionalMiddleare: MiddlewareListener[] = []) => {
      const middleware = [...optionalMiddleare, ...listeners].reduce(
        (acc, listener) => listener(acc),
        [...initialMiddleware]
      )
      return pipe<Request, Context>(...middleware)(data, context)
    },
    set: (listener: MiddlewareListener) => {
      const found = listeners.find(l => l === listener)
      if (found == null) {
        listeners.push(listener)
      }
      return () => {
        listeners = listeners.filter(l => l !== listener)
      }
    },
  }
}
