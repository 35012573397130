/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResetAttemptLimitsByAppTokenRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {ResetAttemptLimitsByAppTokenResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const resetPastAttemptsByAppToken = (request:ResetAttemptLimitsByAppTokenRequest, options?: CashRequestOptions) => post<ResetAttemptLimitsByAppTokenRequest, ResetAttemptLimitsByAppTokenResponse>("/2.0/cash/reset-past-attempts-by-app-token", request, {...options, rejectOnError: true })