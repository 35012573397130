/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetProductsRequest} from '@cash-web/protos/squareup/cash/customersearch/api/GetProducts.pb'
import {GetProductsResponse} from '@cash-web/protos/squareup/cash/customersearch/api/GetProducts.pb'


export const getProducts = (request:GetProductsRequest, options?: CashRequestOptions) => post<GetProductsRequest, GetProductsResponse>("/2.0/cash/get-products", request, {...options, rejectOnError: true })