/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResolvePersonaDidvBlockerRequest} from '@cash-web/protos/squareup/franklin/persona_didv.pb'
import {ResolvePersonaDidvBlockerResponse} from '@cash-web/protos/squareup/franklin/persona_didv.pb'


export const resolvePersonaDidv = (request:ResolvePersonaDidvBlockerRequest, options?: CashRequestOptions) => post<ResolvePersonaDidvBlockerRequest, ResolvePersonaDidvBlockerResponse>("/2.0/cash/resolve-persona-didv", request, {...options, rejectOnError: true })