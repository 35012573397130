/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetTaxFormPrefillRequest} from '@cash-web/protos/squareup/franklin/app/tax_form_prefill.pb'
import {GetTaxFormPrefillResponse} from '@cash-web/protos/squareup/franklin/app/tax_form_prefill.pb'


export const getTaxFormPrefill = (request:GetTaxFormPrefillRequest, options?: CashRequestOptions) => post<GetTaxFormPrefillRequest, GetTaxFormPrefillResponse>("/2.0/cash/get-tax-form-prefill", request, {...options, rejectOnError: true })