/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BatchActivityGetInternalV2Request} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {BatchActivityGetInternalV2Response} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const batchActivityGetInternalV2 = (request:BatchActivityGetInternalV2Request, options?: CashRequestOptions) => post<BatchActivityGetInternalV2Request, BatchActivityGetInternalV2Response>("/2.0/cash/batch-activity-get-internal-v2", request, {...options, rejectOnError: true })