/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SuggestedRecipientsRequest} from '@cash-web/protos/squareup/franklin/app/suggested_recipients.pb'
import {SuggestedRecipientsResponse} from '@cash-web/protos/squareup/franklin/app/suggested_recipients.pb'


export const suggestedRecipients = (request:SuggestedRecipientsRequest, options?: CashRequestOptions) => post<SuggestedRecipientsRequest, SuggestedRecipientsResponse>("/2.0/cash/suggested-recipients", request, {...options, rejectOnError: true })