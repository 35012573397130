/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyCheckAddressRequest} from '@cash-web/protos/squareup/franklin/app/address.pb'
import {VerifyCheckAddressResponse} from '@cash-web/protos/squareup/franklin/app/address.pb'


export const verifyCheckAddress = (request:VerifyCheckAddressRequest, options?: CashRequestOptions) => post<VerifyCheckAddressRequest, VerifyCheckAddressResponse>("/2.0/cash/verify-check-address", request, {...options, rejectOnError: true })