/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResetDeviceRequest} from '@cash-web/protos/squareup/franklin/app/device.pb'
import {ResetDeviceResponse} from '@cash-web/protos/squareup/franklin/app/device.pb'


export const resetDevice = (request:ResetDeviceRequest, options?: CashRequestOptions) => post<ResetDeviceRequest, ResetDeviceResponse>("/2.0/cash/reset-device", request, {...options, rejectOnError: true })