/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetLatestClientProvidedBackupTagRequest} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'
import {GetLatestClientProvidedBackupTagResponse} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'


export const getLatestClientProvidedBackupTag = (request:GetLatestClientProvidedBackupTagRequest, options?: CashRequestOptions) => post<GetLatestClientProvidedBackupTagRequest, GetLatestClientProvidedBackupTagResponse>("/2.0/cash/get-latest-client-provided-backup-tag", request, {...options, rejectOnError: true })