/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAccountListRequest} from '@cash-web/protos/squareup/cash/janus/syncvalues/SyncValueAccounts.pb'
import {GetAccountListResponse} from '@cash-web/protos/squareup/cash/janus/syncvalues/SyncValueAccounts.pb'


export const getAccountList = (request:GetAccountListRequest, options?: CashRequestOptions) => post<GetAccountListRequest, GetAccountListResponse>("/2.0/cash/get-account-list", request, {...options, rejectOnError: true })