/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetAppMessagePreferenceRequest} from '@cash-web/protos/squareup/franklin/app/app_message.pb'
import {SetAppMessagePreferenceResponse} from '@cash-web/protos/squareup/franklin/app/app_message.pb'


export const setAppMessagePreference = (request:SetAppMessagePreferenceRequest, options?: CashRequestOptions) => post<SetAppMessagePreferenceRequest, SetAppMessagePreferenceResponse>("/2.0/cash/set-app-message-preference", request, {...options, rejectOnError: true })