/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetFeatureFlagsRequest} from '@cash-web/protos/squareup/franklin/app/feature_flags.pb'
import {SetFeatureFlagsResponse} from '@cash-web/protos/squareup/franklin/app/feature_flags.pb'


export const setFeatureFlags = (request:SetFeatureFlagsRequest, options?: CashRequestOptions) => post<SetFeatureFlagsRequest, SetFeatureFlagsResponse>("/2.0/cash/set-feature-flags", request, {...options, rejectOnError: true })