/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetBitcoinDisplayPreferenceRequest} from '@cash-web/protos/squareup/franklin/app/bitcoin_display_preference.pb'
import {SetBitcoinDisplayPreferenceResponse} from '@cash-web/protos/squareup/franklin/app/bitcoin_display_preference.pb'


export const setBitcoinDisplayPreference = (request:SetBitcoinDisplayPreferenceRequest, options?: CashRequestOptions) => post<SetBitcoinDisplayPreferenceRequest, SetBitcoinDisplayPreferenceResponse>("/2.0/cash/set-bitcoin-display-preference", request, {...options, rejectOnError: true })