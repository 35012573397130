import { RequestContext } from '@cash-web/protos/squareup/franklin/RequestContextProto.pb'

import { CashRequestMiddleware } from './types'

let blockerDescriptorId: string | undefined

export const requestContextRequest: CashRequestMiddleware = request => {
  if (request.method !== 'GET') {
    const request_context = request?.data?.request_context ?? ({} as RequestContext)
    if (blockerDescriptorId != null) {
      request_context.blocker_descriptor_id = blockerDescriptorId
    }
    if (Object.keys(request_context).length > 0 || (typeof window !== 'undefined' && window.csrfToken != null)) {
      request.data ??= {}
      request.data.request_context = request_context
    }
  }
  return request
}

export const setBlockerDescriptorId = (id?: string) => {
  blockerDescriptorId = id
}
