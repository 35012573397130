/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BatchActivityGetInternalRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {BatchActivityGetInternalResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const batchActivityGetInternal = (request:BatchActivityGetInternalRequest, options?: CashRequestOptions) => post<BatchActivityGetInternalRequest, BatchActivityGetInternalResponse>("/2.0/cash/batch-activity-get-internal", request, {...options, rejectOnError: true })