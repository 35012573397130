/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBackupTagByCustomerTokenRequest} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'
import {GetBackupTagByCustomerTokenResponse} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'


export const getBackupTagByCustomerToken = (request:GetBackupTagByCustomerTokenRequest, options?: CashRequestOptions) => post<GetBackupTagByCustomerTokenRequest, GetBackupTagByCustomerTokenResponse>("/2.0/cash/get-backup-tag-by-customer-token", request, {...options, rejectOnError: true })