/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetEnrollmentRequest} from '@cash-web/protos/squareup/franklin/app/enrollment.pb'
import {GetEnrollmentResponse} from '@cash-web/protos/squareup/franklin/app/enrollment.pb'


export const getEnrollment = (request:GetEnrollmentRequest, options?: CashRequestOptions) => post<GetEnrollmentRequest, GetEnrollmentResponse>("/2.0/cash/get-enrollment", request, {...options, rejectOnError: true })