/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PublishGpsEventRequest} from '@cash-web/protos/squareup/franklin/app/gps.pb'
import {PublishGpsEventResponse} from '@cash-web/protos/squareup/franklin/app/gps.pb'


export const publishGpsEvent = (request:PublishGpsEventRequest, options?: CashRequestOptions) => post<PublishGpsEventRequest, PublishGpsEventResponse>("/2.0/cash/publish-gps-event", request, {...options, rejectOnError: true })