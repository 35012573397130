/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSuggestedContactsRequest} from '@cash-web/protos/squareup/franklin/app/contacts.pb'
import {GetSuggestedContactsResponse} from '@cash-web/protos/squareup/franklin/app/contacts.pb'


export const getSuggestedContacts = (request:GetSuggestedContactsRequest, options?: CashRequestOptions) => post<GetSuggestedContactsRequest, GetSuggestedContactsResponse>("/2.0/cash/get-suggested-contacts", request, {...options, rejectOnError: true })