/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RecordNewBackupTagRequest} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'
import {RecordNewBackupTagResponse} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'


export const recordNewBackupTag = (request:RecordNewBackupTagRequest, options?: CashRequestOptions) => post<RecordNewBackupTagRequest, RecordNewBackupTagResponse>("/2.0/cash/record-new-backup-tag", request, {...options, rejectOnError: true })