/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetDevicesRequest} from '@cash-web/protos/squareup/cash/devicegrip/app.pb'
import {GetDevicesResponse} from '@cash-web/protos/squareup/cash/devicegrip/app.pb'


export const getDevices = (request:GetDevicesRequest, options?: CashRequestOptions) => post<GetDevicesRequest, GetDevicesResponse>("/cash-app/devices/1.0/get-devices", request, {...options, rejectOnError: true })