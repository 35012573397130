/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SearchSupportArticlesRequest} from '@cash-web/protos/squareup/cash/supportarticles/app/v1beta1/app.pb'
import {SearchSupportArticlesResponse} from '@cash-web/protos/squareup/cash/supportarticles/app/v1beta1/app.pb'


export const searchSupportArticles = (request:SearchSupportArticlesRequest, options?: CashRequestOptions) => post<SearchSupportArticlesRequest, SearchSupportArticlesResponse>("/2.0/cash/search-support-articles", request, {...options, rejectOnError: true })