/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UnlockRewardRequest} from '@cash-web/protos/squareup/franklin/app/rewards.pb'
import {UnlockRewardResponse} from '@cash-web/protos/squareup/franklin/app/rewards.pb'


export const unlockReward = (request:UnlockRewardRequest, options?: CashRequestOptions) => post<UnlockRewardRequest, UnlockRewardResponse>("/2.0/cash/unlock-reward", request, {...options, rejectOnError: true })