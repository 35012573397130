/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateRedPocketEnvelopeRequest} from '@cash-web/protos/squareup/franklin/app/red_pocket_envelope.pb'
import {CreateRedPocketEnvelopeResponse} from '@cash-web/protos/squareup/franklin/app/red_pocket_envelope.pb'


export const createRedPocketEnvelope = (request:CreateRedPocketEnvelopeRequest, options?: CashRequestOptions) => post<CreateRedPocketEnvelopeRequest, CreateRedPocketEnvelopeResponse>("/2.0/cash/create-red-pocket-envelope", request, {...options, rejectOnError: true })