/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ConfirmPasscodeRequest} from '@cash-web/protos/squareup/franklin/app/passcode.pb'
import {ConfirmPasscodeResponse} from '@cash-web/protos/squareup/franklin/app/passcode.pb'


export const confirmPasscode = (request:ConfirmPasscodeRequest, options?: CashRequestOptions) => post<ConfirmPasscodeRequest, ConfirmPasscodeResponse>("/2.0/cash/confirm-passcode", request, {...options, rejectOnError: true })