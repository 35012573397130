/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {EditBillNicknameFlowRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/EditBillNicknameFlow.pb'
import {EditBillNicknameFlowResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/EditBillNicknameFlow.pb'


export const editBillNickname = (request:EditBillNicknameFlowRequest, options?: CashRequestOptions) => post<EditBillNicknameFlowRequest, EditBillNicknameFlowResponse>("/cash-app/bills/v1.0/app/edit-bill-nickname-flow", request, {...options, rejectOnError: true })