/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateCheckRecipientRequest} from '@cash-web/protos/squareup/franklin/app/check_recipient.pb'
import {UpdateCheckRecipientResponse} from '@cash-web/protos/squareup/franklin/app/check_recipient.pb'


export const updateCheckRecipient = (request:UpdateCheckRecipientRequest, options?: CashRequestOptions) => post<UpdateCheckRecipientRequest, UpdateCheckRecipientResponse>("/2.0/cash/update-check-recipient", request, {...options, rejectOnError: true })