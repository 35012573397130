/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UploadFileRequest} from '@cash-web/protos/squareup/franklin/app/upload_file.pb'
import {UploadFileResponse} from '@cash-web/protos/squareup/franklin/app/upload_file.pb'


export const uploadFile = (request:UploadFileRequest, options?: CashRequestOptions) => post<UploadFileRequest, UploadFileResponse>("/2.0/cash/upload-file", request, {...options, rejectOnError: true })