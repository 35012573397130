/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SignOutRequest} from '@cash-web/protos/squareup/franklin/app/sign_out.pb'
import {SignOutResponse} from '@cash-web/protos/squareup/franklin/app/sign_out.pb'


export const signOut = (request:SignOutRequest, options?: CashRequestOptions) => post<SignOutRequest, SignOutResponse>("/2.0/cash/sign-out", request, {...options, rejectOnError: true })