/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetRewardProfileRequest} from '@cash-web/protos/squareup/franklin/app/reward_profile.pb'
import {GetRewardProfileResponse} from '@cash-web/protos/squareup/franklin/app/reward_profile.pb'


export const getRewardProfile = (request:GetRewardProfileRequest, options?: CashRequestOptions) => post<GetRewardProfileRequest, GetRewardProfileResponse>("/2.0/cash/get-reward-profile", request, {...options, rejectOnError: true })