/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UnlinkInstrumentRequest} from '@cash-web/protos/squareup/franklin/UnlinkInstrumentRequestProto.pb'
import {UnlinkInstrumentResponse} from '@cash-web/protos/squareup/franklin/UnlinkInstrumentResponseProto.pb'


export const unlinkInstrument = (request:UnlinkInstrumentRequest, options?: CashRequestOptions) => post<UnlinkInstrumentRequest, UnlinkInstrumentResponse>("/2.0/cash/unlink-instrument", request, {...options, rejectOnError: true })