import { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'

import { useLogCashWebCdfEvent } from '@cash-web/shared-feature-analytics'
import {
  WebCheckoutTrackPage,
  WebCheckoutTrackSystem,
  WebCheckoutTrackUser,
  WebCheckoutTrackView,
} from '@squareup/customer-data-events-web'

import { useAuth } from '../components/AuthProvider'
import { PageNames, Route, TrackingErrorCodeEventWhitelist } from '../model'
import { useCheckoutRequestId, useError, useMerchant, useOrderId, usePaymentMode } from '../state'
import { getPlatform, useLoanDetails, useOrderTotal } from '../utils'
import { getAppConfig } from './config'

export const viewedPageEventName = (pageName: string) => `Viewed ${pageName} Page`

export const useRoutePageName = () => {
  const { pathname } = useRouter()

  return PageNames[pathname as Route]
}

export const useEnduringProperties = () => {
  const { appVersion } = getAppConfig()
  const customer_request_id = useCheckoutRequestId()
  const merchant = useMerchant()
  const order_id = useOrderId()
  const orderTotal = useOrderTotal()
  const platform = getPlatform()

  const cleanValue = (val: string | number | undefined) => (typeof val === 'undefined' || val === '' ? undefined : val)

  return useMemo(
    () => ({
      customer_request_id: cleanValue(customer_request_id),
      merchant_name: cleanValue(merchant.name),
      order_id: cleanValue(order_id),
      order_amount_cents: cleanValue(orderTotal?.amount),
      order_currency: cleanValue(orderTotal?.currency),
      platform,
      version: appVersion,
    }),
    [appVersion, customer_request_id, merchant.name, orderTotal?.amount, orderTotal?.currency, order_id, platform]
  )
}

export const useTrackUserEvent = () => {
  const { analyticsHost } = getAppConfig()
  const logCdfEvent = useLogCashWebCdfEvent({ hostName: analyticsHost })

  const enduringProperties = useEnduringProperties()
  const payment_mode = usePaymentMode()
  const loanDetails = useLoanDetails()

  return useCallback(
    async (eventName: string) => {
      const detail = JSON.stringify({
        ...enduringProperties,
        payment_mode,
        ...loanDetails,
      })

      await logCdfEvent(
        WebCheckoutTrackUser({
          action: eventName,
          detail,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payment_mode]
  )
}

export const useTrackSystemEvent = () => {
  const { analyticsHost } = getAppConfig()
  const logCdfEvent = useLogCashWebCdfEvent({ hostName: analyticsHost })

  const enduringProperties = useEnduringProperties()

  return useCallback(
    async (eventName: string) => {
      const detail = JSON.stringify({
        ...enduringProperties,
      })

      await logCdfEvent(
        WebCheckoutTrackSystem({
          action: eventName,
          detail,
        })
      )
    },
    [enduringProperties, logCdfEvent]
  )
}

interface ErrorDetails {
  error_code?: string
  error_category?: string
}

export const useTrackPageView = () => {
  const { analyticsHost } = getAppConfig()
  const logCdfEvent = useLogCashWebCdfEvent({ hostName: analyticsHost })

  const enduringProperties = useEnduringProperties()
  const loanDetails = useLoanDetails()
  const error = useError()

  return useCallback(
    async (pageName: string) => {
      const errorDetails: ErrorDetails = {}
      const isErrorEvent = TrackingErrorCodeEventWhitelist.includes(pageName)
      if (isErrorEvent) {
        errorDetails.error_code = error.code
        errorDetails.error_category = error.category
      }
      const detail = JSON.stringify({
        ...enduringProperties,
        ...loanDetails,
        ...errorDetails,
      })

      await logCdfEvent(
        WebCheckoutTrackPage({
          action: viewedPageEventName(pageName),
          detail,
        })
      )
    },
    [enduringProperties, error.category, error.code, loanDetails, logCdfEvent]
  )
}

export const useTrackView = () => {
  const { loggedIn } = useAuth()
  const { analyticsHost } = getAppConfig()
  const logCdfEvent = useLogCashWebCdfEvent({ hostName: analyticsHost })

  const enduringProperties = useEnduringProperties()

  return useCallback(
    async (eventName: string, error_code?: string) => {
      if (!loggedIn) {
        return
      }
      const detail = JSON.stringify({
        ...enduringProperties,
        error_code,
      })

      await logCdfEvent(
        WebCheckoutTrackView({
          action: eventName,
          detail,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedIn]
  )
}
