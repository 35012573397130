/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetExchangeContractRequest} from '@cash-web/protos/squareup/franklin/app/exchange_contract.pb'
import {GetExchangeContractResponse} from '@cash-web/protos/squareup/franklin/app/exchange_contract.pb'


export const getExchangeContract = (request:GetExchangeContractRequest, options?: CashRequestOptions) => post<GetExchangeContractRequest, GetExchangeContractResponse>("/2.0/cash/get-exchange-contract", request, {...options, rejectOnError: true })