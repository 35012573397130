/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SubmitSetPaycheckMultiAllocationRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/submit_set_paycheck_multi_allocation_blocker.pb'
import {SubmitSetPaycheckMultiAllocationResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/submit_set_paycheck_multi_allocation_blocker.pb'


export const submitSetPaycheckMultiAllocation = (request:SubmitSetPaycheckMultiAllocationRequest, options?: CashRequestOptions) => post<SubmitSetPaycheckMultiAllocationRequest, SubmitSetPaycheckMultiAllocationResponse>("/2.0/cash/submit-set-paycheck-multi-allocation", request, {...options, rejectOnError: true })