/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CdpTrackEventRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {CdpTrackEventResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const cdpTrackEvent = (request:CdpTrackEventRequest, options?: CashRequestOptions) => post<CdpTrackEventRequest, CdpTrackEventResponse>("/2.0/cash/cdp-track-event", request, {...options, rejectOnError: true })