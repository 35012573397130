/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetHealthRequest} from '@cash-web/protos/squareup/cashfrontend/api.pb'
import {GetHealthResponse} from '@cash-web/protos/squareup/cashfrontend/api.pb'


export const getHealth = (request:GetHealthRequest, options?: CashRequestOptions) => post<GetHealthRequest, GetHealthResponse>("/2.0/cash/get-health", request, {...options, rejectOnError: true })