/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CompleteInviteFriendsRequest} from '@cash-web/protos/squareup/franklin/app/invitations.pb'
import {CompleteInviteFriendsResponse} from '@cash-web/protos/squareup/franklin/app/invitations.pb'


export const completeInviteFriends = (request:CompleteInviteFriendsRequest, options?: CashRequestOptions) => post<CompleteInviteFriendsRequest, CompleteInviteFriendsResponse>("/2.0/cash/complete-invite-friends", request, {...options, rejectOnError: true })