/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {StartWebAuthnRegistrationRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {StartWebAuthnRegistrationResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const startWebAuthnRegistration = (request:StartWebAuthnRegistrationRequest, options?: CashRequestOptions) => post<StartWebAuthnRegistrationRequest, StartWebAuthnRegistrationResponse>("/cash-app/security/1.0/start-webauthn-registration", request, {...options, rejectOnError: true })