/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAccountRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {GetAccountResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const getAccountInternal = (request:GetAccountRequest, options?: CashRequestOptions) => post<GetAccountRequest, GetAccountResponse>("/2.0/cash/get-account-internal", request, {...options, rejectOnError: true })