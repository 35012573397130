/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BatchCreateOrUpdateActivityItemRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {BatchCreateOrUpdateActivityItemResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const batchCreateOrUpdateActivityItem = (request:BatchCreateOrUpdateActivityItemRequest, options?: CashRequestOptions) => post<BatchCreateOrUpdateActivityItemRequest, BatchCreateOrUpdateActivityItemResponse>("/2.0/cash/batch-create-or-update-activity-item", request, {...options, rejectOnError: true })