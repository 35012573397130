import * as React from 'react'

import Bugsnag, { FeatureFlag } from '@bugsnag/js'

import { initializeBugsnag } from './lib/bugsnag-runtime'

const notify = (error: Error, severity: 'info' | 'warning' | 'error') => {
  if (process.env['NODE_ENV'] === 'development') {
    const method = severity === 'warning' ? 'warn' : severity
    // eslint-disable-next-line no-console
    console[method](error)
  }
  Bugsnag.notify(error, function (event) {
    event.severity = severity
  })
}

type LoggableFlagPrimitives = string | number | boolean | null | undefined
type LoggableFlagValues =
  | LoggableFlagPrimitives
  | { [key: string]: LoggableFlagValues }
  | Readonly<{ [key: string]: LoggableFlagValues }>
  | Array<LoggableFlagValues>
  | ReadonlyArray<LoggableFlagValues>

const logger = {
  /*
   * Initializes Bugsnag error monitoring
   * Put this at the highest level of your application to capture errors for monitoring and alerting.
   * This is also required for the Bugsnag methods below to work
   */
  initErrorMonitoring: ({
    appName,
  }: {
    /**
     * The name of the application which is being monitored. This shows up in BugSnag and
     * is useful for differentiating between apps in a given BugSnag project.
     */
    appName: string | undefined
  }) => {
    initializeBugsnag()

    logger.addMetadata('app', {
      name: appName,
    })
  },
  /*
   * Creates a React <ErrorBoundary /> component to capture errors and renders an error state UI.
   * It only captures methods in the component tree that occur when rendering, in lifecycle methods,
   * and in constructors.
   */
  createErrorBoundary: () => {
    return Bugsnag.getPlugin?.('react')?.createErrorBoundary(React)
  },
  /*
   * Captures errors for monitoring and alerting
   */
  error: (error: Error) => {
    notify(error, 'error')
  },
  /*
   * Logs errors with severity: info for monitoring and alerting
   */
  info: (error: Error) => {
    notify(error, 'info')
  },
  /*
   * Logs errors with severity: warning for monitoring and alerting
   */
  warn: (error: Error) => {
    notify(error, 'warning')
  },
  /**
   * Register features flags for session
   */
  addFeatureFlags: (
    featureFlags: Array<{
      flag: string
      value: LoggableFlagValues
    }>
  ) => {
    const processedFlags: Array<FeatureFlag> = featureFlags.map(({ flag, value }) => {
      let variant: FeatureFlag['variant']

      if (typeof value === 'string') {
        variant = value
      } else if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'object') {
        variant = JSON.stringify(value)
      } else if (value === undefined) {
        variant = 'undefined'
      } else {
        variant = value
      }

      return { name: flag, variant }
    })

    Bugsnag.addFeatureFlags(processedFlags)
  },
  /**
   * Clears set feature flags for session
   */
  clearFeatureFlags: () => {
    Bugsnag.clearFeatureFlags()
  },
  /**
   * Register metadata for session
   */
  addMetadata: (field: string, metadata: Record<string, unknown>) => {
    Bugsnag.addMetadata(field, metadata)
  },
  /**
   * Adds breadcrumbs to the session for better understanding
   * how someone got into a specific situation
   */
  addBreadcrumb: (...args: Parameters<(typeof Bugsnag)['leaveBreadcrumb']>) => {
    Bugsnag.leaveBreadcrumb(...args)
  },
  /**
   * Clears set metadata field for session
   */
  clearMetadata: (field: string) => {
    Bugsnag.clearMetadata(field)
  },
}

export default logger
