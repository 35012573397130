import { CSSProperties } from 'react'

import styled from '@emotion/styled'

/**
 * `Stack` Component
 *
 * @overview
 * The `Stack` component is a layout utility designed to vertically space child elements.
 * It employs the CSS grid system for even spacing without individual child margins.
 *
 * @props
 *
 * @prop {string} space - Defines the vertical spacing between the `Stack` component's direct children.
 *                        It should be a valid CSS spacing value. For consistent design, it's recommended
 *                        to use spacing values from the design system:
 *                        `import { spacing } from '@cash-design-system/react'`.
 *
 * @example
 *
 * ```javascript
 * <Stack space={spacing.m}>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Stack>
 * ```
 *
 * In the above usage, `Item 1`, `Item 2`, and `Item 3` have a medium or (1 rem) vertical spacing.
 *
 * @notes
 * - Only affects direct children's vertical spacing. Nested elements aren't spaced.
 *
 */
const Stack = styled.div<{ space: string; justifyItems?: CSSProperties['justifyItems'] }>`
  display: grid;
  row-gap: ${props => props.space};

  ${({ justifyItems }) => justifyItems && `justify-items: ${justifyItems};`}
`

export default Stack
