/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateInboundVoiceCaseRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {CreateInboundVoiceCaseResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const createInboundVoiceCase = (request:CreateInboundVoiceCaseRequest, options?: CashRequestOptions) => post<CreateInboundVoiceCaseRequest, CreateInboundVoiceCaseResponse>("/2.0/cash/create-inbound-voice-case", request, {...options, rejectOnError: true })