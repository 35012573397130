/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateDeviceDetailsRequest} from '@cash-web/protos/squareup/cash/devicegrip/app.pb'
import {UpdateDeviceDetailsResponse} from '@cash-web/protos/squareup/cash/devicegrip/app.pb'


export const updateDeviceDetails = (request:UpdateDeviceDetailsRequest, options?: CashRequestOptions) => post<UpdateDeviceDetailsRequest, UpdateDeviceDetailsResponse>("/cash-app/devices/1.0/update-device-details", request, {...options, rejectOnError: true })