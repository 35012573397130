/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetCashtagUrlEnabledRequest} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'
import {SetCashtagUrlEnabledResponse} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'


export const setCashtagUrlEnabled = (request:SetCashtagUrlEnabledRequest, options?: CashRequestOptions) => post<SetCashtagUrlEnabledRequest, SetCashtagUrlEnabledResponse>("/2.0/cash/set-cashtag-url-enabled", request, {...options, rejectOnError: true })