/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPublicArticleRequest} from '@cash-web/protos/squareup/cash/supportarticles/api/loggedout/v1/service.pb'
import {GetPublicArticleResponse} from '@cash-web/protos/squareup/cash/supportarticles/api/loggedout/v1/service.pb'


export const getPublicArticle = (request:GetPublicArticleRequest, options?: CashRequestOptions) => post<GetPublicArticleRequest, GetPublicArticleResponse>("/2.0/cash/get-public-article", request, {...options, rejectOnError: true })