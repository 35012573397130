import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { redactTokensRecursive } from '@cash-web/shared/util-redaction'
import { canTrack, Region } from '@cash-web/shared-util-can-track'
import { getEnvironmentForObservabilityTools, isServerEnvironment } from '@cash-web/shared-util-environment'

declare const BUGSNAG_API_KEY: string
declare const BUGSNAG_APP_VERSION: string

const hasApiKey = () => {
  try {
    return !!BUGSNAG_API_KEY
  } catch (e) {
    return false
  }
}
let hasInitialized = false

export const resetInitialization = () => {
  hasInitialized = false
}

export const initializeBugsnag = () => {
  // Bugsnag is not currently supported in Cash Cloud
  // or it has been called more than once.
  // or there is no BUGSNAG_API_KEY (which happens in cypress tests)
  if (isServerEnvironment() || hasInitialized || !hasApiKey()) {
    return
  }

  const region = window?.countryGuess as Region
  /*
   * If we can't track in the region, redact the user key that includes the user's IP, nsid, or other PII
   */
  const redactUserKey = !canTrack(region) ? 'user' : ''
  // This is the only place we should be initializing bugsnag
  // eslint-disable-next-line no-restricted-syntax
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: getEnvironmentForObservabilityTools(),
    enabledReleaseStages: ['production', 'staging', 'unreleased', 'cypress'],
    appVersion: BUGSNAG_APP_VERSION,
    enabledBreadcrumbTypes: ['error', 'log'],
    /*Redact keys directly in redactedKeys.
     More complex redaction should mutate event in onError */
    redactedKeys: redactUserKey ? [redactUserKey] : [],
    onError: function (event) {
      redactTokensRecursive(event as Partial<Event>)
    },
    /* If we can't track in the region, don't generate an anonymous id
     * This is used for user stability tracking, but is not compatible with GDPR
     * https://docs.bugsnag.com/platforms/javascript/configuration-options/#generateanonymousid
     */
    generateAnonymousId: canTrack(region),
    /*
     * By default, BugSnag will automatically capture and report session information
     * We don't want to do this in no track regions
     * https://docs.bugsnag.com/platforms/javascript/configuration-options/#autotracksessions
     */
    autoTrackSessions: canTrack(region),
    /*
    By default the IP address of the user whose browser reported an error will be collected and displayed in the request tab
    * We don't want to do this in no track regions
    * https://docs.bugsnag.com/platforms/javascript/configuration-options/#collectuserip
    */
    collectUserIp: canTrack(region),
  })
  hasInitialized = true
}
