/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetPasswordRequest} from '@cash-web/protos/squareup/cash/security/api.pb'
import {SetPasswordResponse} from '@cash-web/protos/squareup/cash/security/api.pb'


export const setPassword = (request:SetPasswordRequest, options?: CashRequestOptions) => post<SetPasswordRequest, SetPasswordResponse>("/cash-app/security/1.0/set-password", request, {...options, rejectOnError: true })