/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetCashtagRequest} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'
import {SetCashtagResponse} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'


export const setCashtag = (request:SetCashtagRequest, options?: CashRequestOptions) => post<SetCashtagRequest, SetCashtagResponse>("/2.0/cash/set-cashtag", request, {...options, rejectOnError: true })