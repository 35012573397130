/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetNewsRequest} from '@cash-web/protos/squareup/cash/marketdata/service.pb'
import {GetNewsResponse} from '@cash-web/protos/squareup/cash/marketdata/service.pb'


export const getNews = (request:GetNewsRequest, options?: CashRequestOptions) => post<GetNewsRequest, GetNewsResponse>("/2.0/cash/get-news", request, {...options, rejectOnError: true })