/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {OcrCardRequest} from '@cash-web/protos/squareup/franklin/app/ocr_card.pb'
import {OcrCardResponse} from '@cash-web/protos/squareup/franklin/app/ocr_card.pb'


export const ocrCard = (request:OcrCardRequest, options?: CashRequestOptions) => post<OcrCardRequest, OcrCardResponse>("/2.0/cash/ocr-card", request, {...options, rejectOnError: true })