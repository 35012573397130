/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RefreshPaychecksHomeRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'
import {RefreshPaychecksHomeResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'


export const refreshPaychecksHome = (request:RefreshPaychecksHomeRequest, options?: CashRequestOptions) => post<RefreshPaychecksHomeRequest, RefreshPaychecksHomeResponse>("/cash-app/paychecks/refresh-paychecks-home", request, {...options, rejectOnError: true })