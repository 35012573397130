/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetHistoricalExchangeDataRequest} from '@cash-web/protos/squareup/franklin/app/exchange_data.pb'
import {GetHistoricalExchangeDataResponse} from '@cash-web/protos/squareup/franklin/app/exchange_data.pb'


export const getHistoricalExchangeData = (request:GetHistoricalExchangeDataRequest, options?: CashRequestOptions) => post<GetHistoricalExchangeDataRequest, GetHistoricalExchangeDataResponse>("/2.0/cash/get-historical-exchange-data", request, {...options, rejectOnError: true })