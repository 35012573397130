/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PayBillFlowRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/PayBillFlow.pb'
import {PayBillFlowResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/PayBillFlow.pb'


export const payBill = (request:PayBillFlowRequest, options?: CashRequestOptions) => post<PayBillFlowRequest, PayBillFlowResponse>("/cash-app/bills/v1.0/app/pay-bill-flow", request, {...options, rejectOnError: true })