/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetIssuedCardLockRequest} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'
import {SetIssuedCardLockResponse} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'


export const setIssuedCardLock = (request:SetIssuedCardLockRequest, options?: CashRequestOptions) => post<SetIssuedCardLockRequest, SetIssuedCardLockResponse>("/2.0/cash/set-issued-card-lock", request, {...options, rejectOnError: true })