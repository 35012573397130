/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAppMessageTemplatesRequest} from '@cash-web/protos/squareup/franklin/app/app_message.pb'
import {GetAppMessageTemplatesResponse} from '@cash-web/protos/squareup/franklin/app/app_message.pb'


export const getAppMessageTemplatesApp = (request:GetAppMessageTemplatesRequest, options?: CashRequestOptions) => post<GetAppMessageTemplatesRequest, GetAppMessageTemplatesResponse>("/2.0/cash/get-app-message-templates-app", request, {...options, rejectOnError: true })