/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetKybEligibilityWarningInternalRequest} from '@cash-web/protos/squareup/cash/cashbusinessaccounts/api/v1/get_kyb_eligibility_warning.pb'
import {GetKybEligibilityWarningInternalResponse} from '@cash-web/protos/squareup/cash/cashbusinessaccounts/api/v1/get_kyb_eligibility_warning.pb'


export const getKybEligibilityWarningInternal = (request:GetKybEligibilityWarningInternalRequest, options?: CashRequestOptions) => post<GetKybEligibilityWarningInternalRequest, GetKybEligibilityWarningInternalResponse>("/2.0/cash/get-kyb-eligibility-warning-internal", request, {...options, rejectOnError: true })