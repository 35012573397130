/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreditFirstTimeBorrowRequest} from '@cash-web/protos/squareup/franklin/app/credit_first_time_borrow.pb'
import {CreditFirstTimeBorrowResponse} from '@cash-web/protos/squareup/franklin/app/credit_first_time_borrow.pb'


export const creditFirstTimeBorrow = (request:CreditFirstTimeBorrowRequest, options?: CashRequestOptions) => post<CreditFirstTimeBorrowRequest, CreditFirstTimeBorrowResponse>("/2.0/cash/credit-first-time-borrow", request, {...options, rejectOnError: true })