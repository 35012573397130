/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SelectSponsorsRequest} from '@cash-web/protos/squareup/franklin/app/sponsors.pb'
import {SelectSponsorsResponse} from '@cash-web/protos/squareup/franklin/app/sponsors.pb'


export const selectSponsors = (request:SelectSponsorsRequest, options?: CashRequestOptions) => post<SelectSponsorsRequest, SelectSponsorsResponse>("/2.0/cash/select-sponsors", request, {...options, rejectOnError: true })