/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteCreatePinRequest} from '@cash-web/protos/squareup/franklin/app/pin.pb'
import {ExecuteCreatePinResponse} from '@cash-web/protos/squareup/franklin/app/pin.pb'


export const executeCreatePin = (request:ExecuteCreatePinRequest, options?: CashRequestOptions) => post<ExecuteCreatePinRequest, ExecuteCreatePinResponse>("/2.0/cash/execute-create-pin", request, {...options, rejectOnError: true })