/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBillsHomeRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBillsHome.pb'
import {GetBillsHomeResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBillsHome.pb'


export const getBillsHome = (request:GetBillsHomeRequest, options?: CashRequestOptions) => post<GetBillsHomeRequest, GetBillsHomeResponse>("/cash-app/bills/v1.0/app/get-bills-home", request, {...options, rejectOnError: true })