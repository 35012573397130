/* eslint-disable */

/** A description of where in the app or web a call is originating. */
export enum ClientScenario {
  /** ONBOARDING - The call originated during onboarding of the client. */
  ONBOARDING = "ONBOARDING",
  /** LOGIN - The call originated during a sign in flow that needs to skip everything non-essential. */
  LOGIN = "LOGIN",
  /** RETURNING_CUSTOMER_LOGIN - The call originated from login flow for returning customer, that will skip 2FA and risk-evaluation. */
  RETURNING_CUSTOMER_LOGIN = "RETURNING_CUSTOMER_LOGIN",
  /** PAYMENT_FLOW - The call originated during a payment flow in the client. */
  PAYMENT_FLOW = "PAYMENT_FLOW",
  /** PROFILE - The call originated in the client's profile. */
  PROFILE = "PROFILE",
  /** ACTIVITY - The call originated in the client's activity. */
  ACTIVITY = "ACTIVITY",
  /** ENABLE_CASH_BALANCE - The call originated in a flow for enabling deposits into a customer’s stored balance. */
  ENABLE_CASH_BALANCE = "ENABLE_CASH_BALANCE",
  /**
   * DISABLE_CASH_BALANCE - The call originated in a flow for disabling deposits into a customer’s stored balance.
   * This is only supported for old apps. Use ENABLE_AUTO_CASH_OUT instead.
   */
  DISABLE_CASH_BALANCE = "DISABLE_CASH_BALANCE",
  /** ENABLE_SECURITY_LOCK - The call originated in a flow for enabling a customer’s security lock. */
  ENABLE_SECURITY_LOCK = "ENABLE_SECURITY_LOCK",
  /** DISABLE_SECURITY_LOCK - The call originated in a flow for disabling a customer’s security lock. */
  DISABLE_SECURITY_LOCK = "DISABLE_SECURITY_LOCK",
  /** CREATE_PASSCODE - The call originated in a flow for creating a customer’s passcode. */
  CREATE_PASSCODE = "CREATE_PASSCODE",
  /** CHANGE_PASSCODE - The call originated in a flow for changing a customer’s passcode. */
  CHANGE_PASSCODE = "CHANGE_PASSCODE",
  /** RESET_PASSCODE - The call originated in a flow for resetting a customer’s passcode without the current passcode. */
  RESET_PASSCODE = "RESET_PASSCODE",
  /** TRANSFER_FUNDS - The call originated in a flow to transfer funds to or from a customer's stored balance. */
  TRANSFER_FUNDS = "TRANSFER_FUNDS",
  /** GET_ISSUED_CARD - The call originated in a flow for getting a customer’s issued card. */
  GET_ISSUED_CARD = "GET_ISSUED_CARD",
  /** ACTIVATE_ISSUED_CARD - The call originated in a flow for activating a customer’s issued card. */
  ACTIVATE_ISSUED_CARD = "ACTIVATE_ISSUED_CARD",
  /** ENABLE_ISSUED_CARD - The call originated in a flow for enabling a customer’s issued card. */
  ENABLE_ISSUED_CARD = "ENABLE_ISSUED_CARD",
  /** DISABLE_ISSUED_CARD - The call originated in a flow for disabling a customer’s issued card. */
  DISABLE_ISSUED_CARD = "DISABLE_ISSUED_CARD",
  /** LOCK_ISSUED_CARD - The call originated in a flow for locking a customer’s issued card. */
  LOCK_ISSUED_CARD = "LOCK_ISSUED_CARD",
  /** UNLOCK_ISSUED_CARD - The call originated in a flow for unlocking a customer’s issued card. */
  UNLOCK_ISSUED_CARD = "UNLOCK_ISSUED_CARD",
  /**
   * ENABLE_AUTO_CASH_OUT - The call originated in a flow for enabling auto cash outs. The deposit preference to set may
   * still be undecided at this point. This is equivalent of DISABLE_CASH_BALANCE for apps prior
   * to v2.12.
   */
  ENABLE_AUTO_CASH_OUT = "ENABLE_AUTO_CASH_OUT",
  /** ENABLE_TRANSFER_INSTANTLY_WITH_FEE - The call originated in a flow for enabling transfer instantly with fee deposit preference. */
  ENABLE_TRANSFER_INSTANTLY_WITH_FEE = "ENABLE_TRANSFER_INSTANTLY_WITH_FEE",
  /** ENABLE_TRANSFER_SLOWLY_WITHOUT_FEE - The call originated in a flow for enabling transfer slow without fee deposit preference. */
  ENABLE_TRANSFER_SLOWLY_WITHOUT_FEE = "ENABLE_TRANSFER_SLOWLY_WITHOUT_FEE",
  /**
   * DEPOSIT_INSTANTLY_FROM_SLOW - The call originated in a flow to deposit payment instantly where
   * the payment was originally going to be deposited slowly.
   */
  DEPOSIT_INSTANTLY_FROM_SLOW = "DEPOSIT_INSTANTLY_FROM_SLOW",
  /** PROVISION_APPLE_PAY - The call originates in a flow for provisioning an issued card for Apple Pay. */
  PROVISION_APPLE_PAY = "PROVISION_APPLE_PAY",
  /**
   * SUGGEST_MERGE - The call originated in a flow to optionally merge into a similar customer prompted
   * by the server.
   */
  SUGGEST_MERGE = "SUGGEST_MERGE",
  /** REQUEST_PHYSICAL_CARD - The call originated in a flow for requesting a physical debit card. */
  REQUEST_PHYSICAL_CARD = "REQUEST_PHYSICAL_CARD",
  /**
   * REQUEST_PHYSICAL_CARD_APP_MESSAGE - The call originated in a flow for requesting a physical card from an app message.
   * This scenario can have different blockers than REQUEST_PHYSICAL_CARD.
   */
  REQUEST_PHYSICAL_CARD_APP_MESSAGE = "REQUEST_PHYSICAL_CARD_APP_MESSAGE",
  /** ACTIVATE_PHYSICAL_CARD - The call originated in a flow for activating a physical debit card after it has arrived. */
  ACTIVATE_PHYSICAL_CARD = "ACTIVATE_PHYSICAL_CARD",
  /** ACQUIRE_ALIAS - The call originated in a flow for acquiring an alias when already logged in. */
  ACQUIRE_ALIAS = "ACQUIRE_ALIAS",
  /** ACTIVATE_DIRECT_DEPOSIT_ACCOUNT - The call originated in a flow for activating a direct deposit account. */
  ACTIVATE_DIRECT_DEPOSIT_ACCOUNT = "ACTIVATE_DIRECT_DEPOSIT_ACCOUNT",
  /** REPORT_LOST_CARD - The call originated in a flow to report the loss/theft of a physical card. */
  REPORT_LOST_CARD = "REPORT_LOST_CARD",
  /**
   * REPORT_MISSING_REWARD - The call originated in a flow to report a missing reward.
   *
   * @deprecated
   */
  REPORT_MISSING_REWARD = "REPORT_MISSING_REWARD",
  /** ENABLE_CRYPTOCURRENCY - The call originated in a flow to enable a BTC balance. */
  ENABLE_CRYPTOCURRENCY = "ENABLE_CRYPTOCURRENCY",
  /** TRANSFER_CRYPTOCURRENCY - The call originated in a flow to transfer BTC from BTC balance. */
  TRANSFER_CRYPTOCURRENCY = "TRANSFER_CRYPTOCURRENCY",
  /** EXCHANGE_CURRENCY - The call originated in a flow to exchange currency (e.g., BTC for USD, or USD for BTC). */
  EXCHANGE_CURRENCY = "EXCHANGE_CURRENCY",
  /** ENABLE_CRYPTOCURRENCY_TRANSFER_IN - The call originated in a flow to enable transfers from an external wallet to the BTC balance. */
  ENABLE_CRYPTOCURRENCY_TRANSFER_IN = "ENABLE_CRYPTOCURRENCY_TRANSFER_IN",
  /** ENABLE_CRYPTOCURRENCY_TRANSFER_OUT - The call originated in a flow to enable transfers from the BTC balance to an external wallet. */
  ENABLE_CRYPTOCURRENCY_TRANSFER_OUT = "ENABLE_CRYPTOCURRENCY_TRANSFER_OUT",
  /** GET_REWARDS - The call originated in a flow to get rewards. */
  GET_REWARDS = "GET_REWARDS",
  /** ADD_OR_UPDATE_REWARD - The call originated in a flow to add or update a reward. */
  ADD_OR_UPDATE_REWARD = "ADD_OR_UPDATE_REWARD",
  /** REPORT_MISSING_BOOST - The call originated in a flow to report a missing boost on a transaction. */
  REPORT_MISSING_BOOST = "REPORT_MISSING_BOOST",
  /** ENABLE_SCHEDULED_RELOAD - A flow to create or update a scheduled reload preference (aka auto add cash). */
  ENABLE_SCHEDULED_RELOAD = "ENABLE_SCHEDULED_RELOAD",
  /** DISABLE_SCHEDULED_RELOAD - A flow to disable the scheduled reload preference. */
  DISABLE_SCHEDULED_RELOAD = "DISABLE_SCHEDULED_RELOAD",
  /** UPDATE_SCHEDULED_RELOAD_AMOUNT - A flow to update the scheduled reload amount. */
  UPDATE_SCHEDULED_RELOAD_AMOUNT = "UPDATE_SCHEDULED_RELOAD_AMOUNT",
  /** UPDATE_SCHEDULED_RELOAD_FREQUENCY - A flow to update the scheduled reload recurring schedule. */
  UPDATE_SCHEDULED_RELOAD_FREQUENCY = "UPDATE_SCHEDULED_RELOAD_FREQUENCY",
  /** EXCHANGE_EQUITY - A flow to trade equities for cash. */
  EXCHANGE_EQUITY = "EXCHANGE_EQUITY",
  /** ACQUIRE_ALIAS_FOR_MISSING_PAYMENT - A flow to find a missing payment. */
  ACQUIRE_ALIAS_FOR_MISSING_PAYMENT = "ACQUIRE_ALIAS_FOR_MISSING_PAYMENT",
  /** PROVISION_GOOGLE_PAY - The call originates in a flow for provisioning an issued card for Google Pay. */
  PROVISION_GOOGLE_PAY = "PROVISION_GOOGLE_PAY",
  /** CHANGE_CARD_DESIGN - A flow to customize and order a physical card again. */
  CHANGE_CARD_DESIGN = "CHANGE_CARD_DESIGN",
  /** RESOLVE_SUSPENSION - A flow to provide information needed to unsuspend a customer. */
  RESOLVE_SUSPENSION = "RESOLVE_SUSPENSION",
  /** REQUEST_EMERGENCY_CARD - A flow to request a temporary emergency card. */
  REQUEST_EMERGENCY_CARD = "REQUEST_EMERGENCY_CARD",
  /** VERIFY_INSTRUMENT - A flow to verify a risky instrument. */
  VERIFY_INSTRUMENT = "VERIFY_INSTRUMENT",
  /** BITCOIN_DEPOSIT_REVERSAL - A flow for reversing a rejected Bitcoin deposit to an external wallet address. */
  BITCOIN_DEPOSIT_REVERSAL = "BITCOIN_DEPOSIT_REVERSAL",
  /** LINK_BANK_ACCOUNT_USING_YODLEE - A flow to request link a new bank account using Yodlee. */
  LINK_BANK_ACCOUNT_USING_YODLEE = "LINK_BANK_ACCOUNT_USING_YODLEE",
  /** RECOVER_ACCOUNT - Identify an account to recover and then verify sufficient assets to be allowed back in. */
  RECOVER_ACCOUNT = "RECOVER_ACCOUNT",
  /** ADD_EMAIL_ALIAS - A flow to add an email alias to the customer account. */
  ADD_EMAIL_ALIAS = "ADD_EMAIL_ALIAS",
  /** CONFIRM_CLOSE_ACCOUNT - This client scenario will directly close the customer's account, if they are eligible. */
  CONFIRM_CLOSE_ACCOUNT = "CONFIRM_CLOSE_ACCOUNT",
  /**
   * CLOSE_ACCOUNT - This is a placeholder for a client scenario that will be implemented. It will encapsulate the flow for
   * ensuring a customer is eligible to close their account, and if the criteria are met, will close the account.
   */
  CLOSE_ACCOUNT = "CLOSE_ACCOUNT",
  /** ADD_SMS_ALIAS - A flow to add a phone number alias to the customer account. */
  ADD_SMS_ALIAS = "ADD_SMS_ALIAS",
  /** INITIATE_LOAN - A flow to initiate a borrow instantly loan. */
  INITIATE_LOAN = "INITIATE_LOAN",
  /** INITIATE_LOAN_PAYMENT - A flow to initiate a loan payment. */
  INITIATE_LOAN_PAYMENT = "INITIATE_LOAN_PAYMENT",
  APPROVE_ACCOUNT_INTEGRATION = "APPROVE_ACCOUNT_INTEGRATION",
  /** RECOVER_ALIAS_ACCOUNT - Recover Account implicit flavour. */
  RECOVER_ALIAS_ACCOUNT = "RECOVER_ALIAS_ACCOUNT",
  INVITE_FRIENDS = "INVITE_FRIENDS",
  /** SKIP_LOAN_PAYMENT - A flow to skip a loan payment. */
  SKIP_LOAN_PAYMENT = "SKIP_LOAN_PAYMENT",
  /** ENABLE_SCHEDULED_INVESTMENT_BUY - A flow to create or update a scheduled investment (equity/btc) buy preference. */
  ENABLE_SCHEDULED_INVESTMENT_BUY = "ENABLE_SCHEDULED_INVESTMENT_BUY",
  /** DISABLE_SCHEDULED_INVESTMENT_BUY - A flow to disable the a scheduled (equity/btc) buy preference. */
  DISABLE_SCHEDULED_INVESTMENT_BUY = "DISABLE_SCHEDULED_INVESTMENT_BUY",
  /** UPDATE_SCHEDULED_INVESTMENT_BUY_AMOUNT - A flow to update the a scheduled (equity/btc) buy preference. */
  UPDATE_SCHEDULED_INVESTMENT_BUY_AMOUNT = "UPDATE_SCHEDULED_INVESTMENT_BUY_AMOUNT",
  /** UPDATE_SCHEDULED_INVESTMENT_BUY_FREQUENCY - A flow to update the a scheduled (equity/btc) buy preference. */
  UPDATE_SCHEDULED_INVESTMENT_BUY_FREQUENCY = "UPDATE_SCHEDULED_INVESTMENT_BUY_FREQUENCY",
  /** SEND_TAX_FORM_EMAIL - A flow to send investing tax form to customer's email. */
  SEND_TAX_FORM_EMAIL = "SEND_TAX_FORM_EMAIL",
  /** AMEND_CARD_MAILING_ADDRESS - Update the shipping address for a new physical card. */
  AMEND_CARD_MAILING_ADDRESS = "AMEND_CARD_MAILING_ADDRESS",
  /** INITIATE_REFUND_REQUEST - Initiate payment sender request for a refund on a payment. */
  INITIATE_REFUND_REQUEST = "INITIATE_REFUND_REQUEST",
  /** REVIEW_REFUND_REQUEST - Payment recipient review of a senders request for a refund (Refund or decline). */
  REVIEW_REFUND_REQUEST = "REVIEW_REFUND_REQUEST",
  /** OAUTH - A flow to complete authorization of a third party. */
  OAUTH = "OAUTH",
  /** DEPOSIT_CHECK - A flow to deposit a check. */
  DEPOSIT_CHECK = "DEPOSIT_CHECK",
  /** DDA_DETAILS - A flow to show specifics around a customer's DDA and how to setup direct deposit. */
  DDA_DETAILS = "DDA_DETAILS",
  /**
   * GOVERNMENT_STIMULUS_1 - Client scenarios reserved potentially new flows that we introduce as a part of handling
   * government stimulus payouts for COVID-19. (May or may not be used.)
   */
  GOVERNMENT_STIMULUS_1 = "GOVERNMENT_STIMULUS_1",
  GOVERNMENT_STIMULUS_2 = "GOVERNMENT_STIMULUS_2",
  GOVERNMENT_STIMULUS_3 = "GOVERNMENT_STIMULUS_3",
  GOVERNMENT_STIMULUS_4 = "GOVERNMENT_STIMULUS_4",
  GOVERNMENT_STIMULUS_5 = "GOVERNMENT_STIMULUS_5",
  DISPUTE_CARD_TRANSACTION = "DISPUTE_CARD_TRANSACTION",
  /** ENABLE_LOAN_AUTOPAY - A flow to enable autopay for a loan. */
  ENABLE_LOAN_AUTOPAY = "ENABLE_LOAN_AUTOPAY",
  /** DISABLE_LOAN_AUTOPAY - A flow to disable autopay for a loan. */
  DISABLE_LOAN_AUTOPAY = "DISABLE_LOAN_AUTOPAY",
  /** INITIATE_CRYPTO_PAYMENT - Initiating a request to pay someone in crypto */
  INITIATE_CRYPTO_PAYMENT = "INITIATE_CRYPTO_PAYMENT",
  /** GET_DIRECT_DEPOSIT_FORM - A flow to create a form to setup direct deposits. */
  GET_DIRECT_DEPOSIT_FORM = "GET_DIRECT_DEPOSIT_FORM",
  /** VERIFY_IDENTITY - A flow to trigger IDV. */
  VERIFY_IDENTITY = "VERIFY_IDENTITY",
  /** VIEW_DIRECT_DEPOSIT_FORM - Initiate a request to get a previously generated direct deposit form. */
  VIEW_DIRECT_DEPOSIT_FORM = "VIEW_DIRECT_DEPOSIT_FORM",
  /**
   * UNLOCK_BORROW - A flow to unlock Borrow for customers that haven't completed the requirements.
   *
   * Deprecated: This has been dropped in favor of Borrow Null State.
   *
   * @deprecated
   */
  UNLOCK_BORROW = "UNLOCK_BORROW",
  /** REVIEW_BORROW_FEE_STATUS - A flow to review the customer's fee status for Borrow. */
  REVIEW_BORROW_FEE_STATUS = "REVIEW_BORROW_FEE_STATUS",
  /** GOOGLE_PAY_PROVISIONING - A flow to assist google pay when provisioning a physical card. */
  GOOGLE_PAY_PROVISIONING = "GOOGLE_PAY_PROVISIONING",
  /** GET_EVENT_BASED_BOOST_DETAIL - A flow to retrieve details on event based boost. */
  GET_EVENT_BASED_BOOST_DETAIL = "GET_EVENT_BASED_BOOST_DETAIL",
  /**
   * INITIATE_BANK_ACCOUNT_LINKING - A flow to link a bank account via OAuth or manual ACH.
   *
   * Unused as of 3.27, reserved for future use by the Network workstream.
   */
  INITIATE_BANK_ACCOUNT_LINKING = "INITIATE_BANK_ACCOUNT_LINKING",
  /** REPORT_SCAM - A flow to report a suspected scam payment. */
  REPORT_SCAM = "REPORT_SCAM",
  /** ACCEPT_CRYPTO_PAYMENT - Accept a pending crypto payment */
  ACCEPT_CRYPTO_PAYMENT = "ACCEPT_CRYPTO_PAYMENT",
  /** UNLOCK_QR_CODE_BOOST - Unlock boost using QR code */
  UNLOCK_QR_CODE_BOOST = "UNLOCK_QR_CODE_BOOST",
  /** DEEP_LINK_ACTIVATE_PHYSICAL_CARD - Deep link activate physical card */
  DEEP_LINK_ACTIVATE_PHYSICAL_CARD = "DEEP_LINK_ACTIVATE_PHYSICAL_CARD",
  /** ACCEPT_INVEST_PAYMENT - Recipient accepts p2p stock gift */
  ACCEPT_INVEST_PAYMENT = "ACCEPT_INVEST_PAYMENT",
  /** SEND_INVEST_PAYMENT - Sender sends p2p stock gift */
  SEND_INVEST_PAYMENT = "SEND_INVEST_PAYMENT",
  /** EDIT_PROFILE_BIO - A flow to edit your bio. */
  EDIT_PROFILE_BIO = "EDIT_PROFILE_BIO",
  /** REPORT_PROFILE - A flow to report a profile. */
  REPORT_PROFILE = "REPORT_PROFILE",
  /** DECLINE_CRYPTO_PAYMENT - Decline a pending crypto payment */
  DECLINE_CRYPTO_PAYMENT = "DECLINE_CRYPTO_PAYMENT",
  /** UPGRADE_APP_VERSION_LENDING - A flow that forces a customer to update their app to access Lending functionality. */
  UPGRADE_APP_VERSION_LENDING = "UPGRADE_APP_VERSION_LENDING",
  /**
   * INITIATE_LENDING_FLOW_2 - A flow used in the Lending Repayment Optimization Experiments to display rate change
   * information to users in some of the buckets.
   */
  INITIATE_LENDING_FLOW_2 = "INITIATE_LENDING_FLOW_2",
  /**
   * INITIATE_LENDING_FLOW_1 - ClientScenarios for future Lending use, such as displaying a message of upcoming offer
   * expiration, or informing a customer of changes to their credit line.
   */
  INITIATE_LENDING_FLOW_1 = "INITIATE_LENDING_FLOW_1",
  INITIATE_LENDING_FLOW_3 = "INITIATE_LENDING_FLOW_3",
  INITIATE_LENDING_FLOW_4 = "INITIATE_LENDING_FLOW_4",
  INITIATE_LENDING_FLOW_5 = "INITIATE_LENDING_FLOW_5",
  INITIATE_LENDING_FLOW_6 = "INITIATE_LENDING_FLOW_6",
  INITIATE_LENDING_FLOW_7 = "INITIATE_LENDING_FLOW_7",
  NEW_DEVICE_LOGIN = "NEW_DEVICE_LOGIN",
  ACTIVATE_PHYSICAL_CARD_FROM_NOTIFICATION = "ACTIVATE_PHYSICAL_CARD_FROM_NOTIFICATION",
  DEPOSIT_PAPER_CASH = "DEPOSIT_PAPER_CASH",
  GET_BANKING_INTERSTITIAL = "GET_BANKING_INTERSTITIAL",
  TRANSFER_FROM_BANKS = "TRANSFER_FROM_BANKS",
  /** REQUEST_SPONSORSHIP - A flow for an under-18 account to request sponsorship from an adult. */
  REQUEST_SPONSORSHIP = "REQUEST_SPONSORSHIP",
  /** APPROVE_SPONSORSHIP - A flow for an adult to approve a sponsorship request from an under-18 account. */
  APPROVE_SPONSORSHIP = "APPROVE_SPONSORSHIP",
  /** INITIATE_TAX_UPGRADE - A flow for ensuring a customer meets IRS requirements to file taxes with us. */
  INITIATE_TAX_UPGRADE = "INITIATE_TAX_UPGRADE",
  DEPOSIT_PAPER_CASH_CONFIRMATION = "DEPOSIT_PAPER_CASH_CONFIRMATION",
  GET_INSTANT_PAYOUT = "GET_INSTANT_PAYOUT",
  /** UNLOCK_BANKING_DEPOSIT_OPTIONS - A flow that unlocks banking options for the user. */
  UNLOCK_BANKING_DEPOSIT_OPTIONS = "UNLOCK_BANKING_DEPOSIT_OPTIONS",
  /** INITIATE_CRYPTO_WITHDRAWAL - A flow to intitiate a BTC withdrawal to the blockchain */
  INITIATE_CRYPTO_WITHDRAWAL = "INITIATE_CRYPTO_WITHDRAWAL",
  /** ENTER_QR_MARKETING_EVENT - A flow to enter a marketing event by scanning a QR code. */
  ENTER_QR_MARKETING_EVENT = "ENTER_QR_MARKETING_EVENT",
  /** CARD_EXPIRATION - A flow to notify customer their card is expiring and to reorder card. */
  CARD_EXPIRATION = "CARD_EXPIRATION",
  /**
   * PLASMA - A dummy ClientScenario that denotes that the flow was handled by Plasma.
   *
   * The logic involved in this case does not use the concept of ClientScenarios and so a true one is
   * not returned.
   */
  PLASMA = "PLASMA",
  /** ACCEPT_TERMS_OF_SERVICE_AGREEMENT - A flow to display and allow user to accept terms of service agreement */
  ACCEPT_TERMS_OF_SERVICE_AGREEMENT = "ACCEPT_TERMS_OF_SERVICE_AGREEMENT",
  /** ADD_CASH - A flow related to pressing the Add Cash button. Currently used for Debit Cash Ins. */
  ADD_CASH = "ADD_CASH",
  /**
   * STANDALONE_IDV - A flow to trigger IDV for customers directly without them having to buy stocks/send btc/etc.
   *
   * This is different from the VERIFY_IDENTITY flow because VERIFY_IDENTITY is actually intended
   * for customers who have already successfully IDVed and are trying to re-do IDV to update their
   * information (for example, a legal name change). This flow is intended for customers who were
   * previously unable to complete IDV and are trying to continue IDV from a link we sent them in
   * a notification/email.
   */
  STANDALONE_IDV = "STANDALONE_IDV",
  /** DIRECT_DEPOSIT_SWITCH_VIA_PAYROLL - A flow to switch customer direct deposit via payroll login. */
  DIRECT_DEPOSIT_SWITCH_VIA_PAYROLL = "DIRECT_DEPOSIT_SWITCH_VIA_PAYROLL",
  /** CARD_LSB_REORDER - A flow to notify customer their shazam card is about to terminate and to reorder card. */
  CARD_LSB_REORDER = "CARD_LSB_REORDER",
  /** RESEND_SPONSORSHIP_REQUEST - A flow to trigger an additional request for sponsorship for an under-18 account. */
  RESEND_SPONSORSHIP_REQUEST = "RESEND_SPONSORSHIP_REQUEST",
  /** INITIATE_LIGHTNING_WITHDRAWAL - A flow to initiate a withdrawal of BTC via the lightning network. */
  INITIATE_LIGHTNING_WITHDRAWAL = "INITIATE_LIGHTNING_WITHDRAWAL",
  /** LINK_PAYROLL_ACCOUNT - A flow to provide payroll login primer and authenticate a customer with its payroll provider. */
  LINK_PAYROLL_ACCOUNT = "LINK_PAYROLL_ACCOUNT",
  /** GET_ESCHEATMENTS - A flow to launch (hackweek) government escheatments flow. */
  GET_ESCHEATMENTS = "GET_ESCHEATMENTS",
  /** SEND_PRINT_CHECK - A flow to launch (hackweek) send or print check flow. */
  SEND_PRINT_CHECK = "SEND_PRINT_CHECK",
  /**
   * RE_VERIFY_IDV - This flow is for customers who were verified incorrectly. It will first invalidate all
   * previous attempts for the customer and send them through the IDV subflow to verify again.
   */
  RE_VERIFY_IDV = "RE_VERIFY_IDV",
  /** DIRECT_DEPOSIT_SWITCH_VIA_PAYROLL_CONFIRMATION - Flow to confirm switching customer's direct deposit via payroll. */
  DIRECT_DEPOSIT_SWITCH_VIA_PAYROLL_CONFIRMATION = "DIRECT_DEPOSIT_SWITCH_VIA_PAYROLL_CONFIRMATION",
  /** DD_SWITCH_UPSELL_CARD_ACTIVATED - Flow for upsell direct deposit switch at the end of a successful card activation. */
  DD_SWITCH_UPSELL_CARD_ACTIVATED = "DD_SWITCH_UPSELL_CARD_ACTIVATED",
  /** ACCEPT_GIFT_CARD_PAYMENT - Recipient accepts p2p gift card */
  ACCEPT_GIFT_CARD_PAYMENT = "ACCEPT_GIFT_CARD_PAYMENT",
  /** CANCEL_INVEST_PAYMENT - Recipient cancels p2p stock gift */
  CANCEL_INVEST_PAYMENT = "CANCEL_INVEST_PAYMENT",
  /** INITIATE_BORROW_NULL_STATE_FLOW - Flow to show Borrow to ineligible customers */
  INITIATE_BORROW_NULL_STATE_FLOW = "INITIATE_BORROW_NULL_STATE_FLOW",
  /** INITIATE_GLOBAL_PAYMENT - Initiate a payment to a customer on another payment network (Global Network of Networks) */
  INITIATE_GLOBAL_PAYMENT = "INITIATE_GLOBAL_PAYMENT",
  /** CONVERT_GIFT_CARD_TO_CASH - Convert a gift card payment to cash in user's account */
  CONVERT_GIFT_CARD_TO_CASH = "CONVERT_GIFT_CARD_TO_CASH",
  /** DIRECT_DEPOSIT_SWITCH_UPSELL - Upsell direct deposit switch. To be used in different contexts: card ordering, activation, etc. */
  DIRECT_DEPOSIT_SWITCH_UPSELL = "DIRECT_DEPOSIT_SWITCH_UPSELL",
  /** FAMILY_ACCOUNT - Family account menu from the app. */
  FAMILY_ACCOUNT = "FAMILY_ACCOUNT",
  /** CANCEL_PENDING_ROUND_UP_EXECUTION - Cancel a pending Round Up execution. */
  CANCEL_PENDING_ROUND_UP_EXECUTION = "CANCEL_PENDING_ROUND_UP_EXECUTION",
  /** SEND_GIFT_CARD_PAYMENT - Send a Gift Card payment to other customers. */
  SEND_GIFT_CARD_PAYMENT = "SEND_GIFT_CARD_PAYMENT",
  /**
   * ACTIVATE_PHYSICAL_CARD_IN_POSTCARD - Deprecated client scenario, all activate physical card should use ACTIVATE_PHYSICAL_CARD
   *
   * @deprecated
   */
  ACTIVATE_PHYSICAL_CARD_IN_POSTCARD = "ACTIVATE_PHYSICAL_CARD_IN_POSTCARD",
  /**
   * INITIATE_BITCOIN_WITHDRAWAL - A flow to initiate a withdrawal of BTC via the lightning network or on-chain.
   * This is a new unified flow to replace INITIATE_CRYPTO_WITHDRAWAL and  INITIATE_LIGHTNING_WITHDRAWAL.
   */
  INITIATE_BITCOIN_WITHDRAWAL = "INITIATE_BITCOIN_WITHDRAWAL",
  GET_NON_RECOURSE_PAYOUT = "GET_NON_RECOURSE_PAYOUT",
  /** WIRE_TRANSFERS - This flow will be kicked off to display details about how to make a Wire transfer. */
  WIRE_TRANSFERS = "WIRE_TRANSFERS",
  /**
   * ENABLE_APP_LOCK - A flow to enable the app lock feature.
   * After a timeout period customers would need to authenticate via biometrics or passcode to use the app.
   */
  ENABLE_APP_LOCK = "ENABLE_APP_LOCK",
  /** DISABLE_APP_LOCK - A flow to disable the app lock feature. */
  DISABLE_APP_LOCK = "DISABLE_APP_LOCK",
  /**
   * UNLOCK_APP - A flow to prompt the user to authenticate when the app lock feature is enabled and the
   * app has been inactive longer than the timeout threshold allows.
   */
  UNLOCK_APP = "UNLOCK_APP",
  /** BANK_AND_WIRE_TRANSFERS - This flow will be kicked off if a user has not gone through idv but should get access to Wires. */
  BANK_AND_WIRE_TRANSFERS = "BANK_AND_WIRE_TRANSFERS",
  /** FIND_MONEY_RETAILERS - A flow to show retailers where money can be withdrawn */
  FIND_MONEY_RETAILERS = "FIND_MONEY_RETAILERS",
  /** IDENTITY_HUB - A flow to manage IDV info */
  IDENTITY_HUB = "IDENTITY_HUB",
  /** RESEND_SPONSORSHIP_FEATURE_INVITATION - A flow to trigger an additional request for out of network sponsorship for an under-18 account. */
  RESEND_SPONSORSHIP_FEATURE_INVITATION = "RESEND_SPONSORSHIP_FEATURE_INVITATION",
  /** INITIATE_MULTICURRENCY_PAYMENT_FOR_RECIPIENT - A flow which kicks off the crossborder-2.0 payment experience, used when the recipient is known */
  INITIATE_MULTICURRENCY_PAYMENT_FOR_RECIPIENT = "INITIATE_MULTICURRENCY_PAYMENT_FOR_RECIPIENT",
  /**
   * INITIATE_MULTICURRENCY_PAYMENT_FOR_REGION - A flow which kicks off the crossborder-2.0 payment experience, used when the recipient is not
   * known, but their region is known
   */
  INITIATE_MULTICURRENCY_PAYMENT_FOR_REGION = "INITIATE_MULTICURRENCY_PAYMENT_FOR_REGION",
  /** RECOVER_ACCOUNT_V2 - New account recovery flow, handled in Janus */
  RECOVER_ACCOUNT_V2 = "RECOVER_ACCOUNT_V2",
  /**
   * POST_SIGN_IN - Post sign in flows that are initiated by login flows that start outside Franklin.
   * Not to be confused with the ONBOARDING client scenario, which is used for signup/login flows that start in Franklin's OnboardingHandler.
   */
  POST_SIGN_IN = "POST_SIGN_IN",
  /**
   * DISPLAY_NAME - DISPLAY_NAME is a Plasma flow but the client relies on the profile object so certain calls must go to Franklin
   * In the future, the DISPLAY_NAME flow should use the PLASMA client scenario but for now, this flow will use the DISPLAY_NAME client scenario.
   */
  DISPLAY_NAME = "DISPLAY_NAME",
  /** REQUEST_SPONSOR_LED_SPONSORSHIP - A flow for an adult account to request to sponsor an under-18. */
  REQUEST_SPONSOR_LED_SPONSORSHIP = "REQUEST_SPONSOR_LED_SPONSORSHIP",
  /** APPROVE_SPONSOR_LED_SPONSORSHIP - A flow for an under-18 account to approve a sponsorship request from an adult. */
  APPROVE_SPONSOR_LED_SPONSORSHIP = "APPROVE_SPONSOR_LED_SPONSORSHIP",
}
