/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AuthenticateWebLoginTokenRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {AuthenticateWebLoginTokenResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const authenticateWebLoginToken = (request:AuthenticateWebLoginTokenRequest, options?: CashRequestOptions) => post<AuthenticateWebLoginTokenRequest, AuthenticateWebLoginTokenResponse>("/2.0/cash/authenticate-web-login-token", request, {...options, rejectOnError: true })