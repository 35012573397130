import { useCallback } from 'react'

import { useRegion } from '@cash-web/shared-data-access-region-provider'

import { logPageView } from './eventstream2'

type PageViewEventData = {
  page: string
}

export function useLogPageViewEventstream(): (data: PageViewEventData) => void {
  const regionCode = useRegion()
  return useCallback(
    ({ page }: PageViewEventData) => {
      logPageView(page, regionCode)
    },
    [regionCode]
  )
}
