/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishCreditMultiStepLoadRequest} from '@cash-web/protos/squareup/franklin/app/credit_multi_step_load.pb'
import {FinishCreditMultiStepLoadResponse} from '@cash-web/protos/squareup/franklin/app/credit_multi_step_load.pb'


export const finishCreditMultiStepLoad = (request:FinishCreditMultiStepLoadRequest, options?: CashRequestOptions) => post<FinishCreditMultiStepLoadRequest, FinishCreditMultiStepLoadResponse>("/2.0/cash/finish-credit-multi-step-load", request, {...options, rejectOnError: true })