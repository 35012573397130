/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetReceiptRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {GetReceiptResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const getReceipt = (request:GetReceiptRequest, options?: CashRequestOptions) => post<GetReceiptRequest, GetReceiptResponse>("/2.0/cash/get-receipt", request, {...options, rejectOnError: true })