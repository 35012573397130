/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetWebLoginConfigRequest} from '@cash-web/protos/squareup/franklin/app/web_login_config.pb'
import {GetWebLoginConfigResponse} from '@cash-web/protos/squareup/franklin/app/web_login_config.pb'


export const getWebLoginConfig = (request:GetWebLoginConfigRequest, options?: CashRequestOptions) => post<GetWebLoginConfigRequest, GetWebLoginConfigResponse>("/2.0/cash/get-web-login-config", request, {...options, rejectOnError: true })