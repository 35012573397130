/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetAddressRequest} from '@cash-web/protos/squareup/franklin/app/address.pb'
import {SetAddressResponse} from '@cash-web/protos/squareup/franklin/app/address.pb'


export const setAddress = (request:SetAddressRequest, options?: CashRequestOptions) => post<SetAddressRequest, SetAddressResponse>("/2.0/cash/set-address", request, {...options, rejectOnError: true })