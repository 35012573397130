/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SendDownloadLinkRequest} from '@cash-web/protos/squareup/franklin/app/download_link.pb'
import {SendDownloadLinkResponse} from '@cash-web/protos/squareup/franklin/app/download_link.pb'


export const sendDownloadLink = (request:SendDownloadLinkRequest, options?: CashRequestOptions) => post<SendDownloadLinkRequest, SendDownloadLinkResponse>("/2.0/cash/send-download-link", request, {...options, rejectOnError: true })