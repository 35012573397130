/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetLocationPermissionRequest} from '@cash-web/protos/squareup/franklin/app/location_permission.pb'
import {SetLocationPermissionResponse} from '@cash-web/protos/squareup/franklin/app/location_permission.pb'


export const setLocationPermission = (request:SetLocationPermissionRequest, options?: CashRequestOptions) => post<SetLocationPermissionRequest, SetLocationPermissionResponse>("/2.0/cash/set-location-permission", request, {...options, rejectOnError: true })