/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetWebAuthnCredentialsInternalRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {GetWebAuthnCredentialsResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const getWebAuthnCredentialsInternal = (request:GetWebAuthnCredentialsInternalRequest, options?: CashRequestOptions) => post<GetWebAuthnCredentialsInternalRequest, GetWebAuthnCredentialsResponse>("/2.0/cash/get-web-authn-credentials-internal", request, {...options, rejectOnError: true })