/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetWebAuthnCredentialsRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {GetWebAuthnCredentialsResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const getWebAuthnCredentials = (request:GetWebAuthnCredentialsRequest, options?: CashRequestOptions) => post<GetWebAuthnCredentialsRequest, GetWebAuthnCredentialsResponse>("/cash-app/security/1.0/get-webauthn-credentials", request, {...options, rejectOnError: true })