/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetCountryRequest} from '@cash-web/protos/squareup/franklin/app/country.pb'
import {SetCountryResponse} from '@cash-web/protos/squareup/franklin/app/country.pb'


export const setCountry = (request:SetCountryRequest, options?: CashRequestOptions) => post<SetCountryRequest, SetCountryResponse>("/2.0/cash/set-country", request, {...options, rejectOnError: true })