/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResetBadgeRequest} from '@cash-web/protos/squareup/franklin/app/badge.pb'
import {ResetBadgeResponse} from '@cash-web/protos/squareup/franklin/app/badge.pb'


export const resetBadge = (request:ResetBadgeRequest, options?: CashRequestOptions) => post<ResetBadgeRequest, ResetBadgeResponse>("/2.0/cash/reset-badge", request, {...options, rejectOnError: true })