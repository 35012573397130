/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAccountsByAccountHolderRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {GetAccountsByAccountHolderResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const getAccountsByAccountHolderInternal = (request:GetAccountsByAccountHolderRequest, options?: CashRequestOptions) => post<GetAccountsByAccountHolderRequest, GetAccountsByAccountHolderResponse>("/2.0/cash/get-accounts-by-account-holder-internal", request, {...options, rejectOnError: true })