/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RecordClientProvidedBackupTagRequest} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'
import {RecordClientProvidedBackupTagResponse} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'


export const recordClientProvidedBackupTag = (request:RecordClientProvidedBackupTagRequest, options?: CashRequestOptions) => post<RecordClientProvidedBackupTagRequest, RecordClientProvidedBackupTagResponse>("/2.0/cash/record-client-provided-backup-tag", request, {...options, rejectOnError: true })