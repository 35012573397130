/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetProfilePhotoRequest} from '@cash-web/protos/squareup/franklin/app/profile_photo.pb'
import {SetProfilePhotoResponse} from '@cash-web/protos/squareup/franklin/app/profile_photo.pb'


export const setProfilePhoto = (request:SetProfilePhotoRequest, options?: CashRequestOptions) => post<SetProfilePhotoRequest, SetProfilePhotoResponse>("/2.0/cash/set-profile-photo", request, {...options, rejectOnError: true })