/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ListPublicArticlesRequest} from '@cash-web/protos/squareup/cash/supportarticles/api/loggedout/v1/service.pb'
import {ListPublicArticlesResponse} from '@cash-web/protos/squareup/cash/supportarticles/api/loggedout/v1/service.pb'


export const listPublicArticles = (request:ListPublicArticlesRequest, options?: CashRequestOptions) => post<ListPublicArticlesRequest, ListPublicArticlesResponse>("/2.0/cash/list-public-articles", request, {...options, rejectOnError: true })