/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InviteContactsRequest} from '@cash-web/protos/squareup/franklin/app/contacts.pb'
import {InviteContactsResponse} from '@cash-web/protos/squareup/franklin/app/contacts.pb'


export const inviteContacts = (request:InviteContactsRequest, options?: CashRequestOptions) => post<InviteContactsRequest, InviteContactsResponse>("/2.0/cash/invite-contacts", request, {...options, rejectOnError: true })