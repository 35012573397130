import * as CryptoJSBase64 from 'crypto-js/enc-base64'
import { default as CryptoJSHmacSHA256 } from 'crypto-js/hmac-sha256'
import { default as CryptoJSSHA256 } from 'crypto-js/sha256'

// intentionally duplicated to avoid sharing
// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any -- obfuscation
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis
  }
  if (typeof self !== 'undefined') {
    return self
  }
  if (typeof window !== 'undefined') {
    return window
  }
  if (typeof global !== 'undefined') {
    return global
  }
  throw 'Unable to locate global object'
})()

/**
 * Part of implementing Anti Request Forgery Headers.
 * See `calculations.js` for more implementation.
 * https://docs.google.com/document/d/1yc7xhd_ED8xrlFY4A7COPNpAMowgIpwwkSThMut2kmA/edit#
 *
 * This file is purposefully vaguely named,
 * to aid in making it harder to reverse engineer.
 *
 * We are hiding the use of the `CryptoJS` library by obfuscating the function names and decoupling where we import it from where we use it.
 */
export function initialize() {
  // We are hiding the use of the `CryptoJS` library.
  const fromCharCode = String.fromCharCode.bind(null)

  // 'toBase64'
  const toBase64 = fromCharCode(116, 111, 66, 97, 115, 101, 54, 52)
  // 'toHmacSHA256'
  const toHmacSHA256 = fromCharCode(116, 111, 72, 109, 97, 99, 83, 72, 65, 50, 53, 54)
  // 'sha256'
  const toSHA256 = fromCharCode(116, 111, 83, 72, 65, 50, 53, 54)

  // 'stringify'
  const stringify = fromCharCode(115, 116, 114, 105, 110, 103, 105, 102, 121)

  // @ts-expect-error -- obfuscation
  globalThis[toBase64] = CryptoJSBase64[stringify].bind(CryptoJSBase64)
  globalThis[toHmacSHA256] = CryptoJSHmacSHA256
  globalThis[toSHA256] = CryptoJSSHA256
}
