/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetRewardStatusRequest} from '@cash-web/protos/squareup/franklin/app/reward_status.pb'
import {GetRewardStatusResponse} from '@cash-web/protos/squareup/franklin/app/reward_status.pb'


export const getRewardStatus = (request:GetRewardStatusRequest, options?: CashRequestOptions) => post<GetRewardStatusRequest, GetRewardStatusResponse>("/2.0/cash/get-reward-status", request, {...options, rejectOnError: true })