/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetDynamicLimitsRequest} from '@cash-web/protos/squareup/cash/btcmanabar/api/public.pb'
import {GetDynamicLimitsResponse} from '@cash-web/protos/squareup/cash/btcmanabar/api/public.pb'


export const getCryptoDynamicLimits = (request:GetDynamicLimitsRequest, options?: CashRequestOptions) => post<GetDynamicLimitsRequest, GetDynamicLimitsResponse>("/2.0/cash/get-crypto-dynamic-limits", request, {...options, rejectOnError: true })