/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSupportArticleRequest} from '@cash-web/protos/squareup/cash/supportarticles/app/v1beta1/app.pb'
import {GetSupportArticleResponse} from '@cash-web/protos/squareup/cash/supportarticles/app/v1beta1/app.pb'


export const getSupportArticle = (request:GetSupportArticleRequest, options?: CashRequestOptions) => post<GetSupportArticleRequest, GetSupportArticleResponse>("/cash-app/support-articles/v1/get", request, {...options, rejectOnError: true })