/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateCheckInRequest} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'
import {CreateCheckInResponse} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'


export const createCheckIn = (request:CreateCheckInRequest, options?: CashRequestOptions) => post<CreateCheckInRequest, CreateCheckInResponse>("/2.0/cash/create-check-in", request, {...options, rejectOnError: true })