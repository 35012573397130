/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {LoanAmountPickerRequest} from '@cash-web/protos/squareup/franklin/app/loan_amount_picker.pb'
import {LoanAmountPickerResponse} from '@cash-web/protos/squareup/franklin/app/loan_amount_picker.pb'


export const completeLoanAmountSelection = (request:LoanAmountPickerRequest, options?: CashRequestOptions) => post<LoanAmountPickerRequest, LoanAmountPickerResponse>("/2.0/cash/complete-loan-amount-selection", request, {...options, rejectOnError: true })