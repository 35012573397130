/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBillerCategoriesRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBillerCategories.pb'
import {GetBillerCategoriesResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBillerCategories.pb'


export const getBillerCategories = (request:GetBillerCategoriesRequest, options?: CashRequestOptions) => post<GetBillerCategoriesRequest, GetBillerCategoriesResponse>("/2.0/cash/get-biller-categories", request, {...options, rejectOnError: true })