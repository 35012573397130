/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {DeregisterWebAuthnCredentialRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {DeregisterWebAuthnCredentialResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const deregisterWebAuthnCredential = (request:DeregisterWebAuthnCredentialRequest, options?: CashRequestOptions) => post<DeregisterWebAuthnCredentialRequest, DeregisterWebAuthnCredentialResponse>("/cash-app/security/1.0/deregister-webauthn-credential", request, {...options, rejectOnError: true })