/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteSetPostalCodeRequest} from '@cash-web/protos/squareup/franklin/app/postal_code.pb'
import {ExecuteSetPostalCodeResponse} from '@cash-web/protos/squareup/franklin/app/postal_code.pb'


export const executeSetPostalCode = (request:ExecuteSetPostalCodeRequest, options?: CashRequestOptions) => post<ExecuteSetPostalCodeRequest, ExecuteSetPostalCodeResponse>("/2.0/cash/execute-set-postal-code", request, {...options, rejectOnError: true })