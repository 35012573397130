/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CompleteDigitalWalletTokenProvisioningRequest} from '@cash-web/protos/squareup/franklin/app/digital_wallet_token.pb'
import {CompleteDigitalWalletTokenProvisioningResponse} from '@cash-web/protos/squareup/franklin/app/digital_wallet_token.pb'


export const completeDigitalWalletTokenProvisioning = (request:CompleteDigitalWalletTokenProvisioningRequest, options?: CashRequestOptions) => post<CompleteDigitalWalletTokenProvisioningRequest, CompleteDigitalWalletTokenProvisioningResponse>("/2.0/cash/complete-digital-wallet-token-provisioning", request, {...options, rejectOnError: true })