/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ApplyRewardCodeRequest} from '@cash-web/protos/squareup/franklin/app/reward_code.pb'
import {ApplyRewardCodeResponse} from '@cash-web/protos/squareup/franklin/app/reward_code.pb'


export const applyRewardCode = (request:ApplyRewardCodeRequest, options?: CashRequestOptions) => post<ApplyRewardCodeRequest, ApplyRewardCodeResponse>("/2.0/cash/apply-reward-code", request, {...options, rejectOnError: true })