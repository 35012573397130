/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetLocationConfigRequest} from '@cash-web/protos/squareup/franklin/app/location_config.pb'
import {GetLocationConfigResponse} from '@cash-web/protos/squareup/franklin/app/location_config.pb'


export const getLocationConfig = (request:GetLocationConfigRequest, options?: CashRequestOptions) => post<GetLocationConfigRequest, GetLocationConfigResponse>("/2.0/cash/get-location-config", request, {...options, rejectOnError: true })