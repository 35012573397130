/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateGroupOrderParticipantRequest} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'
import {CreateGroupOrderParticipantResponse} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'


export const createGroupOrderParticipant = (request:CreateGroupOrderParticipantRequest, options?: CashRequestOptions) => post<CreateGroupOrderParticipantRequest, CreateGroupOrderParticipantResponse>("/2.0/cash/create-group-order-participant", request, {...options, rejectOnError: true })