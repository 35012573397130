/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SearchEntitiesBatchRequest} from '@cash-web/protos/squareup/cash/customersearch/api/v2/SearchEntities.pb'
import {SearchEntitiesBatchResponse} from '@cash-web/protos/squareup/cash/customersearch/api/v2/SearchEntities.pb'


export const searchEntitiesBatch = (request:SearchEntitiesBatchRequest, options?: CashRequestOptions) => post<SearchEntitiesBatchRequest, SearchEntitiesBatchResponse>("/2.0/cash/search-entities-batch", request, {...options, rejectOnError: true })