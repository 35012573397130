/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ReportAbuseRequest} from '@cash-web/protos/squareup/franklin/app/abuse.pb'
import {ReportAbuseResponse} from '@cash-web/protos/squareup/franklin/app/abuse.pb'


export const reportAbuse = (request:ReportAbuseRequest, options?: CashRequestOptions) => post<ReportAbuseRequest, ReportAbuseResponse>("/2.0/cash/report-abuse", request, {...options, rejectOnError: true })