/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {MultiCurrencyPaymentReviewRequest} from '@cash-web/protos/squareup/franklin/app/multi_currency.pb'
import {MultiCurrencyPaymentReviewResponse} from '@cash-web/protos/squareup/franklin/app/multi_currency.pb'


export const acceptMulticurrencyQuote = (request:MultiCurrencyPaymentReviewRequest, options?: CashRequestOptions) => post<MultiCurrencyPaymentReviewRequest, MultiCurrencyPaymentReviewResponse>("/2.0/cash/accept-multicurrency-quote", request, {...options, rejectOnError: true })