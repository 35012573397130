/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishInstantPayDirectDepositSwitchRequest} from '@cash-web/protos/squareup/franklin/app/instant_pay.pb'
import {FinishInstantPayDirectDepositSwitchResponse} from '@cash-web/protos/squareup/franklin/app/instant_pay.pb'


export const finishInstantPayDirectDepositSwitch = (request:FinishInstantPayDirectDepositSwitchRequest, options?: CashRequestOptions) => post<FinishInstantPayDirectDepositSwitchRequest, FinishInstantPayDirectDepositSwitchResponse>("/2.0/cash/finish-instant-pay-direct-deposit-switch", request, {...options, rejectOnError: true })