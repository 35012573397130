/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SyncContactsRequest} from '@cash-web/protos/squareup/franklin/app/contacts.pb'
import {SyncContactsResponse} from '@cash-web/protos/squareup/franklin/app/contacts.pb'


export const syncContacts = (request:SyncContactsRequest, options?: CashRequestOptions) => post<SyncContactsRequest, SyncContactsResponse>("/2.0/cash/sync-contacts", request, {...options, rejectOnError: true })