/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyIdentityRequest} from '@cash-web/protos/squareup/franklin/app/identity.pb'
import {VerifyIdentityResponse} from '@cash-web/protos/squareup/franklin/app/identity.pb'


export const verifyIdentity = (request:VerifyIdentityRequest, options?: CashRequestOptions) => post<VerifyIdentityRequest, VerifyIdentityResponse>("/2.0/cash/verify-identity", request, {...options, rejectOnError: true })