/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetIndividualTaxInformationRequest} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'
import {SetIndividualTaxInformationResponse} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'


export const setIndividualTaxInformation = (request:SetIndividualTaxInformationRequest, options?: CashRequestOptions) => post<SetIndividualTaxInformationRequest, SetIndividualTaxInformationResponse>("/2.0/cash/set-individual-tax-information", request, {...options, rejectOnError: true })