/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AuthenticateInstitutionRequest} from '@cash-web/protos/squareup/franklin/bankbook.pb'
import {AuthenticateInstitutionResponse} from '@cash-web/protos/squareup/franklin/bankbook.pb'


export const authenticateInstitution = (request:AuthenticateInstitutionRequest, options?: CashRequestOptions) => post<AuthenticateInstitutionRequest, AuthenticateInstitutionResponse>("/2.0/cash/authenticate-institution", request, {...options, rejectOnError: true })