/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {LinkBankAccountRequest} from '@cash-web/protos/squareup/franklin/app/bank_account.pb'
import {LinkBankAccountResponse} from '@cash-web/protos/squareup/franklin/app/bank_account.pb'


export const linkBankAccount = (request:LinkBankAccountRequest, options?: CashRequestOptions) => post<LinkBankAccountRequest, LinkBankAccountResponse>("/2.0/cash/link-bank-account", request, {...options, rejectOnError: true })