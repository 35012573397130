/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetInvestmentEntityNewsRequest} from '@cash-web/protos/squareup/cash/marketdata/service.pb'
import {GetInvestmentEntityNewsResponse} from '@cash-web/protos/squareup/cash/marketdata/service.pb'


export const getInvestmentEntityNews = (request:GetInvestmentEntityNewsRequest, options?: CashRequestOptions) => post<GetInvestmentEntityNewsRequest, GetInvestmentEntityNewsResponse>("/2.0/cash/get-investment-entity-news", request, {...options, rejectOnError: true })