/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FindCustomersRequest} from '@cash-web/protos/squareup/franklin/app/customers.pb'
import {FindCustomersResponse} from '@cash-web/protos/squareup/franklin/app/customers.pb'


export const findCustomers = (request:FindCustomersRequest, options?: CashRequestOptions) => post<FindCustomersRequest, FindCustomersResponse>("/2.0/cash/find-customers", request, {...options, rejectOnError: true })