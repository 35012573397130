/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteSetNameRequest} from '@cash-web/protos/squareup/franklin/app/name.pb'
import {ExecuteSetNameResponse} from '@cash-web/protos/squareup/franklin/app/name.pb'


export const executeSetName = (request:ExecuteSetNameRequest, options?: CashRequestOptions) => post<ExecuteSetNameRequest, ExecuteSetNameResponse>("/2.0/cash/execute-set-name", request, {...options, rejectOnError: true })