/* eslint-disable */

export enum OperatingSystemName {
  IOS = "IOS",
  ANDROID = "ANDROID",
  WEB = "WEB",
  WINDOWS = "WINDOWS",
  MAC_OS = "MAC_OS",
  LINUX = "LINUX",
  IOS_WEB = "IOS_WEB",
  ANDROID_WEB = "ANDROID_WEB",
}

export interface OperatingSystem {
  name?: OperatingSystemName;
  /** example: "8.1.3" */
  version?: string;
}
