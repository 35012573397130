/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BatchUpdateReactionRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {BatchUpdateReactionResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const batchUpdateReactionInternal = (request:BatchUpdateReactionRequest, options?: CashRequestOptions) => post<BatchUpdateReactionRequest, BatchUpdateReactionResponse>("/2.0/cash/batch-update-reaction-internal", request, {...options, rejectOnError: true })