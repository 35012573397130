/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RequestPrivacyDeletionRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {RequestPrivacyDeletionResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const requestPrivacyDeletion = (request:RequestPrivacyDeletionRequest, options?: CashRequestOptions) => post<RequestPrivacyDeletionRequest, RequestPrivacyDeletionResponse>("/2.0/cash/request-privacy-deletion", request, {...options, rejectOnError: true })