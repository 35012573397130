/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SubmitSetPaycheckAllocationAmountRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/submit_set_paycheck_allocation_amount_blocker.pb'
import {SubmitSetPaycheckAllocationAmountResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/submit_set_paycheck_allocation_amount_blocker.pb'


export const submitSetPaycheckAllocationAmount = (request:SubmitSetPaycheckAllocationAmountRequest, options?: CashRequestOptions) => post<SubmitSetPaycheckAllocationAmountRequest, SubmitSetPaycheckAllocationAmountResponse>("/cash-app/paychecks/submit-paycheck-allocation-amount", request, {...options, rejectOnError: true })