/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreditMultiStepLoadPollingRequest} from '@cash-web/protos/squareup/franklin/app/credit_multi_step_load.pb'
import {CreditMultiStepLoadPollingResponse} from '@cash-web/protos/squareup/franklin/app/credit_multi_step_load.pb'


export const creditMultiStepPoll = (request:CreditMultiStepLoadPollingRequest, options?: CashRequestOptions) => post<CreditMultiStepLoadPollingRequest, CreditMultiStepLoadPollingResponse>("/2.0/cash/credit-multi-step-poll", request, {...options, rejectOnError: true })