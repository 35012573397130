/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GptSearchRequest} from '@cash-web/protos/squareup/cash/customersearch/api/gpt/gpt.pb'
import {GptSearchResponse} from '@cash-web/protos/squareup/cash/customersearch/api/gpt/gpt.pb'


export const gptSearch = (request:GptSearchRequest, options?: CashRequestOptions) => post<GptSearchRequest, GptSearchResponse>("/2.0/cash/gpt-search", request, {...options, rejectOnError: true })