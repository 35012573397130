/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyMultifactorAuthenticationRequest} from '@cash-web/protos/squareup/franklin/bankbook.pb'
import {VerifyMultifactorAuthenticationResponse} from '@cash-web/protos/squareup/franklin/bankbook.pb'


export const verifyMultifactorAuthentication = (request:VerifyMultifactorAuthenticationRequest, options?: CashRequestOptions) => post<VerifyMultifactorAuthenticationRequest, VerifyMultifactorAuthenticationResponse>("/2.0/cash/verify-multifactor-authentication", request, {...options, rejectOnError: true })