/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiatePasscodeResetRequest} from '@cash-web/protos/squareup/franklin/InitiatePasscodeResetRequestProto.pb'
import {InitiatePasscodeResetResponse} from '@cash-web/protos/squareup/franklin/InitiatePasscodeResetResponseProto.pb'


export const initiatePasscodeReset = (request:InitiatePasscodeResetRequest, options?: CashRequestOptions) => post<InitiatePasscodeResetRequest, InitiatePasscodeResetResponse>("/2.0/cash/initiate-passcode-reset", request, {...options, rejectOnError: true })