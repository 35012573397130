/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetIssuedCardEnabledRequest} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'
import {SetIssuedCardEnabledResponse} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'


export const setIssuedCardEnabled = (request:SetIssuedCardEnabledRequest, options?: CashRequestOptions) => post<SetIssuedCardEnabledRequest, SetIssuedCardEnabledResponse>("/2.0/cash/set-issued-card-enabled", request, {...options, rejectOnError: true })