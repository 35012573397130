/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GenerateLightningInvoiceRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'
import {GenerateLightningInvoiceResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'


export const generateLightningInvoice = (request:GenerateLightningInvoiceRequest, options?: CashRequestOptions) => post<GenerateLightningInvoiceRequest, GenerateLightningInvoiceResponse>("/2.0/cash/generate-lightning-invoice", request, {...options, rejectOnError: true })