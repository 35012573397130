/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResetPasswordRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {ResetPasswordResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const resetPassword = (request:ResetPasswordRequest, options?: CashRequestOptions) => post<ResetPasswordRequest, ResetPasswordResponse>("/2.0/cash/reset-password", request, {...options, rejectOnError: true })