/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteSetCashtagRequest} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'
import {ExecuteSetCashtagResponse} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'


export const executeSetCashtag = (request:ExecuteSetCashtagRequest, options?: CashRequestOptions) => post<ExecuteSetCashtagRequest, ExecuteSetCashtagResponse>("/2.0/cash/execute-set-cashtag", request, {...options, rejectOnError: true })