/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RegisterEmailRequest} from '@cash-web/protos/squareup/franklin/app/email.pb'
import {RegisterEmailResponse} from '@cash-web/protos/squareup/franklin/app/email.pb'


export const registerEmail = (request:RegisterEmailRequest, options?: CashRequestOptions) => post<RegisterEmailRequest, RegisterEmailResponse>("/2.0/cash/register-email", request, {...options, rejectOnError: true })