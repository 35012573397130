import { useContext } from 'react'

import { OptionalMiddlewareContext, OptionalMiddlewareContextProps } from '../OptionalMiddlewareContext'

export const useOptionalFetchMiddleware = (optionalMiddleware?: OptionalMiddlewareContextProps) => {
  const contextOptionalMiddleware = useContext(OptionalMiddlewareContext)
  //merge the optional middleware from the context with the optional middleware passed in
  return {
    response: [...(contextOptionalMiddleware.response || []), ...(optionalMiddleware?.response || [])],
    request: [...(contextOptionalMiddleware.request || []), ...(optionalMiddleware?.request || [])],
  }
}
