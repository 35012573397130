/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {DeregisterWebAuthnCredentialInternalRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {DeregisterWebAuthnCredentialInternalResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const deregisterWebAuthnCredentialInternal = (request:DeregisterWebAuthnCredentialInternalRequest, options?: CashRequestOptions) => post<DeregisterWebAuthnCredentialInternalRequest, DeregisterWebAuthnCredentialInternalResponse>("/2.0/cash/deregister-web-authn-credential-internal", request, {...options, rejectOnError: true })