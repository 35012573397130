/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetInvestmentStatementsRequest} from '@cash-web/protos/squareup/franklin/investing.pb'
import {GetInvestmentStatementsResponse} from '@cash-web/protos/squareup/franklin/investing.pb'


export const getInvestmentStatements = (request:GetInvestmentStatementsRequest, options?: CashRequestOptions) => post<GetInvestmentStatementsRequest, GetInvestmentStatementsResponse>("/2.0/cash/get-investment-statements", request, {...options, rejectOnError: true })