/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetActiveIntegrationsRequest} from '@cash-web/protos/squareup/franklin/app/account_integration.pb'
import {GetActiveIntegrationsResponse} from '@cash-web/protos/squareup/franklin/app/account_integration.pb'


export const getActiveIntegrations = (request:GetActiveIntegrationsRequest, options?: CashRequestOptions) => post<GetActiveIntegrationsRequest, GetActiveIntegrationsResponse>("/2.0/cash/get-active-integrations", request, {...options, rejectOnError: true })