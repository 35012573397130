/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PruneSupportCriteriaNodesRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {PruneSupportCriteriaNodesResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const pruneSupportCriteriaNodes = (request:PruneSupportCriteriaNodesRequest, options?: CashRequestOptions) => post<PruneSupportCriteriaNodesRequest, PruneSupportCriteriaNodesResponse>("/2.0/cash/prune-support-criteria-nodes", request, {...options, rejectOnError: true })