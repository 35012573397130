/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AdvanceSupportFlowRequest} from '@cash-web/protos/squareup/franklin/support.pb'
import {AdvanceSupportFlowResponse} from '@cash-web/protos/squareup/franklin/support.pb'


export const advanceSupportFlow = (request:AdvanceSupportFlowRequest, options?: CashRequestOptions) => post<AdvanceSupportFlowRequest, AdvanceSupportFlowResponse>("/2.0/cash/advance-support-flow", request, {...options, rejectOnError: true })