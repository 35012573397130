/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CheckRewardCodeRequest} from '@cash-web/protos/squareup/franklin/app/reward_code.pb'
import {CheckRewardCodeResponse} from '@cash-web/protos/squareup/franklin/app/reward_code.pb'


export const checkRewardCode = (request:CheckRewardCodeRequest, options?: CashRequestOptions) => post<CheckRewardCodeRequest, CheckRewardCodeResponse>("/2.0/cash/check-reward-code", request, {...options, rejectOnError: true })