/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetContactAliasesRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {GetContactAliasesResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const getContactAliases = (request:GetContactAliasesRequest, options?: CashRequestOptions) => post<GetContactAliasesRequest, GetContactAliasesResponse>("/2.0/cash/get-contact-aliases", request, {...options, rejectOnError: true })