/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetTransactionRequest} from '@cash-web/protos/squareup/franklin/app/transaction.pb'
import {GetTransactionResponse} from '@cash-web/protos/squareup/franklin/app/transaction.pb'


export const getTransaction = (request:GetTransactionRequest, options?: CashRequestOptions) => post<GetTransactionRequest, GetTransactionResponse>("/2.0/cash/get-transaction", request, {...options, rejectOnError: true })