/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SyncEntitiesRequest} from '@cash-web/protos/squareup/franklin/SyncEntitiesRequestProto.pb'
import {SyncEntitiesResponse} from '@cash-web/protos/squareup/franklin/SyncEntitiesResponseProto.pb'


export const syncEntities = (request:SyncEntitiesRequest, options?: CashRequestOptions) => post<SyncEntitiesRequest, SyncEntitiesResponse>("/2.0/cash/sync-entities", request, {...options, rejectOnError: true })