/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBillsRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBills.pb'
import {GetBillsResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBills.pb'


export const getBills = (request:GetBillsRequest, options?: CashRequestOptions) => post<GetBillsRequest, GetBillsResponse>("/cash-app/bills/v1.0/app/get-bills", request, {...options, rejectOnError: true })