/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SelectOptionRequest} from '@cash-web/protos/squareup/franklin/app/select_option.pb'
import {SelectOptionResponse} from '@cash-web/protos/squareup/franklin/app/select_option.pb'


export const selectOption = (request:SelectOptionRequest, options?: CashRequestOptions) => post<SelectOptionRequest, SelectOptionResponse>("/2.0/cash/select-option", request, {...options, rejectOnError: true })