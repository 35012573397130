/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetRatePlanRequest} from '@cash-web/protos/squareup/franklin/app/rate_plan.pb'
import {SetRatePlanResponse} from '@cash-web/protos/squareup/franklin/app/rate_plan.pb'


export const setRatePlan = (request:SetRatePlanRequest, options?: CashRequestOptions) => post<SetRatePlanRequest, SetRatePlanResponse>("/2.0/cash/set-rate-plan", request, {...options, rejectOnError: true })