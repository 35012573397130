/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetContentfulSupportCategoriesRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {GetContentfulSupportCategoriesResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const getContentfulSupportCategories = (request:GetContentfulSupportCategoriesRequest, options?: CashRequestOptions) => post<GetContentfulSupportCategoriesRequest, GetContentfulSupportCategoriesResponse>("/2.0/cash/get-contentful-support-categories", request, {...options, rejectOnError: true })