/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {HandleThreeDomainSecureV2ActionRequest} from '@cash-web/protos/squareup/franklin/rpc/handle_three_domain_secure_v2_action.pb'
import {HandleThreeDomainSecureV2ActionResponse} from '@cash-web/protos/squareup/franklin/rpc/handle_three_domain_secure_v2_action.pb'


export const handleThreeDomainSecureV2Action = (request:HandleThreeDomainSecureV2ActionRequest, options?: CashRequestOptions) => post<HandleThreeDomainSecureV2ActionRequest, HandleThreeDomainSecureV2ActionResponse>("/2.0/cash/handle-three-domain-secure-v2-action", request, {...options, rejectOnError: true })