/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreditNullStateButtonRequest} from '@cash-web/protos/squareup/franklin/app/credit_null_state_button.pb'
import {CreditNullStateButtonResponse} from '@cash-web/protos/squareup/franklin/app/credit_null_state_button.pb'


export const creditNullStateButton = (request:CreditNullStateButtonRequest, options?: CashRequestOptions) => post<CreditNullStateButtonRequest, CreditNullStateButtonResponse>("/2.0/cash/credit-null-state-button", request, {...options, rejectOnError: true })