/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBrandProfileRequest} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'
import {GetBrandProfileResponse} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'


export const getBrandProfile = (request:GetBrandProfileRequest, options?: CashRequestOptions) => post<GetBrandProfileRequest, GetBrandProfileResponse>("/cash-app/local/profile/brand", request, {...options, rejectOnError: true })