export type EnvironmentType = 'development' | 'staging' | 'production' | 'unreleased' | 'local' | 'cypress'

type GetEnvironmentAtRunTimeOptions = {
  defaultEnvironmentForSSR?: EnvironmentType
}

/**
 * Checks the URL to determine the correct environment at run time. Note that this
 * will *not* undergo dead code elimination.
 */
export const getEnvironmentAtRunTime = (options: GetEnvironmentAtRunTimeOptions = {}): EnvironmentType => {
  if (typeof window !== 'undefined') {
    const host = window.location.host

    if (host.includes('cashstaging.app')) {
      return 'staging'
    }

    if (host.includes('cash.app')) {
      return 'production'
    }

    return 'development'
  }

  return getServerEnvironment(options)
}

export const getEnvironmentForObservabilityTools = (): EnvironmentType => {
  if (typeof window !== 'undefined' && window?.bugsnagEnvironment != undefined && window?.bugsnagEnvironment != '') {
    return window.bugsnagEnvironment
  }
  return getEnvironmentAtRunTime()
}

export const getServerEnvironment = (options: GetEnvironmentAtRunTimeOptions = {}): EnvironmentType => {
  const { defaultEnvironmentForSSR = 'development' } = options
  const envEnv = process.env['ENVIRONMENT']

  return envEnv ? (envEnv.toLowerCase() as EnvironmentType) : defaultEnvironmentForSSR
}

export const isServerEnvironment = () => typeof window === 'undefined'
