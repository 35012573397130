/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {OAuthResolveFlowRequest} from '@cash-web/protos/squareup/franklin/app/o_auth.pb'
import {OAuthResolveFlowResponse} from '@cash-web/protos/squareup/franklin/app/o_auth.pb'


export const oAuthResolveFlow = (request:OAuthResolveFlowRequest, options?: CashRequestOptions) => post<OAuthResolveFlowRequest, OAuthResolveFlowResponse>("/2.0/cash/o-auth-resolve-flow", request, {...options, rejectOnError: true })