/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateRemoteCheckDepositRequest} from '@cash-web/protos/squareup/franklin/app/remote_check_deposit.pb'
import {InitiateRemoteCheckDepositResponse} from '@cash-web/protos/squareup/franklin/app/remote_check_deposit.pb'


export const initiateRemoteCheckDeposit = (request:InitiateRemoteCheckDepositRequest, options?: CashRequestOptions) => post<InitiateRemoteCheckDepositRequest, InitiateRemoteCheckDepositResponse>("/2.0/cash/initiate-remote-check-deposit", request, {...options, rejectOnError: true })