/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCryptoLightningWithdrawalLimitsRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'
import {GetCryptoLightningWithdrawalLimitsResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'


export const getCryptoLightningWithdrawalLimits = (request:GetCryptoLightningWithdrawalLimitsRequest, options?: CashRequestOptions) => post<GetCryptoLightningWithdrawalLimitsRequest, GetCryptoLightningWithdrawalLimitsResponse>("/2.0/cash/get-crypto-lightning-withdrawal-limits", request, {...options, rejectOnError: true })