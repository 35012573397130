/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetEquityContractRequest} from '@cash-web/protos/squareup/franklin/app/equity_contract.pb'
import {GetEquityContractResponse} from '@cash-web/protos/squareup/franklin/app/equity_contract.pb'


export const getEquityContract = (request:GetEquityContractRequest, options?: CashRequestOptions) => post<GetEquityContractRequest, GetEquityContractResponse>("/2.0/cash/get-equity-contract", request, {...options, rejectOnError: true })