/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetRequirePasscodeConfirmationRequest} from '@cash-web/protos/squareup/franklin/app/passcode.pb'
import {SetRequirePasscodeConfirmationResponse} from '@cash-web/protos/squareup/franklin/app/passcode.pb'


export const setRequirePasscodeConfirmation = (request:SetRequirePasscodeConfirmationRequest, options?: CashRequestOptions) => post<SetRequirePasscodeConfirmationRequest, SetRequirePasscodeConfirmationResponse>("/2.0/cash/set-require-passcode-confirmation", request, {...options, rejectOnError: true })