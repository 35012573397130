/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ClaimByPaymentTokenRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {ClaimByPaymentTokenResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const claimByPaymentToken = (request:ClaimByPaymentTokenRequest, options?: CashRequestOptions) => post<ClaimByPaymentTokenRequest, ClaimByPaymentTokenResponse>("/2.0/cash/claim-by-payment-token", request, {...options, rejectOnError: true })