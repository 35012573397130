/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SwitchAccountRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {SwitchAccountResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const switchAccount = (request:SwitchAccountRequest, options?: CashRequestOptions) => post<SwitchAccountRequest, SwitchAccountResponse>("/2.0/cash/switch-account", request, {...options, rejectOnError: true })