import { Middleware } from '../pipe'
import { CashRequest, CashRequestContext } from './types'

export const optionalRequestHeadersRequest: Middleware<CashRequest, CashRequestContext> = async (request, context) => {
  if (context?.options?.optionalRequestHeaders) {
    request.headers = {
      ...request.headers,
      ...context.options.optionalRequestHeaders,
    }
  }
  return request
}
