/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {Send1099BToTaxmanRequest} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/tax.pb'
import {Send1099BToTaxmanResponse} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/tax.pb'


export const send1099BToTaxman = (request:Send1099BToTaxmanRequest, options?: CashRequestOptions) => post<Send1099BToTaxmanRequest, Send1099BToTaxmanResponse>("/2.0/cash/send1099-b-to-taxman", request, {...options, rejectOnError: true })