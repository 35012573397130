/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillCustomerMerchantRelationshipRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillCustomerMerchantRelationship.pb'
import {BackfillCustomerMerchantRelationshipResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillCustomerMerchantRelationship.pb'


export const backfillCustomerMerchantRelationships = (request:BackfillCustomerMerchantRelationshipRequest, options?: CashRequestOptions) => post<BackfillCustomerMerchantRelationshipRequest, BackfillCustomerMerchantRelationshipResponse>("/2.0/cash/backfill-customer-merchant-relationships", request, {...options, rejectOnError: true })