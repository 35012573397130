/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateOrUpdateActivityItemRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {CreateOrUpdateActivityItemResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const createOrUpdateActivityItem = (request:CreateOrUpdateActivityItemRequest, options?: CashRequestOptions) => post<CreateOrUpdateActivityItemRequest, CreateOrUpdateActivityItemResponse>("/2.0/cash/create-or-update-activity-item", request, {...options, rejectOnError: true })