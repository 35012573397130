/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ConfirmIosDonationRequest} from '@cash-web/protos/squareup/franklin/app/ios_donation.pb'
import {ConfirmIosDonationResponse} from '@cash-web/protos/squareup/franklin/app/ios_donation.pb'


export const confirmIosDonation = (request:ConfirmIosDonationRequest, options?: CashRequestOptions) => post<ConfirmIosDonationRequest, ConfirmIosDonationResponse>("/2.0/cash/confirm-ios-donation", request, {...options, rejectOnError: true })