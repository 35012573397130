/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {TransferFundsRequest} from '@cash-web/protos/squareup/franklin/TransferFundsRequestProto.pb'
import {TransferFundsResponse} from '@cash-web/protos/squareup/franklin/TransferFundsResponseProto.pb'


export const transferFunds = (request:TransferFundsRequest, options?: CashRequestOptions) => post<TransferFundsRequest, TransferFundsResponse>("/2.0/cash/transfer-funds", request, {...options, rejectOnError: true })