/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ApproveAccountIntegrationRequest} from '@cash-web/protos/squareup/franklin/app/account_integration.pb'
import {ApproveAccountIntegrationResponse} from '@cash-web/protos/squareup/franklin/app/account_integration.pb'


export const approveAccountIntegration = (request:ApproveAccountIntegrationRequest, options?: CashRequestOptions) => post<ApproveAccountIntegrationRequest, ApproveAccountIntegrationResponse>("/2.0/cash/approve-account-integration", request, {...options, rejectOnError: true })