/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyQrCodeRequest} from '@cash-web/protos/squareup/franklin/app/qr_code.pb'
import {VerifyQrCodeResponse} from '@cash-web/protos/squareup/franklin/app/qr_code.pb'


export const verifyQrCode = (request:VerifyQrCodeRequest, options?: CashRequestOptions) => post<VerifyQrCodeRequest, VerifyQrCodeResponse>("/2.0/cash/verify-qr-code", request, {...options, rejectOnError: true })