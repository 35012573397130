/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetDepositPreferenceRequest} from '@cash-web/protos/squareup/franklin/SetDepositPreferenceRequestProto.pb'
import {SetDepositPreferenceResponse} from '@cash-web/protos/squareup/franklin/SetDepositPreferenceResponseProto.pb'


export const setDepositPreference = (request:SetDepositPreferenceRequest, options?: CashRequestOptions) => post<SetDepositPreferenceRequest, SetDepositPreferenceResponse>("/2.0/cash/set-deposit-preference", request, {...options, rejectOnError: true })