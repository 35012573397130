/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PlaceRecurringOrderRequest} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/recurring_order.pb'
import {PlaceRecurringOrderResponse} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/recurring_order.pb'


export const placeRecurringOrder = (request:PlaceRecurringOrderRequest, options?: CashRequestOptions) => post<PlaceRecurringOrderRequest, PlaceRecurringOrderResponse>("/2.0/cash/place-recurring-order", request, {...options, rejectOnError: true })