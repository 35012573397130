/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteContractRequest} from '@cash-web/protos/squareup/franklin/app/exchange_contract.pb'
import {ExecuteContractResponse} from '@cash-web/protos/squareup/franklin/app/exchange_contract.pb'


export const executeContract = (request:ExecuteContractRequest, options?: CashRequestOptions) => post<ExecuteContractRequest, ExecuteContractResponse>("/2.0/cash/execute-contract", request, {...options, rejectOnError: true })