/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UnregisterAliasRequest} from '@cash-web/protos/squareup/franklin/app/alias.pb'
import {UnregisterAliasResponse} from '@cash-web/protos/squareup/franklin/app/alias.pb'


export const unregisterAlias = (request:UnregisterAliasRequest, options?: CashRequestOptions) => post<UnregisterAliasRequest, UnregisterAliasResponse>("/2.0/cash/unregister-alias", request, {...options, rejectOnError: true })