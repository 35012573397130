import { css } from '@emotion/react'

import { MotionCurveStandard } from '@cash-design-system/tokens'

const motion = {
  standardTransition: css`
    transition-property: transform, background, color, box-shadow;
    transition-duration: 80ms;
    transition-timing-function: ${MotionCurveStandard};
  `,
  standardActiveState: css`
    transform: scale(0.95);
  `,
  standardSubtleActiveState: css`
    transform: scale(0.98);
  `,
  motionCurvedStandard: css`
    transition-timing-function: ${MotionCurveStandard};
  `,
} as const

export type ThemeMotion = typeof motion

export default motion
