/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishWebAuthnRegistrationRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {FinishWebAuthnRegistrationResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const finishWebAuthnRegistration = (request:FinishWebAuthnRegistrationRequest, options?: CashRequestOptions) => post<FinishWebAuthnRegistrationRequest, FinishWebAuthnRegistrationResponse>("/cash-app/security/1.0/finish-webauthn-registration", request, {...options, rejectOnError: true })