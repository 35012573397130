/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RevokeAccountIntegrationRequest} from '@cash-web/protos/squareup/franklin/app/account_integration.pb'
import {RevokeAccountIntegrationResponse} from '@cash-web/protos/squareup/franklin/app/account_integration.pb'


export const revokeAccountIntegration = (request:RevokeAccountIntegrationRequest, options?: CashRequestOptions) => post<RevokeAccountIntegrationRequest, RevokeAccountIntegrationResponse>("/2.0/cash/revoke-account-integration", request, {...options, rejectOnError: true })