/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishPinwheelLinkRequest} from '@cash-web/protos/squareup/franklin/app/pinwheel_link.pb'
import {FinishPinwheelLinkResponse} from '@cash-web/protos/squareup/franklin/app/pinwheel_link.pb'


export const finishPinwheelLink = (request:FinishPinwheelLinkRequest, options?: CashRequestOptions) => post<FinishPinwheelLinkRequest, FinishPinwheelLinkResponse>("/2.0/cash/finish-pinwheel-link", request, {...options, rejectOnError: true })