/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBillRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBill.pb'
import {GetBillResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/GetBill.pb'


export const getBill = (request:GetBillRequest, options?: CashRequestOptions) => post<GetBillRequest, GetBillResponse>("/cash-app/bills/v1.0/app/get-bill", request, {...options, rejectOnError: true })