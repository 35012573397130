/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetIndividualTaxInformationStatusRequest} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'
import {GetIndividualTaxInformationStatusResponse} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'


export const getIndividualTaxInformationStatus = (request:GetIndividualTaxInformationStatusRequest, options?: CashRequestOptions) => post<GetIndividualTaxInformationStatusRequest, GetIndividualTaxInformationStatusResponse>("/2.0/cash/get-individual-tax-information-status", request, {...options, rejectOnError: true })