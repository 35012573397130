import * as React from 'react'

import { Theme, useTheme } from '@emotion/react'

import * as tokens from '@cash-design-system/tokens'

import { ArcadeDollarLogo } from '../../icons/logos/ArcadeDollarLogo'
import { ArcadeDollarLogoWithText } from '../../icons/logos/ArcadeDollarLogoWithText'
import { ArcadeTaxesLogoWithText } from '../../icons/logos/ArcadeTaxesLogoWithText'
import { ColorVariants } from './Logo'

export type ArcadeLogoSvgProps = {
  altText?: string
  logoColor: string
  textColor?: string
  symbolColor: string
} & React.SVGAttributes<SVGElement>

export type ArcadeVariants = ColorVariants | 'taxes'

export type LogoProps = {
  variant?: ArcadeVariants
  withText?: boolean
} & Omit<ArcadeLogoSvgProps, 'logoColor' | 'symbolColor' | 'textColor'>

type Color = string | { light: string; dark: string }
type LogoVariant = { logoColor: Color; textColor: Color; symbolColor: Color }

export const LOGO_VARIANTS: Record<ArcadeVariants, LogoVariant> = {
  white: {
    logoColor: tokens.ColorPaletteWhite,
    textColor: tokens.ColorPaletteWhite,
    symbolColor: '',
  },
  black: {
    logoColor: tokens.ColorPaletteGray20,
    textColor: tokens.ColorPaletteGray20,
    symbolColor: '',
  },
  investingPurple: {
    logoColor: tokens.ColorPaletteInvestingPurpleLight,
    textColor: tokens.ColorPaletteInvestingPurpleLight,
    symbolColor: '',
  },
  green: {
    logoColor: tokens.ColorTintGreenLight,
    textColor: tokens.ColorTintGreenLight,
    symbolColor: '',
  },
  greenLogoWhiteText: {
    logoColor: tokens.ColorTintGreenLight,
    textColor: tokens.ColorPaletteWhite,
    symbolColor: '',
  },
  greenLogoBlackText: {
    logoColor: tokens.ColorTintGreenLight,
    textColor: tokens.ColorPaletteGray20,
    symbolColor: '',
  },
  pinkCashAppCard: {
    logoColor: '#F50086', // not a design system color
    textColor: tokens.ColorPaletteWhite,
    symbolColor: '',
  },
  taxes: {
    logoColor: { light: tokens.ColorPaletteGray20, dark: tokens.ColorPaletteWhite },
    textColor: { light: tokens.ColorPaletteGray20, dark: tokens.ColorPaletteWhite },
    symbolColor: '',
  },
  default: {
    logoColor: tokens.ColorTintGreenLight,
    textColor: tokens.ColorPaletteBlack,
    symbolColor: tokens.ColorPaletteWhite,
  },
}

const getThemedColorValue = (theme: Theme, color: Color): string => {
  if (typeof color === 'string') {
    return color
  }
  return theme.darkMode ? color.dark : color.light
}

const ArcadeLogo = ({ altText, variant = 'default', withText, ...props }: LogoProps) => {
  const theme = useTheme()

  const LogoComponentWithText = variant === 'taxes' ? ArcadeTaxesLogoWithText : ArcadeDollarLogoWithText
  const LogoComponent = withText ? LogoComponentWithText : ArcadeDollarLogo

  const { logoColor, textColor, symbolColor } = LOGO_VARIANTS[variant] || LOGO_VARIANTS.default

  return (
    <LogoComponent
      altText={altText}
      logoColor={getThemedColorValue(theme, logoColor)}
      symbolColor={getThemedColorValue(theme, symbolColor)}
      textColor={getThemedColorValue(theme, textColor)}
      {...props}
    />
  )
}

export default ArcadeLogo
