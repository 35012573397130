/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetW9SnapshotPiiDataRequest} from '@cash-web/protos/squareup/franklin/investing.pb'
import {GetW9SnapshotPiiDataResponse} from '@cash-web/protos/squareup/franklin/investing.pb'


export const getW9SnapshotPiiData = (request:GetW9SnapshotPiiDataRequest, options?: CashRequestOptions) => post<GetW9SnapshotPiiDataRequest, GetW9SnapshotPiiDataResponse>("/2.0/cash/get-w9-snapshot-pii-data", request, {...options, rejectOnError: true })