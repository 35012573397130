/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CaptureThreatmetrixSessionRequest} from '@cash-web/protos/squareup/cash/behavioraldatapoc/api/v1/events.pb'
import {CaptureThreatmetrixSessionResponse} from '@cash-web/protos/squareup/cash/behavioraldatapoc/api/v1/service.pb'


export const captureThreatmetrixSession = (request:CaptureThreatmetrixSessionRequest, options?: CashRequestOptions) => post<CaptureThreatmetrixSessionRequest, CaptureThreatmetrixSessionResponse>("/cash-app/bdcash/tm/cs", request, {...options, rejectOnError: true })