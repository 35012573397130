/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RegisterDeviceRequest} from '@cash-web/protos/squareup/franklin/app/register_device.pb'
import {RegisterDeviceResponse} from '@cash-web/protos/squareup/franklin/app/register_device.pb'


export const registerDevice = (request:RegisterDeviceRequest, options?: CashRequestOptions) => post<RegisterDeviceRequest, RegisterDeviceResponse>("/2.0/cash/register-device", request, {...options, rejectOnError: true })