import type { PropsWithChildren } from 'react'
import { useInView } from 'react-cool-inview'

type ImpressionTrackingProps = {
  eventCall: () => void
  threshold?: number
}
export function useImpressionTracking({ eventCall, threshold = 0.8 }: ImpressionTrackingProps) {
  const { observe } = useInView({
    onEnter: ({ unobserve }) => {
      eventCall()
      unobserve()
    },
    threshold,
  })
  return { ref: observe }
}

export function ImpressionTrackingWrapper({
  children,
  eventCall,
  threshold = 0.8,
}: PropsWithChildren<ImpressionTrackingProps>) {
  const { ref } = useImpressionTracking({ eventCall, threshold })
  return <span ref={ref}>{children}</span>
}
