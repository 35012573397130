/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCryptoTaxStatementsRequest} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/tax.pb'
import {GetCryptoTaxStatementsResponse} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/tax.pb'


export const getCryptoTaxStatements = (request:GetCryptoTaxStatementsRequest, options?: CashRequestOptions) => post<GetCryptoTaxStatementsRequest, GetCryptoTaxStatementsResponse>("/2.0/cash/get-crypto-tax-statements", request, {...options, rejectOnError: true })