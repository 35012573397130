/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetExchangeDataRequest} from '@cash-web/protos/squareup/franklin/app/exchange_data.pb'
import {GetExchangeDataResponse} from '@cash-web/protos/squareup/franklin/app/exchange_data.pb'


export const getExchangeData = (request:GetExchangeDataRequest, options?: CashRequestOptions) => post<GetExchangeDataRequest, GetExchangeDataResponse>("/2.0/cash/get-exchange-data", request, {...options, rejectOnError: true })