/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetProfileInfoRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {GetProfileInfoResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const getProfileInfo = (request:GetProfileInfoRequest, options?: CashRequestOptions) => post<GetProfileInfoRequest, GetProfileInfoResponse>("/2.0/cash/web/profile", request, {...options, rejectOnError: true })