/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RefreshPaychecksAppletRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'
import {RefreshPaychecksAppletResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'


export const refreshPaychecksApplet = (request:RefreshPaychecksAppletRequest, options?: CashRequestOptions) => post<RefreshPaychecksAppletRequest, RefreshPaychecksAppletResponse>("/2.0/cash/refresh-paychecks-applet", request, {...options, rejectOnError: true })