/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetLoginDetailsRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {GetLoginDetailsResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const getLoginDetails = (request:GetLoginDetailsRequest, options?: CashRequestOptions) => post<GetLoginDetailsRequest, GetLoginDetailsResponse>("/2.0/cash/get-login-details", request, {...options, rejectOnError: true })