/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {EndFlowRequest} from '@cash-web/protos/squareup/franklin/app/flow.pb'
import {EndFlowResponse} from '@cash-web/protos/squareup/franklin/app/flow.pb'


export const endFlow = (request:EndFlowRequest, options?: CashRequestOptions) => post<EndFlowRequest, EndFlowResponse>("/2.0/cash/end-flow", request, {...options, rejectOnError: true })