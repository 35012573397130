import { Middleware } from '../pipe'
import { CashResponse, CashResponseContext, HttpError } from './types'
export const tryJsonParseError = (str: string) => {
  if (!str.includes('{')) {
    return str
  }
  try {
    return JSON.parse(str)
  } catch (e) {
    // do nothing, if we fail it's because we expected json, but got a message with a curly brace in it.
  }
  return str
}
export const parseErrorMessage = (response: CashResponseContext['response']) => {
  return `${response.status} - ${response.statusText}, endpoint:${response.url}`
}

/**
 * Rejects the promise if the response is not ok and the options.rejectOnError is true
 */
export const rejectOnErrorResponse: Middleware<CashResponse, CashResponseContext> = async (response, context) => {
  if (context != null && context?.response != null && !context?.response?.ok && context?.options?.rejectOnError) {
    const errorMessage = parseErrorMessage(context.response)
    throw new HttpError(errorMessage, context?.response?.status, response)
  }
  return response
}
