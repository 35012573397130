/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetUnredactedIssuedCardRequest} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'
import {GetUnredactedIssuedCardResponse} from '@cash-web/protos/squareup/franklin/app/issued_card.pb'


export const getUnredactedIssuedCard = (request:GetUnredactedIssuedCardRequest, options?: CashRequestOptions) => post<GetUnredactedIssuedCardRequest, GetUnredactedIssuedCardResponse>("/2.0/cash/get-unredacted-issued-card", request, {...options, rejectOnError: true })