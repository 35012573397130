/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishInstantPayAutoAdvanceRequest} from '@cash-web/protos/squareup/franklin/app/instant_pay.pb'
import {FinishInstantPayAutoAdvanceResponse} from '@cash-web/protos/squareup/franklin/app/instant_pay.pb'


export const finishInstantPayAutoAdvance = (request:FinishInstantPayAutoAdvanceRequest, options?: CashRequestOptions) => post<FinishInstantPayAutoAdvanceRequest, FinishInstantPayAutoAdvanceResponse>("/2.0/cash/finish-instant-pay-auto-advance", request, {...options, rejectOnError: true })