import { CurrencyCode } from '@cash-web/protos/squareup/common/currency_codes.pb'

const numberInThousands = /\B(?=(\d{3})+(?!\d))/g

export type SupportedCurrencySymbols =
  | CurrencyCode.AUD
  | CurrencyCode.BTC
  | CurrencyCode.CAD
  | CurrencyCode.EUR
  | CurrencyCode.GBP
  | CurrencyCode.JPY
  | CurrencyCode.USD

export const CURRENCY_SYMBOLS: {
  [key in SupportedCurrencySymbols]: string
} = {
  AUD: '$',
  BTC: '฿',
  CAD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  USD: '$',
} as const

// Units of precision (converting from lowest currency units to one full unit)
const CONVERSION = {
  BTC: 100000000,
  DEFAULT: 100,
} as const

// Units of precision for display.
const PRECISION = {
  BTC: 8,
  DEFAULT: 2,
} as const

export type AmountMoney = {
  currency_code: keyof typeof CURRENCY_SYMBOLS
  amount: number
}

export const formatThousands = (num: string | number) => {
  return num.toString().replace(numberInThousands, ',')
}

// Formats money without currency symbol, truncates trailing `.00` unless verbose is true
// Always returns a string
export const formattedNumber = function (amountMoney: AmountMoney, verbose: boolean) {
  const { currency_code } = amountMoney

  const rawAmount = amountMoney.amount
  if (!rawAmount && rawAmount !== 0) {
    return
  }

  const conversion = currency_code === 'BTC' ? CONVERSION.BTC : CONVERSION.DEFAULT
  const precision = currency_code === 'BTC' ? PRECISION.BTC : PRECISION.DEFAULT

  const shiftedAmount = rawAmount / conversion
  let formattedAmount = (
    !verbose && shiftedAmount % 1 === 0 ? shiftedAmount : shiftedAmount.toFixed(precision)
  ).toString()

  // Add commas for thousands on all currencies except BTC
  if (rawAmount >= 100000 && amountMoney.currency_code !== 'BTC') {
    formattedAmount = formatThousands(formattedAmount)
  }
  return formattedAmount
}

// Formats money with currency symbol, truncates trailing `.00` unless verbose is true
export const formatCurrencyAmount = function (amountMoney: AmountMoney, verbose = true) {
  return CURRENCY_SYMBOLS[amountMoney.currency_code] + formattedNumber(amountMoney, verbose)
}
