/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SupplyCheckRecipientContactsRequest} from '@cash-web/protos/squareup/franklin/app/contacts.pb'
import {SupplyCheckRecipientContactsResponse} from '@cash-web/protos/squareup/franklin/app/contacts.pb'


export const supplyCheckRecipientContacts = (request:SupplyCheckRecipientContactsRequest, options?: CashRequestOptions) => post<SupplyCheckRecipientContactsRequest, SupplyCheckRecipientContactsResponse>("/2.0/cash/supply-check-recipient-contacts", request, {...options, rejectOnError: true })