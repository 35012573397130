/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateExportInternalRequest} from '@cash-web/protos/squareup/cash/activityexport/api/v1/service.pb'
import {CreateExportResponse} from '@cash-web/protos/squareup/cash/activityexport/api/v1/service.pb'


export const createExportInternal = (request:CreateExportInternalRequest, options?: CashRequestOptions) => post<CreateExportInternalRequest, CreateExportResponse>("/2.0/cash/create-export-internal", request, {...options, rejectOnError: true })