/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetScheduledTransactionPreferenceRequest} from '@cash-web/protos/squareup/franklin/app/scheduled_transaction.pb'
import {SetScheduledTransactionPreferenceResponse} from '@cash-web/protos/squareup/franklin/app/scheduled_transaction.pb'


export const setScheduledTransactionPreference = (request:SetScheduledTransactionPreferenceRequest, options?: CashRequestOptions) => post<SetScheduledTransactionPreferenceRequest, SetScheduledTransactionPreferenceResponse>("/2.0/cash/set-scheduled-transaction-preference", request, {...options, rejectOnError: true })