import { createContext } from 'react'

import { GetProfileResponse } from '@cash-web/protos/squareup/franklin/app/profile.pb'
import { Profile } from '@cash-web/protos/squareup/franklin/ProfileProto.pb'
import { HttpError } from '@cash-web/shared-util-fetch'

export type AuthContextInterface = {
  profile?: Profile
  isLoading: boolean
  isFetching: boolean
  error?: HttpError<GetProfileResponse> | null
}

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined)
