import { fetchRequestMiddleware, fetchResponseMiddleware } from './middleware'
import { CashApiRequest, CashRequestOptions } from './middleware/fetch/types'

export type SerializeQueryParameters = { [index: string]: string | string[] }

export const serializeQueryParams = (parameters: SerializeQueryParameters): string =>
  Object.entries(parameters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        const encodedParams = value.map((param: string) => encodeURIComponent(param))
        return `${encodeURIComponent(key)}=${encodedParams.join(`&${encodeURIComponent(key)}=`)}`
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

export const createQueryString = (parameters: SerializeQueryParameters): string => {
  const queryParams = parameters && Object.keys(parameters).length ? serializeQueryParams(parameters) : null
  return queryParams ? `?${queryParams}` : ''
}

// Older versions of Edge / Chrome don't support the referrerPolicy option in `fetch`
// This is a basic feature detection function that will include it when supported
function supportsSameOriginReferrerPolicy() {
  try {
    new Request('_', {
      referrerPolicy: 'same-origin',
    })
  } catch (e) {
    return false
  }
  return true
}
export const MAX_RETRIES = 3
export const RETRY_DELAY = 100

export const retryFetch: typeof fetch = async (input, init) => {
  for (let retries = 0; retries < MAX_RETRIES; retries++) {
    try {
      return await fetch(input, init)
    } catch (e) {
      // let the retries continue
    }
    // Delay before retrying
    await new Promise(resolve => setTimeout(resolve, RETRY_DELAY))
  }
  // if we fail to fetch the query, we return null
  return Promise.resolve(new Response())
}

const fetchReq = async <T>(
  path: string,
  request: CashApiRequest,
  { optionalMiddleware, ...options }: CashRequestOptions = {},
  data?: T
) => {
  const params = { ...request }
  if (supportsSameOriginReferrerPolicy()) {
    params.referrerPolicy = 'same-origin'
  }
  const response = await retryFetch(
    path,
    await fetchRequestMiddleware.execute(
      {
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'error',
        signal: options.signal,
        keepalive: options.keepalive,
        ...params,
        data,
      },
      { options, path },
      optionalMiddleware?.request
    )
  )

  return fetchResponseMiddleware.execute(
    response,
    {
      request: { ...request, path, data, ...params, ...optionalMiddleware?.request },
      response: {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        ok: response.ok,
      },
      options,
    },
    optionalMiddleware?.response
  )
}

export const post = <I, O>(path: string, data?: I, options?: CashRequestOptions): Promise<O> => {
  return fetchReq(
    path,
    {
      method: 'POST',
    },
    options,
    data
  )
}

export const get = <O>(path: string, params?: SerializeQueryParameters, options?: CashRequestOptions): Promise<O> =>
  fetchReq(
    `${path}${createQueryString(params || {})}`,
    {
      method: 'GET',
    },
    options
  )
