import React, { useContext, useMemo } from 'react'

import {
  OptionalMiddlewareContext,
  OptionalMiddlewareRequest,
  OptionalMiddlewareResponse,
} from './OptionalMiddlewareContext'

export type OptionalMiddlewareProviderProps = {
  request?: OptionalMiddlewareRequest
  response?: OptionalMiddlewareResponse
}
export const OptionalMiddlewareProvider = ({
  response,
  request,
  children,
}: React.PropsWithChildren<OptionalMiddlewareProviderProps>) => {
  const { response: existingResponse, request: existingRequest } = useContext(OptionalMiddlewareContext)
  const value = useMemo(() => {
    return {
      response: response != null ? [...(existingResponse || []), response] : existingResponse,
      request: request != null ? [...(existingRequest || []), request] : existingRequest,
    }
  }, [response, request, existingResponse, existingRequest])
  return <OptionalMiddlewareContext.Provider value={value}>{children}</OptionalMiddlewareContext.Provider>
}
