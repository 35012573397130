/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SendTaxFormEmailRequest} from '@cash-web/protos/squareup/franklin/app/tax_form_email.pb'
import {SendTaxFormEmailResponse} from '@cash-web/protos/squareup/franklin/app/tax_form_email.pb'


export const sendTaxFormEmail = (request:SendTaxFormEmailRequest, options?: CashRequestOptions) => post<SendTaxFormEmailRequest, SendTaxFormEmailResponse>("/2.0/cash/send-tax-form-email", request, {...options, rejectOnError: true })