/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetEntitiesBatchRequest} from '@cash-web/protos/squareup/cash/customersearch/api/v2/GetEntities.pb'
import {GetEntitiesBatchResponse} from '@cash-web/protos/squareup/cash/customersearch/api/v2/GetEntities.pb'


export const getEntitiesBatch = (request:GetEntitiesBatchRequest, options?: CashRequestOptions) => post<GetEntitiesBatchRequest, GetEntitiesBatchResponse>("/2.0/cash/get-entities-batch", request, {...options, rejectOnError: true })