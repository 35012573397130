/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CheckVersionRequest} from '@cash-web/protos/squareup/franklin/app/version.pb'
import {CheckVersionResponse} from '@cash-web/protos/squareup/franklin/app/version.pb'


export const checkVersion = (request:CheckVersionRequest, options?: CashRequestOptions) => post<CheckVersionRequest, CheckVersionResponse>("/2.0/cash/check-version", request, {...options, rejectOnError: true })