/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPlatformClientRequest} from '@cash-web/protos/squareup/franklin/app/platform_client.pb'
import {GetPlatformClientResponse} from '@cash-web/protos/squareup/franklin/app/platform_client.pb'


export const getPlatformClient = (request:GetPlatformClientRequest, options?: CashRequestOptions) => post<GetPlatformClientRequest, GetPlatformClientResponse>("/2.0/cash/get-platform-client", request, {...options, rejectOnError: true })