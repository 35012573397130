import { CashFaviconAttributes } from '../../types'

export enum FaviconAsset {
  AppleTouchIcon = 'appleTouchIcon',
  Manifest = 'manifest',
  Ico = 'ico',
  Svg = 'svg',
}

const BASE_CDN_URL = 'https://cash-f.squarecdn.com'

const ASSET_DEFAULTS = {
  [FaviconAsset.AppleTouchIcon]: {
    rel: 'apple-touch-icon',
    href: `${BASE_CDN_URL}/static/apple-touch-icon.png`,
    integrity: 'sha384-obbtLXUmL5CCcijDtMgdqvtSyKu3FTcGYIdVn6uif5nIxTaS7u3IMUG3Wl1PjBox',
  },
  [FaviconAsset.Manifest]: {
    rel: 'manifest',
    href: `${BASE_CDN_URL}/static/manifest.webmanifest`,
    integrity: 'sha384-CdK6MPtohWsnTcqD6CGLRmq1Wc5Al3/3LP9FNcnsODQKI3SMTfCiCamhNianUQCU',
  },
  [FaviconAsset.Ico]: {
    rel: 'icon',
    href: `${BASE_CDN_URL}/static/favicon.ico`,
    integrity: 'sha384-4ChiDA321IPrHSobMPeW1n8RX2GU6pY6iWH7h79DqAFaYyhrlbYTvRWEAJqMZ3GS',
    sizes: '16x16,32x32',
    type: 'image/x-icon',
  },
  [FaviconAsset.Svg]: {
    rel: 'icon',
    href: `${BASE_CDN_URL}/static/favicon.svg`,
    integrity: 'sha384-KFrrYlHN2FMoPYRtljOsgHAreGaHwX+UtbQgfY3LbnCzenjycuPRU7zBKWf7yaQZ',
    sizes: 'any',
    type: 'image/svg+xml',
  },
}

export const getAssetDefaults = (asset: FaviconAsset) => ASSET_DEFAULTS[asset] as CashFaviconAttributes

export const setDynamicFavicon = ({
  attributes,
  query,
}: {
  attributes: Partial<CashFaviconAttributes>
  query?: string
}) => {
  const linkEl = query ? document.querySelector(query) : document.createElement('link')

  if (!linkEl) return

  Object.entries(attributes).forEach(([key, value]) => {
    linkEl.setAttribute(key, value)
  })

  const head = document.querySelector('head')
  !query && head?.appendChild(linkEl)
}
