/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AssignVerifiedCustomerToVoiceCaseRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {AssignVerifiedCustomerToVoiceCaseResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const assignVerifiedCustomerToVoiceCase = (request:AssignVerifiedCustomerToVoiceCaseRequest, options?: CashRequestOptions) => post<AssignVerifiedCustomerToVoiceCaseRequest, AssignVerifiedCustomerToVoiceCaseResponse>("/2.0/cash/assign-verified-customer-to-voice-case", request, {...options, rejectOnError: true })