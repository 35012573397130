/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {DirectDepositTransferSelectionRequest} from '@cash-web/protos/squareup/instant-pay/instantpay_api.pb'
import {DirectDepositTransferSelectionResponse} from '@cash-web/protos/squareup/instant-pay/instantpay_api.pb'


export const directDepositTransferSelection = (request:DirectDepositTransferSelectionRequest, options?: CashRequestOptions) => post<DirectDepositTransferSelectionRequest, DirectDepositTransferSelectionResponse>("/2.0/cash/direct-deposit-transfer-selection", request, {...options, rejectOnError: true })