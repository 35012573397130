/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ActivityPageRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {PaychecksAugmentedActivityPageResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'


export const getActivityPage = (request:ActivityPageRequest, options?: CashRequestOptions) => post<ActivityPageRequest, PaychecksAugmentedActivityPageResponse>("/2.0/cash/get-activity-page", request, {...options, rejectOnError: true })