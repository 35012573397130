/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetLoyaltyNotificationPreferenceRequest} from '@cash-web/protos/squareup/franklin/app/loyalty_notification.pb'
import {SetLoyaltyNotificationPreferenceResponse} from '@cash-web/protos/squareup/franklin/app/loyalty_notification.pb'


export const setLoyaltyNotificationPreference = (request:SetLoyaltyNotificationPreferenceRequest, options?: CashRequestOptions) => post<SetLoyaltyNotificationPreferenceRequest, SetLoyaltyNotificationPreferenceResponse>("/2.0/cash/set-loyalty-notification-preference", request, {...options, rejectOnError: true })