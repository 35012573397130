/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetProfileRequest} from '@cash-web/protos/squareup/franklin/app/profile.pb'
import {GetProfileResponse} from '@cash-web/protos/squareup/franklin/app/profile.pb'


export const getProfile = (request:GetProfileRequest, options?: CashRequestOptions) => post<GetProfileRequest, GetProfileResponse>("/2.0/cash/get-profile", request, {...options, rejectOnError: true })