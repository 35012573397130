/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CashInRequest} from '@cash-web/protos/squareup/moneta/api/CashInRequestProto.pb'
import {CashInResponse} from '@cash-web/protos/squareup/moneta/api/CashInResponseProto.pb'


export const cashIn = (request:CashInRequest, options?: CashRequestOptions) => post<CashInRequest, CashInResponse>("/cash-app/cash-in/v1.0/add-cash", request, {...options, rejectOnError: true })