/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RegisterProspectTokenRequest} from '@cash-web/protos/squareup/cash/janus/api/RegisterProspectTokenProto.pb'
import {RegisterProspectTokenResponse} from '@cash-web/protos/squareup/cash/janus/api/RegisterProspectTokenProto.pb'


export const registerProspectToken = (request:RegisterProspectTokenRequest, options?: CashRequestOptions) => post<RegisterProspectTokenRequest, RegisterProspectTokenResponse>("/2.0/cash/register-prospect-token", request, {...options, rejectOnError: true })