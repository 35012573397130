import { CashRequestMiddleware } from './types'

export const flowTokenRequest: CashRequestMiddleware = (request, { options } = {}) => {
  let flowToken
  if (options?.flowToken != null) {
    flowToken = options.flowToken
  }
  if (flowToken != null) {
    const headers = request.headers as Record<string, string>
    headers['Cash-Flow-Token'] = flowToken
  }
  return request
}
