/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AssignUnknownCustomerToVoiceCaseRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {AssignUnknownCustomerToVoiceCaseResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const assignUnknownCustomerToVoiceCase = (request:AssignUnknownCustomerToVoiceCaseRequest, options?: CashRequestOptions) => post<AssignUnknownCustomerToVoiceCaseRequest, AssignUnknownCustomerToVoiceCaseResponse>("/2.0/cash/assign-unknown-customer-to-voice-case", request, {...options, rejectOnError: true })