/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetInvitationConfigRequest} from '@cash-web/protos/squareup/franklin/app/invitation_config.pb'
import {GetInvitationConfigResponse} from '@cash-web/protos/squareup/franklin/app/invitation_config.pb'


export const getInvitationConfig = (request:GetInvitationConfigRequest, options?: CashRequestOptions) => post<GetInvitationConfigRequest, GetInvitationConfigResponse>("/2.0/cash/get-invitation-config", request, {...options, rejectOnError: true })