/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetFlowRequest} from '@cash-web/protos/squareup/franklin/app/flow.pb'
import {GetFlowResponse} from '@cash-web/protos/squareup/franklin/app/flow.pb'

// This method does not expect a flow token option. If you need a flow token, use the one from get-flow response in subsequent calls.
export const getFlow = (request:GetFlowRequest, options?: Omit<CashRequestOptions, 'flowToken'>) => post<GetFlowRequest, GetFlowResponse>("/2.0/cash/get-flow", request, {...options, rejectOnError: true })