/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateCustomersRequest} from '@cash-web/protos/squareup/cash/supportal/service.pb'
import {UpdateCustomersResponse} from '@cash-web/protos/squareup/cash/supportal/service.pb'


export const updateCustomers = (request:UpdateCustomersRequest, options?: CashRequestOptions) => post<UpdateCustomersRequest, UpdateCustomersResponse>("/2.0/cash/update-customers", request, {...options, rejectOnError: true })