// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const fromCharCode = String.fromCharCode.bind(null)

export const btdetect = {
  // 'X-Request-UUID'
  a: fromCharCode(88, 45, 82, 101, 113, 117, 101, 115, 116, 45, 85, 85, 73, 68),

  // X-Request-Signature
  b: fromCharCode(88, 45, 82, 101, 113, 117, 101, 115, 116, 45, 83, 105, 103, 110, 97, 116, 117, 114, 101),

  // X-BT-ID
  c: fromCharCode(88, 45, 66, 84, 45, 73, 68),

  // Resources:
  // https://stackoverflow.com/questions/20862728/reliably-detecting-phantomjs-based-spam-bots
  // https://github.com/ikarienator/phantomjs_hide_and_seek
  // http://engineering.shapesecurity.com/2015/01/detecting-phantomjs-based-visitors.html

  // A set of tests to detect automated front end browsers.
  // Produces a two number result:
  //  0.0 - a normal browser
  //  2.1 - a bot found by test //1

  // Number 1 - confidence level 0 (no problem), 1 (maybe bot), 2 (bot)
  // Number 2 - the test number that failed

  getID: (function () {
    let bot = 0
    let test = 0

    if (typeof window !== 'undefined') {
      const win = window
      const nav = win.navigator
      const ua = nav.userAgent

      // Definitive Quick Tests
      if (win._phantom || win.callPhantom || win.__phantomas || !!win.callPhantom || /phantomjs/i.test(ua)) {
        test = 1 // PhantomJS
      } else if (win.Buffer) {
        test = 2 // Node
      } else if (win.emit) {
        test = 3 // CouchJS
      } else if (win.spawn) {
        test = 4 // Rhino
      } else if (win.webdriver) {
        test = 5 // Selenium
      } else if (win.domAutomation || win.domAutomationController) {
        test = 6 // chromium based automation driver
      }

      if (test) {
        bot = 2 // Indicates a bot is found
      }

      if (!bot) {
        // Definitive Test for Phantom, hard to work around
        // http://engineering.shapesecurity.com/2015/01/detecting-phantomjs-based-visitors.html
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          null[0]()
        } catch (e: unknown) {
          if (e && e instanceof Error && (e.stack?.indexOf('phantomjs') ?? -2) > -1) {
            bot = 2 // PhantomJS
            test = 10
          }
        }
      }
    }
    const id = bot + '.' + test

    return function () {
      return id
    }
  })(),
}
