/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateWebBuyerIntentActionRequest} from '@cash-web/protos/squareup/cash/local/v1/cash_app_local_web_service.pb'
import {CreateWebBuyerIntentActionResponse} from '@cash-web/protos/squareup/cash/local/v1/cash_app_local_web_service.pb'


export const createWebBuyerIntent = (request:CreateWebBuyerIntentActionRequest, options?: CashRequestOptions) => post<CreateWebBuyerIntentActionRequest, CreateWebBuyerIntentActionResponse>("/cash-app/local/web-buyer-intent", request, {...options, rejectOnError: true })