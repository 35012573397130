/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetExperimentsRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {GetExperimentsResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const getExperiments = (request:GetExperimentsRequest, options?: CashRequestOptions) => post<GetExperimentsRequest, GetExperimentsResponse>("/2.0/cash/get-experiments", request, {...options, rejectOnError: true })