/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCheckAddressRequest} from '@cash-web/protos/squareup/franklin/app/check_address.pb'
import {GetCheckAddressResponse} from '@cash-web/protos/squareup/franklin/app/check_address.pb'


export const getCheckAddress = (request:GetCheckAddressRequest, options?: CashRequestOptions) => post<GetCheckAddressRequest, GetCheckAddressResponse>("/2.0/cash/get-check-address", request, {...options, rejectOnError: true })