/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RegisterAppMessageActionRequest} from '@cash-web/protos/squareup/franklin/app/app_message.pb'
import {RegisterAppMessageActionResponse} from '@cash-web/protos/squareup/franklin/app/app_message.pb'


export const registerAppMessageAction = (request:RegisterAppMessageActionRequest, options?: CashRequestOptions) => post<RegisterAppMessageActionRequest, RegisterAppMessageActionResponse>("/2.0/cash/register-app-message-action", request, {...options, rejectOnError: true })