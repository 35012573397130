/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BatchDeleteActivityItemRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {BatchDeleteActivityItemResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const batchDeleteActivityItem = (request:BatchDeleteActivityItemRequest, options?: CashRequestOptions) => post<BatchDeleteActivityItemRequest, BatchDeleteActivityItemResponse>("/2.0/cash/batch-delete-activity-item", request, {...options, rejectOnError: true })