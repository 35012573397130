/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ToggleBillAutoPaymentRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/ToggleBillAutoPayment.pb'
import {ToggleBillAutoPaymentResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/ToggleBillAutoPayment.pb'


export const toggleBillAutoPayment = (request:ToggleBillAutoPaymentRequest, options?: CashRequestOptions) => post<ToggleBillAutoPaymentRequest, ToggleBillAutoPaymentResponse>("/2.0/cash/toggle-bill-auto-payment", request, {...options, rejectOnError: true })