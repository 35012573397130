/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExecuteEquityContractRequest} from '@cash-web/protos/squareup/franklin/app/equity_contract.pb'
import {ExecuteEquityContractResponse} from '@cash-web/protos/squareup/franklin/app/equity_contract.pb'


export const executeEquityContract = (request:ExecuteEquityContractRequest, options?: CashRequestOptions) => post<ExecuteEquityContractRequest, ExecuteEquityContractResponse>("/2.0/cash/execute-equity-contract", request, {...options, rejectOnError: true })