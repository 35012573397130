import { viewport } from '@arcade/web'

const maxWidths = {
  content: '31rem',
  field: '27rem',
  standalonePage: '55rem',
  page: viewport.max,
} as const

export type ThemeMaxWidths = typeof maxWidths

export default maxWidths
