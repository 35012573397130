/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetOrCreateSavingsBalanceRequest} from '@cash-web/protos/squareup/cash/app/get_or_create_savings_balance.pb'
import {GetOrCreateSavingsBalanceResponse} from '@cash-web/protos/squareup/cash/app/get_or_create_savings_balance.pb'


export const getOrCreateSavingsBalance = (request:GetOrCreateSavingsBalanceRequest, options?: CashRequestOptions) => post<GetOrCreateSavingsBalanceRequest, GetOrCreateSavingsBalanceResponse>("/cash-app/savings/get-or-create-savings-balance", request, {...options, rejectOnError: true })