/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ConfirmIndividualTaxInformationRequest} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'
import {ConfirmIndividualTaxInformationResponse} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'


export const confirmIndividualTaxInformation = (request:ConfirmIndividualTaxInformationRequest, options?: CashRequestOptions) => post<ConfirmIndividualTaxInformationRequest, ConfirmIndividualTaxInformationResponse>("/2.0/cash/confirm-individual-tax-information", request, {...options, rejectOnError: true })