/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SelectInstrumentRequest} from '@cash-web/protos/squareup/cash/moneta/api/v1_0/plasma/app.pb'
import {SelectInstrumentResponse} from '@cash-web/protos/squareup/cash/moneta/api/v1_0/plasma/app.pb'


export const selectInstrument = (request:SelectInstrumentRequest, options?: CashRequestOptions) => post<SelectInstrumentRequest, SelectInstrumentResponse>("/cash-app/instrument/v1.0/select-instrument", request, {...options, rejectOnError: true })