/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SyncCapsAccountHolderRequest} from '@cash-web/protos/squareup/cash/janus/api/admin/AccountHolderAdminProtos.pb'
import {SyncCapsAccountHolderResponse} from '@cash-web/protos/squareup/cash/janus/api/admin/AccountHolderAdminProtos.pb'


export const syncCapsAccountHolder = (request:SyncCapsAccountHolderRequest, options?: CashRequestOptions) => post<SyncCapsAccountHolderRequest, SyncCapsAccountHolderResponse>("/2.0/cash/sync-caps-account-holder", request, {...options, rejectOnError: true })