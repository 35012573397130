/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SavingsMoveCashRequest} from '@cash-web/protos/squareup/cash/balancemover/api/v1/savings_move_cash.pb'
import {SavingsMoveCashResponse} from '@cash-web/protos/squareup/cash/balancemover/api/v1/savings_move_cash.pb'


export const savingsMoveCash = (request:SavingsMoveCashRequest, options?: CashRequestOptions) => post<SavingsMoveCashRequest, SavingsMoveCashResponse>("/cash-app/internal-transfers/v1.0/move-cash", request, {...options, rejectOnError: true })