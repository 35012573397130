/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RemoveActiveGoalRequest} from '@cash-web/protos/squareup/cash/app/remove_active_goal.pb'
import {RemoveActiveGoalResponse} from '@cash-web/protos/squareup/cash/app/remove_active_goal.pb'


export const removeActiveGoal = (request:RemoveActiveGoalRequest, options?: CashRequestOptions) => post<RemoveActiveGoalRequest, RemoveActiveGoalResponse>("/2.0/cash/remove-active-goal", request, {...options, rejectOnError: true })