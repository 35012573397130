/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdatePaymentScheduleRequest} from '@cash-web/protos/squareup/franklin/app/payment_schedule.pb'
import {UpdatePaymentScheduleResponse} from '@cash-web/protos/squareup/franklin/app/payment_schedule.pb'


export const updatePaymentSchedule = (request:UpdatePaymentScheduleRequest, options?: CashRequestOptions) => post<UpdatePaymentScheduleRequest, UpdatePaymentScheduleResponse>("/2.0/cash/update-payment-schedule", request, {...options, rejectOnError: true })