/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetShortlinkRequest} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'
import {GetShortlinkResponse} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'


export const getShortlink = (request:GetShortlinkRequest, options?: CashRequestOptions) => post<GetShortlinkRequest, GetShortlinkResponse>("/2.0/cash/get-shortlink", request, {...options, rejectOnError: true })