import { useCallback, useEffect, useState } from 'react'

import {
  MutationOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import logger from '@cash-web/shared/util-logger'
import { CashRequestOptions, HttpError, useOptionalFetchMiddleware } from '@cash-web/shared-util-fetch'

export type MutationRequestOptions = {
  options?: CashRequestOptions
}

export type QueryProps<TRequest = unknown, TResponse = unknown, TData = TResponse> = {
  variables?: TRequest
  queryKey?: string[]
  queryOptions?: Omit<UseQueryOptions<TResponse, HttpError<TResponse>, TData>, 'queryFn' | 'queryKey'>
  options?: CashRequestOptions
}

export type UseQuery<TRequest = unknown, TResponse = unknown> = {
  <TData = TResponse>(request: QueryProps<TRequest, TResponse, TData>): UseQueryResult<TData, HttpError<TResponse>>
  asQueryConfig: <TData = TResponse>(
    request: QueryProps<TRequest, TResponse, TData>
  ) => UseQueryOptions<TResponse, HttpError<TResponse>, TData>
  rootQueryKey: string
  getQueryKey: <TData = TResponse>(request: QueryProps<TRequest, TResponse, TData>) => unknown[]
}
/**
 * @param query - The query to update
 * @param updater - The function to update the query cache
 * @param queryKey - This is an optional parameter that can be used to add specificity to the query key (beyond the rootQueryKey) e.g. [rootQueryKey, ...queryKey]
 */
export type PerformOptimisticUpdate<TMutationRequest> = <TQueryRequest, TQueryResponse>(
  query: UseQuery<TQueryRequest, TQueryResponse>,
  updater: ({ queryData, variables }: { queryData: TQueryResponse; variables: TMutationRequest }) => TQueryResponse,
  queryKey?: string[]
) => void

export type PerformOptimisticUpdateOption<TMutationRequest> = {
  performOptimisticUpdate?: (setQueryData: PerformOptimisticUpdate<TMutationRequest>) => void
}
export type MutationProps<TRequest = unknown, TResponse = unknown, TContext = unknown> = {
  mutationOptions?: UseMutationOptions<TResponse, HttpError<TResponse>, TRequest, TContext>
  options?: CashRequestOptions
} & PerformOptimisticUpdateOption<TRequest>

export type OptimisticallyUpdatingStatus = { isOptimisticallyUpdating?: boolean }

export type UseMutation<TRequest = unknown, TResponse = unknown, TContext = unknown> = {
  (request?: MutationProps<TRequest, TResponse, TContext> & MutationRequestOptions): UseMutationResult<
    TResponse,
    HttpError<TResponse>,
    TRequest,
    TContext
  > &
    OptimisticallyUpdatingStatus
  rootQueryKey: string
}

const convertToPascalCase = (str: string) => `${str[0].toUpperCase()}${str.slice(1)}`
const createQueryKey = (name: string, queryKey?: string[], variables?: unknown) => {
  const contextualKey: unknown[] = [name]
  if (queryKey != null) {
    contextualKey.push()
  }
  if (variables != null && Object.keys(variables).length > 0) {
    contextualKey.push(variables)
  }
  return contextualKey
}
export const createReactQueryHook = <TRequest, TResponse>(
  apiName: string,
  api: (request: TRequest, options?: CashRequestOptions) => Promise<TResponse>
) => {
  const name = `useQuery${convertToPascalCase(apiName)}`
  const empty = {} as TRequest
  const useQueryFn: UseQuery<TRequest, TResponse> = ({
    variables: queryVariables,
    queryKey,
    queryOptions,
    options,
  } = {}) => {
    const optionalMiddleware = useOptionalFetchMiddleware(options?.optionalMiddleware)

    return useQuery(
      createQueryKey(name, queryKey, queryVariables),
      () => api(queryVariables ?? empty, { ...options, optionalMiddleware }),
      queryOptions
  )
}
  useQueryFn.asQueryConfig = ({ variables, queryKey, queryOptions, options } = {}) => {
    return {
      queryKey: createQueryKey(name, queryKey, variables),
      queryFn: () => api(variables ?? empty, { ...options }),
      ...queryOptions,
    }
  }
  useQueryFn.rootQueryKey = name

  useQueryFn.getQueryKey = ({ variables, queryKey } = {}) => {
    return createQueryKey(name, queryKey, variables)
  }

  return useQueryFn
}
const createOptimisticUpdateKey = (key: string[]) => `_optimisticUpdate_${key.join('_')}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useIsOptimisticallyUpdating = (...mutationQueryOrKey: (UseMutation<any, any, any> | string)[]) => {
  const queryClient = useQueryClient()

  const keys = mutationQueryOrKey.map(queryOrKey =>
    typeof queryOrKey === 'string' ? queryOrKey : queryOrKey.rootQueryKey
  )

  const getIsOptimisticallyUpdatingStateContext = useCallback(() => {
    return keys
      .map(key => queryClient.getMutationCache().find({ mutationKey: key }))
      .some(mutationCache => {
        const context = mutationCache?.state?.context as OptimisticallyUpdatingStatus
        return context?.isOptimisticallyUpdating ?? false
      })
  }, [keys, queryClient])

  const [isOptimisticallyUpdating, setIsOptimisticallyUpdating] = useState(getIsOptimisticallyUpdatingStateContext())
  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(() => {
      const result = getIsOptimisticallyUpdatingStateContext()
      if (result !== isOptimisticallyUpdating) {
        setIsOptimisticallyUpdating(result)
      }
    })
    return () => unsubscribe()
  }, [
    queryClient,
    keys,
    isOptimisticallyUpdating,
    setIsOptimisticallyUpdating,
    getIsOptimisticallyUpdatingStateContext,
  ])

  return isOptimisticallyUpdating
}

/**
 *
 * @param performOptimisticUpdate
 * @param mutationOptions
 * @returns UseMutationOptions
 * @description
 * `performOptimisticUpdate` is a custom option added only to mutations. Sometimes when performing a mutation we want to optimistically update the query cache in order to provide a better experience for our customers. This new option available at the top level rather than being located in `options` or `mutationOptions` as `options` is exclusively for API options and `mutationOptions` is used when passing options provided by react-query itself.
 * @example
 * Using this new option you'll be passed a function called `setQueryData` this expects you to pass the mutation / query that you want to optimistically update and a subsequent `updater` function to indicate how to update this data.
 *
 * See below for an example: (this example can also be found in `CashtagPageLockSettings.tsx`)
 *
 * ```JavaScript
 * const { mutate: setCashtagUrlEnabled } = useMutationSetCashtagUrlEnabled({
 *     performOptimisticUpdate: setQueryData => {
 *       setQueryData(useQueryGetProfile, ({queryData, variables}) => {
 *         return { ...queryData, profile: { ...queryData.profile, cashtag_url_enabled: !cashtagUrlEnabled } }
 *       })
 *     },
 *   })
 * ```
 */
const useAddOptimisticUpdateOption = <TRequest, TResponse, TContext = unknown>(
  name: string,
  performOptimisticUpdate?: (setQueryData: PerformOptimisticUpdate<TRequest>) => void,
  mutationOptions?: UseMutationOptions<TResponse, HttpError<TResponse>, TRequest, TContext>
):
  | (UseMutationOptions<TResponse, HttpError<TResponse>, TRequest, TContext> & OptimisticallyUpdatingStatus)
  | undefined => {
  const queryClient = useQueryClient()

  const isOptimisticallyUpdating = useIsOptimisticallyUpdating(name)
  const setIsOptimisticallyUpdating = (isOptimisticallyUpdating: boolean) => {
    const cache = queryClient.getMutationCache().find({
      mutationKey: name,
    })
    const context = (cache?.state?.context ?? {}) as TContext
    cache?.setState({
      ...cache.state,
      context: { ...context, isOptimisticallyUpdating },
    })
  }
  if (!performOptimisticUpdate) {
    return mutationOptions
  }
  return {
    ...mutationOptions,
    onMutate: async variables => {
      let context: { [index: string]: unknown } = {}

      performOptimisticUpdate(async (query, updater, queryKey = []) => {
        const key = [query.rootQueryKey, ...queryKey]
        setIsOptimisticallyUpdating(true)
        // first let's update the context with the current data
        const queryData = queryClient.getQueryData(key)
        // we need to store the current value in the context so we can rollback to it later
        context = { ...context, [createOptimisticUpdateKey(key)]: queryData }

        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(key)

        // we want to set the state optimistically, so we don't have to wait for the mutation to finish.
        if (queryData) {
          queryClient.setQueryData(
            key,
            updater({
              queryData: queryData as ReturnType<typeof updater>,
              variables: variables as TRequest,
            }) as unknown
          )
        } else {
          logger.error(new Error('Trying to perform optimistic update on a query that is not cached -> key: ' + key))
        }
      })
      // if passed onMutate we still want to run this.
      return {
        ...context,
        ...(await mutationOptions?.onMutate?.(variables)),
        isOptimisticallyUpdating: true,
      } as TContext
    },
    onError: (error, variables, context) => {
      // if passed onError we still want to run this.
      mutationOptions?.onError?.(error, variables, context)

      // rollback to the previous value
      performOptimisticUpdate((query, updater, queryKey = []) => {
        const key = [query.rootQueryKey, ...queryKey]
        const customContext = context as { [index: string]: unknown }
        const oldValue = customContext[createOptimisticUpdateKey(key)]
        if (oldValue) {
          queryClient.setQueryData(key, oldValue)
        }
      })
    },
    onSettled: (data, error, variables, context) => {
      // if passed onSettled we still want to run this.
      mutationOptions?.onSettled?.(data, error, variables, context)
      const invalidationPromises: Promise<unknown>[] = []

      performOptimisticUpdate((query, updater, queryKey = []) => {
        const key = [query.rootQueryKey, ...queryKey]
        invalidationPromises.push(queryClient.invalidateQueries(key))
      })
      // we are no longer optimistically updating
      // when all the invalidation promises have resolved
      Promise.all(invalidationPromises).then(() => {
        setIsOptimisticallyUpdating(false)
      })
    },
    isOptimisticallyUpdating,
  }
}
export const createReactQueryMutationHook = <TRequest, TResponse, TContext = unknown>(
  apiName: string,
  api: (request: TRequest, options?: CashRequestOptions) => Promise<TResponse>
) => {
  const name = `useMutation${convertToPascalCase(apiName)}`
  const useMutationFn: UseMutation<TRequest & MutationRequestOptions, TResponse, TContext> = ({
    mutationOptions,
    options: globalRequestOptions,
    performOptimisticUpdate,
  } = {}) => {
    // adding performOptimisticUpdate to the mutationOptions

    const updatedMutationOptions = useAddOptimisticUpdateOption(name, performOptimisticUpdate, mutationOptions) ?? {}
    // we need to add the mutation key so we can identify the mutation in the query cache
    updatedMutationOptions.mutationKey = updatedMutationOptions?.mutationKey ?? name

    const optionalMiddleware = useOptionalFetchMiddleware(globalRequestOptions?.optionalMiddleware)
    return {
      ...useMutation(({ options: requestOptions, ...request }: TRequest & MutationRequestOptions) => api(request as TRequest, { ...globalRequestOptions, ...requestOptions, optionalMiddleware }), updatedMutationOptions),
      isOptimisticallyUpdating: updatedMutationOptions?.isOptimisticallyUpdating ?? undefined,
    }
  }

  useMutationFn.rootQueryKey = name
  return useMutationFn
}
