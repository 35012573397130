/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPortfoliosPerformanceRequest} from '@cash-web/protos/squareup/cash/portfolios/service.pb'
import {GetPortfoliosPerformanceResponse} from '@cash-web/protos/squareup/cash/portfolios/service.pb'


export const getPortfoliosPerformance = (request:GetPortfoliosPerformanceRequest, options?: CashRequestOptions) => post<GetPortfoliosPerformanceRequest, GetPortfoliosPerformanceResponse>("/2.0/cash/investing/get-portfolios-performance", request, {...options, rejectOnError: true })