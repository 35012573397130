/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ActivityGetInternalRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {ActivityGetInternalResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const activityGetInternal = (request:ActivityGetInternalRequest, options?: CashRequestOptions) => post<ActivityGetInternalRequest, ActivityGetInternalResponse>("/2.0/cash/activity-get-internal", request, {...options, rejectOnError: true })