/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {StartSupportFlowRequest} from '@cash-web/protos/squareup/franklin/support.pb'
import {StartSupportFlowResponse} from '@cash-web/protos/squareup/franklin/support.pb'


export const startSupportFlow = (request:StartSupportFlowRequest, options?: CashRequestOptions) => post<StartSupportFlowRequest, StartSupportFlowResponse>("/2.0/cash/start-support-flow", request, {...options, rejectOnError: true })