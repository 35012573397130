/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CancelInvestmentOrderRequest} from '@cash-web/protos/squareup/franklin/investing.pb'
import {CancelInvestmentOrderResponse} from '@cash-web/protos/squareup/franklin/investing.pb'


export const cancelInvestmentOrder = (request:CancelInvestmentOrderRequest, options?: CashRequestOptions) => post<CancelInvestmentOrderRequest, CancelInvestmentOrderResponse>("/2.0/cash/cancel-investment-order", request, {...options, rejectOnError: true })