/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {HandleThreeDSChallengeOutcomeRequest} from '@cash-web/protos/squareup/franklin/app/three_ds_challenge.pb'
import {HandleThreeDSChallengeOutcomeResponse} from '@cash-web/protos/squareup/franklin/app/three_ds_challenge.pb'


export const handleThreeDSChallengeOutcome = (request:HandleThreeDSChallengeOutcomeRequest, options?: CashRequestOptions) => post<HandleThreeDSChallengeOutcomeRequest, HandleThreeDSChallengeOutcomeResponse>("/2.0/cash/handle-three-d-s-challenge-outcome", request, {...options, rejectOnError: true })