import * as React from 'react'

import styled from '@emotion/styled'

import { useArcadeMigrationFeatureFlag } from '../ArcadeComponentMigration/ArcadeComponentMigrationProvider'
import ArcadeButton, {
  ButtonProminence as ArcadeButtonProminence,
  ButtonSize as ArcadeButtonSize,
} from './ArcadeButton'
import MooncakeButton, { ButtonProps, ButtonSize as MooncakeButtonSize } from './MooncakeButton'

export const Buttonless = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  text-align: left;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.empty};
  user-select: none;
  min-height: 4rem;
  line-height: 2rem;
  vertical-align: middle;

  ${({ theme }) => theme.selectors.keyboard} &:focus {
    border-radius: 0.5rem;
    outline: 0.5rem solid ${({ theme }) => theme.addOpacity(theme.colors.primary, 0.2)};
  }
`

const translatableMooncakeVariants = ['primary', 'secondary', 'tertiary', 'tertiary-outline', 'outline']
type TranslatableMooncakeVariants = (typeof translatableMooncakeVariants)[number]
const mooncakeToArcadeProminenceMap: Record<TranslatableMooncakeVariants, ArcadeButtonProminence> = {
  primary: 'prominent',
  secondary: 'standard',
  tertiary: 'subtle',
  'tertiary-outline': 'subtle',
  outline: 'subtle',
}

const mooncakeToArcadeSizeMap: Record<MooncakeButtonSize, ArcadeButtonSize> = {
  large: 'cta',
  medium: 'default',
  small: 'compact',
  icon: 'compact',
}

const isTranslatableMooncakeVariant = (variant: string): variant is TranslatableMooncakeVariants =>
  translatableMooncakeVariants.includes(variant)

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const shouldUseArcadeButton = useArcadeMigrationFeatureFlag('button')

  if (shouldUseArcadeButton) {
    const { variant, size, ...rest } = props

    const isDestructive = variant === 'error'

    let arcadeVariant: ArcadeButtonProminence = 'prominent'
    if (variant && isTranslatableMooncakeVariant(variant)) {
      arcadeVariant = mooncakeToArcadeProminenceMap[variant]
    }

    const arcadeSize = size && mooncakeToArcadeSizeMap[size]

    return (
      <ArcadeButton ref={ref} prominence={arcadeVariant} size={arcadeSize} isDestructive={isDestructive} {...rest} />
    )
  }

  return <MooncakeButton ref={ref} {...props} />
})

export default Button
