import { size } from '@arcade/web'

const spacing = {
  /** "0rem" (0px) */
  empty: size[0],

  /** "1rem" (16px with 16px base font size) */
  default: size[4],

  /** "0.25rem" (4px with 16px base font size) */
  xxs: size[1],

  /** "0.5rem" (8px with 16px base font size) */
  xs: size[2],

  /** "0.75rem" (12px with 16px base font size) */
  s: size[3],

  /** "1rem" (16px with 16px base font size) */
  m: size[4],

  /** "1.25rem" (20px with 16px base font size) */
  smallGap: '1.25rem',

  /** "1.5rem" (24px with 16px base font size) */
  l: size[6],

  /** "2rem" (32px with 16px base font size) */
  xl: size[8],

  /** "2.5rem" (40px with 16px base font size) */
  defaultContent: '2.5rem',

  /** "3rem" (48px with 16px base font size) */
  xxl: size[12],

  /** "4rem" (64px with 16px base font size) */
  headerSpacing: '4rem',

  /** "6rem" (96px with 16px base font size) */
  xxxl: size[24],

  /** "7.5rem" (120px with 16px base font size) */
  xxxxl: size[30],

  /** "1rem 1rem 1rem 1rem" (16px 16px 16px 16px) */
  insetDefault: `${size[4]} ${size[4]} ${size[4]} ${size[4]}`,

  /** "0.25rem 0.25rem 0.25rem 0.25rem" (4px 4px 4px 4px) */
  insetXxs: `${size[1]} ${size[1]} ${size[1]} ${size[1]}`,

  /** "0.5rem 0.5rem 0.5rem 0.5rem" (8px 8px 8px 8px) */
  insetXs: `${size[2]} ${size[2]} ${size[2]} ${size[2]}`,

  /** "0.75rem 0.75rem 0.75rem 0.75rem" (12px 12px 12px 12px) */
  insetS: `${size[3]} ${size[3]} ${size[3]} ${size[3]}`,

  /** "1rem 1rem 1rem 1rem" (16px 16px 16px 16px) */
  insetM: `${size[4]} ${size[4]} ${size[4]} ${size[4]}`,

  /** "1.5rem 1.5rem 1.5rem 1.5rem" (24px 24px 24px 24px) */
  insetL: `${size[6]} ${size[6]} ${size[6]} ${size[6]}`,

  /** "2rem 2rem 2rem 2rem" (32px 32px 32px 32px) */
  insetXl: `${size[8]} ${size[8]} ${size[8]} ${size[8]}`,
} as const

export type ThemeSpacing = typeof spacing

export default spacing
