/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetConversationListRequest} from '@cash-web/protos/squareup/franklin/app/conversation.pb'
import {GetConversationListResponse} from '@cash-web/protos/squareup/franklin/app/conversation.pb'


export const getConversationList = (request:GetConversationListRequest, options?: CashRequestOptions) => post<GetConversationListRequest, GetConversationListResponse>("/2.0/cash/get-conversation-list", request, {...options, rejectOnError: true })