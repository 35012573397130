/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyDeviceRequest} from '@cash-web/protos/squareup/franklin/app/device.pb'
import {VerifyDeviceResponse} from '@cash-web/protos/squareup/franklin/app/device.pb'


export const verifyDevice = (request:VerifyDeviceRequest, options?: CashRequestOptions) => post<VerifyDeviceRequest, VerifyDeviceResponse>("/2.0/cash/verify-device", request, {...options, rejectOnError: true })