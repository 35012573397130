/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SubmitFilesetRequest} from '@cash-web/protos/squareup/franklin/app/fileset.pb'
import {SubmitFilesetResponse} from '@cash-web/protos/squareup/franklin/app/fileset.pb'


export const submitFileset = (request:SubmitFilesetRequest, options?: CashRequestOptions) => post<SubmitFilesetRequest, SubmitFilesetResponse>("/2.0/cash/submit-fileset", request, {...options, rejectOnError: true })