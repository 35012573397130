import Head from 'next/head'

import { FaviconTagsProps } from '../../../types'
import { FaviconAsset, getAssetDefaults } from '../../lib/util'

export const FaviconTags: React.FC<FaviconTagsProps> = ({ additionalAssets = [], include = {} }) => {
  const { appleTouchIcon = {}, ico = {}, manifest = {}, svg = {} } = include

  return (
    <Head>
      {appleTouchIcon && (
        <link
          key="favicon-apple-touch-icon"
          {...{ ...getAssetDefaults(FaviconAsset.AppleTouchIcon), ...appleTouchIcon }}
        />
      )}
      {manifest && <link key="favicon-manifest" {...{ ...getAssetDefaults(FaviconAsset.Manifest), ...manifest }} />}
      {ico && <link key="favicon-ico" {...{ ...getAssetDefaults(FaviconAsset.Ico), ...ico }} />}
      {svg && <link key="favicon-svg" {...{ ...getAssetDefaults(FaviconAsset.Svg), ...svg }} />}

      {additionalAssets.map(asset => (
        <link {...asset} key={asset.key} />
      ))}
    </Head>
  )
}
