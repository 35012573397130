/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {OrderConfirmationRequest} from '@cash-web/protos/squareup/franklin/app/order_confirmation.pb'
import {OrderConfirmationResponse} from '@cash-web/protos/squareup/franklin/app/order_confirmation.pb'


export const confirmOrder = (request:OrderConfirmationRequest, options?: CashRequestOptions) => post<OrderConfirmationRequest, OrderConfirmationResponse>("/2.0/cash/confirm-order", request, {...options, rejectOnError: true })