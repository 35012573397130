/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RevokeWebLoginTokensRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {RevokeWebLoginTokensResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const revokeWebLoginTokens = (request:RevokeWebLoginTokensRequest, options?: CashRequestOptions) => post<RevokeWebLoginTokensRequest, RevokeWebLoginTokensResponse>("/2.0/cash/revoke-web-login-tokens", request, {...options, rejectOnError: true })