/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetEffectiveCustomerLimitsRequest} from '@cash-web/protos/squareup/franklin/app/customer_limits.pb'
import {GetEffectiveCustomerLimitsResponse} from '@cash-web/protos/squareup/franklin/app/customer_limits.pb'


export const getEffectiveCustomerLimits = (request:GetEffectiveCustomerLimitsRequest, options?: CashRequestOptions) => post<GetEffectiveCustomerLimitsRequest, GetEffectiveCustomerLimitsResponse>("/2.0/cash/get-effective-customer-limits", request, {...options, rejectOnError: true })