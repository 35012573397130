/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSavingsOverviewRequest} from '@cash-web/protos/squareup/cash/app/get_savings_overview.pb'
import {GetSavingsOverviewResponse} from '@cash-web/protos/squareup/cash/app/get_savings_overview.pb'


export const getSavingsOverview = (request:GetSavingsOverviewRequest, options?: CashRequestOptions) => post<GetSavingsOverviewRequest, GetSavingsOverviewResponse>("/cash-app/savings/get-savings-overview", request, {...options, rejectOnError: true })