import { CashRequestMiddleware } from './types'

export const clientScenarioRequest: CashRequestMiddleware = (request, context) => {
  const clientScenario = context?.options?.clientScenario
  const headers = request.headers as Record<string, string>
  if (clientScenario != null) {
    headers['Cash-Client-Scenario'] = clientScenario
  }
  return request
}
