/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCustomerInvestmentSettingsRequest} from '@cash-web/protos/squareup/franklin/investing.pb'
import {GetCustomerInvestmentSettingsResponse} from '@cash-web/protos/squareup/franklin/investing.pb'


export const getCustomerInvestmentSettings = (request:GetCustomerInvestmentSettingsRequest, options?: CashRequestOptions) => post<GetCustomerInvestmentSettingsRequest, GetCustomerInvestmentSettingsResponse>("/2.0/cash/get-customer-investment-settings", request, {...options, rejectOnError: true })