/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {StartAccountRecoveryRequest} from '@cash-web/protos/squareup/cash/janus/api/StartAccountRecoveryProto.pb'
import {StartAccountRecoveryResponse} from '@cash-web/protos/squareup/cash/janus/api/StartAccountRecoveryProto.pb'


export const startAccountRecovery = (request:StartAccountRecoveryRequest, options?: CashRequestOptions) => post<StartAccountRecoveryRequest, StartAccountRecoveryResponse>("/2.0/cash/start-account-recovery", request, {...options, rejectOnError: true })