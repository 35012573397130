/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ConfirmPaymentRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {ConfirmPaymentResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const confirmPayment = (request:ConfirmPaymentRequest, options?: CashRequestOptions) => post<ConfirmPaymentRequest, ConfirmPaymentResponse>("/2.0/cash/confirm-payment", request, {...options, rejectOnError: true })