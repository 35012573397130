/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RefreshSavingsHomeRequest} from '@cash-web/protos/squareup/cash/app/refresh_savings_home.pb'
import {RefreshSavingsHomeResponse} from '@cash-web/protos/squareup/cash/app/refresh_savings_home.pb'


export const refreshSavingsHome = (request:RefreshSavingsHomeRequest, options?: CashRequestOptions) => post<RefreshSavingsHomeRequest, RefreshSavingsHomeResponse>("/2.0/cash/refresh-savings-home", request, {...options, rejectOnError: true })