/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetEligibilityRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'
import {GetEligibilityResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'


export const getEligibility = (request:GetEligibilityRequest, options?: CashRequestOptions) => post<GetEligibilityRequest, GetEligibilityResponse>("/2.0/cash/get-eligibility", request, {...options, rejectOnError: true })