/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ActivitySearchPageInternalRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/search.pb'
import {ActivitySearchResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/search.pb'


export const activitySearchPageInternal = (request:ActivitySearchPageInternalRequest, options?: CashRequestOptions) => post<ActivitySearchPageInternalRequest, ActivitySearchResponse>("/2.0/cash/activity-search-page-internal", request, {...options, rejectOnError: true })