/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetSignatureRequest} from '@cash-web/protos/squareup/franklin/app/signature.pb'
import {SetSignatureResponse} from '@cash-web/protos/squareup/franklin/app/signature.pb'


export const setSignature = (request:SetSignatureRequest, options?: CashRequestOptions) => post<SetSignatureRequest, SetSignatureResponse>("/2.0/cash/set-signature", request, {...options, rejectOnError: true })