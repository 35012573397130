import { ReactNode, Suspense, useEffect, useMemo, useState } from 'react'

import { AppContext, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { Provider as DesignSystemProvider } from '@cash-design-system/react'
import { FaviconTags } from '@cash-web/favicon'
import { isServerEnvironment } from '@cash-web/shared-util-environment'

import './styles.css'

import { MainLayout } from '../layouts/MainLayout'
import {
  AppRegionProvider,
  AuthProtection,
  AuthProvider,
  Head,
  PageLoader,
  PortalIdentifier,
  TerminalError,
} from '../src/components'
import { StorePersistenceProvider } from '../src/components/StorePersistenceProvider'
import { CheckoutComponent, LocationContexts } from '../src/model'
import { persistor, store } from '../src/state'
import { AssociatedRequestDetails } from '../src/state/services/backend/types'
import logger from '../src/utils/clientLogger'
import { useDarkMode } from '../src/utils/darkMode'
import { getLocationContext } from '../src/utils/locationContext'
import setupI18n from '../src/utils/setup-i18n'

interface CustomAppProps<P> extends AppProps<P> {
  Component: CheckoutComponent
  skipSessionAuth?: true | null
}

interface PageProps {
  associatedDetails?: AssociatedRequestDetails
  referer?: string
  locationContext?: LocationContexts
  skipSessionAuth?: boolean
}

const EmptyComponent = ({ children }: { children: ReactNode }) => <>{children}</>

function CheckoutApp({ Component, skipSessionAuth, pageProps = {} }: CustomAppProps<PageProps>) {
  const router = useRouter()

  const { facePilePageProps } = Component
  const {
    associatedDetails,
    referer,
    locationContext,
    skipSessionAuth: pageSkipSessionAuth,
    ...remainingPageProps
  } = pageProps

  const [routerState, setRouterState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  })
  const isDarkMode = useDarkMode()

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setRouterState(prevState => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }))
    }

    const handleRouteChangeEnd = () => {
      setRouterState(prevState => ({
        ...prevState,
        isRouteChanging: false,
      }))
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    router.events.on('routeChangeError', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
      router.events.off('routeChangeError', handleRouteChangeEnd)
    }
  }, [router.events])

  const ErrorBoundary = useMemo(() => {
    if (isServerEnvironment()) {
      return EmptyComponent
    }
    return logger.createErrorBoundary() || EmptyComponent
  }, [])

  // skip session auth if we're in a skip session auth location (e.g. SUP), or if the child component has instructed us to via props, or if the child component skips it via its prototype
  const shouldSkipSessionAuth = skipSessionAuth || pageSkipSessionAuth || Boolean(Component.skipSessionAuth)

  return (
    <Suspense>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <StorePersistenceProvider toPersist={{ associatedDetails, referer, locationContext }}>
            <Head />
            <FaviconTags />
            <AppRegionProvider>
              <DesignSystemProvider darkMode={isDarkMode}>
                <ErrorBoundary FallbackComponent={TerminalError}>
                  <PageLoader show={routerState.isRouteChanging} key={routerState.loadingKey} />
                  <main className="app">
                    <AuthProvider
                      actionTypes={associatedDetails?.actionTypes}
                      skipSessionAuth={shouldSkipSessionAuth}
                      requireAuth={Component.requireAuth}
                    >
                      <AuthProtection requireAuth={Component.requireAuth}>
                        <MainLayout {...facePilePageProps}>
                          <Component {...remainingPageProps} />
                        </MainLayout>
                      </AuthProtection>
                      <div id={PortalIdentifier.Modal} />
                    </AuthProvider>
                  </main>
                </ErrorBoundary>
              </DesignSystemProvider>
            </AppRegionProvider>
          </StorePersistenceProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  )
}

CheckoutApp.getInitialProps = async (appContext: AppContext) => {
  // Having this check here allows the session auth skipping flag to be synchronously available on every page
  const locationContext = getLocationContext(appContext.ctx)
  const skipSessionAuth = locationContext === LocationContexts.SUP

  return {
    skipSessionAuth,
  }
}

setupI18n()

export default CheckoutApp
