/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {OpenRedPocketEnvelopeRequest} from '@cash-web/protos/squareup/franklin/app/red_pocket_envelope.pb'
import {OpenRedPocketEnvelopeResponse} from '@cash-web/protos/squareup/franklin/app/red_pocket_envelope.pb'


export const openRedPocketEnvelope = (request:OpenRedPocketEnvelopeRequest, options?: CashRequestOptions) => post<OpenRedPocketEnvelopeRequest, OpenRedPocketEnvelopeResponse>("/2.0/cash/open-red-pocket-envelope", request, {...options, rejectOnError: true })