/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPagedSyncEntitiesRequest} from '@cash-web/protos/squareup/franklin/app/sync_entities.pb'
import {GetPagedSyncEntitiesResponse} from '@cash-web/protos/squareup/franklin/app/sync_entities.pb'


export const getPagedSyncEntities = (request:GetPagedSyncEntitiesRequest, options?: CashRequestOptions) => post<GetPagedSyncEntitiesRequest, GetPagedSyncEntitiesResponse>("/2.0/cash/get-paged-sync-entities", request, {...options, rejectOnError: true })