import { useEffect } from 'react'

import { WebViewViewApp } from '@squareup/customer-data-events-web'

import { useLogCashWebCdfEvent } from './useLogCashWebCdfEvent'

export function useWebViewViewApp() {
  const logCdfEvent = useLogCashWebCdfEvent({ waitForProfile: false })
  useEffect(() => {
    logCdfEvent(WebViewViewApp())
  }, [logCdfEvent])
}
