/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetLinkedAccountsRequest} from '@cash-web/protos/squareup/franklin/app/linked_account.pb'
import {GetLinkedAccountsResponse} from '@cash-web/protos/squareup/franklin/app/linked_account.pb'


export const getLinkedAccounts = (request:GetLinkedAccountsRequest, options?: CashRequestOptions) => post<GetLinkedAccountsRequest, GetLinkedAccountsResponse>("/2.0/cash/get-linked-accounts", request, {...options, rejectOnError: true })