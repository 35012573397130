import * as tokens from '@cash-design-system/tokens'

// Targets device width >= 760px
const tablet = `@media (min-width: ${tokens.BreakpointSmall})` as const

// Targets device width >= 1024px
const desktop = `@media (min-width: ${tokens.BreakpointMedium})` as const

// Targets device width >= 1440px
const widescreen = `@media (min-width: ${tokens.BreakpointLarge})` as const

const breakpoints = {
  tablet,
  desktop,
  widescreen,
} as const

export type ThemeBreakpoints = typeof breakpoints

export default breakpoints
