/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PostPlaidBankLinkingRequest} from '@cash-web/protos/squareup/franklin/app/plaid.pb'
import {PostPlaidBankLinkingResponse} from '@cash-web/protos/squareup/franklin/app/plaid.pb'


export const postPlaidBankLinking = (request:PostPlaidBankLinkingRequest, options?: CashRequestOptions) => post<PostPlaidBankLinkingRequest, PostPlaidBankLinkingResponse>("/2.0/cash/post-plaid-bank-linking", request, {...options, rejectOnError: true })