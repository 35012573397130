/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PingRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {PingResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const ping = (request:PingRequest, options?: CashRequestOptions) => post<PingRequest, PingResponse>("/2.0/cash/ping", request, {...options, rejectOnError: true })