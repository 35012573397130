/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillSponsoredAccountRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillSponsoredAccount.pb'
import {BackfillSponsoredAccountResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillSponsoredAccount.pb'


export const backfillSponsoredAccount = (request:BackfillSponsoredAccountRequest, options?: CashRequestOptions) => post<BackfillSponsoredAccountRequest, BackfillSponsoredAccountResponse>("/2.0/cash/backfill-sponsored-account", request, {...options, rejectOnError: true })