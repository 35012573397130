/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {FinishWebAuthnAuthenticationRequest} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'
import {FinishWebAuthnAuthenticationResponse} from '@cash-web/protos/squareup/cash/janus/api/WebAuthn.pb'


export const finishWebAuthnAuthentication = (request:FinishWebAuthnAuthenticationRequest, options?: CashRequestOptions) => post<FinishWebAuthnAuthenticationRequest, FinishWebAuthnAuthenticationResponse>("/cash-app/security/1.0/finish-webauthn-authentication", request, {...options, rejectOnError: true })