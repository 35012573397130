/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetExportInternalRequest} from '@cash-web/protos/squareup/cash/activityexport/api/v1/service.pb'
import {GetExportResponse} from '@cash-web/protos/squareup/cash/activityexport/api/v1/service.pb'


export const getExportInternal = (request:GetExportInternalRequest, options?: CashRequestOptions) => post<GetExportInternalRequest, GetExportResponse>("/2.0/cash/get-export-internal", request, {...options, rejectOnError: true })