/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ApproveCashAppPayRequest} from '@cash-web/protos/squareup/franklin/app/cash_app_pay.pb'
import {ApproveCashAppPayResponse} from '@cash-web/protos/squareup/franklin/app/cash_app_pay.pb'


export const approveCashAppPay = (request:ApproveCashAppPayRequest, options?: CashRequestOptions) => post<ApproveCashAppPayRequest, ApproveCashAppPayResponse>("/2.0/cash/approve-cash-app-pay", request, {...options, rejectOnError: true })