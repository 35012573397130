/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateAddBillerFlowRequest} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/CreateAddBillerFlow.pb'
import {CreateAddBillerFlowResponse} from '@cash-web/protos/squareup/cash/billy/api/v1_0/app/CreateAddBillerFlow.pb'


export const createAddBillerFlow = (request:CreateAddBillerFlowRequest, options?: CashRequestOptions) => post<CreateAddBillerFlowRequest, CreateAddBillerFlowResponse>("/cash-app/bills/v1.0/app/create-add-biller-flow", request, {...options, rejectOnError: true })