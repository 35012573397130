export type Middleware<Data, Context = unknown> = (data: Data, context?: Context) => Promise<Data> | Data

export type Pipe<Data, Context = unknown> = (data: Data, context?: Context) => Promise<Data>

export const pipe = <Data, Context = unknown>(...middlewares: Middleware<Data, Context>[]): Pipe<Data, Context> => {
  return async (data, context) => {
    let current = data
    for (const middleware of middlewares) {
      current = await middleware(current, context)
    }
    return current
  }
}
