import { ClientScenario } from '@cash-web/protos/squareup/franklin/api/client_scenario.pb'

import { Middleware } from '../pipe'
import { OptionalMiddlewareContextProps } from './OptionalMiddlewareContext'

type OptionalRequestHeaders = HeadersInit | (Record<string, string> & { 'x-recaptcha-token'?: string })

export type CashRequestOptions = {
  clientScenario?: ClientScenario
  flowToken?: string
  // temporarily adding this to slowly migrate away from looking at status
  rejectOnError?: boolean
  optionalMiddleware?: OptionalMiddlewareContextProps
  optionalRequestHeaders?: OptionalRequestHeaders
  signal?: AbortSignal | null
  keepalive?: boolean
}

export type CashApiRequest = RequestInit
export class HttpError<T = object> extends Error {
  readonly response?: {
    status: number
    body: string | T
  }

  constructor(message: string, status: number, body: string | T) {
    super(message)
    this.name = 'HttpError'
    this.response = { status, body }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CashRequest<T = any> = CashApiRequest & {
  // this will be removed before the request is sent
  // used to store the data in raw format before converting to json string
  data?: T
  path?: string
}
export type CashRequestContext = {
  options?: CashRequestOptions
  path?: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CashResponse<T = any> = T
export type CashResponseContext = {
  request?: CashRequest
  response: {
    url: string
    status: number
    statusText: string
    ok: boolean
  }
  options?: CashRequestOptions
}

export type CashRequestMiddleware = Middleware<CashRequest, CashRequestContext>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CashResponseMiddleware<T = any> = Middleware<CashResponse<T>, CashResponseContext>
