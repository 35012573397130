/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SaveUserViewedDirectDepositUpsellRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'
import {SaveUserViewedDirectDepositUpsellResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'


export const saveUserViewedDirectDepositUpsell = (request:SaveUserViewedDirectDepositUpsellRequest, options?: CashRequestOptions) => post<SaveUserViewedDirectDepositUpsellRequest, SaveUserViewedDirectDepositUpsellResponse>("/2.0/cash/save-user-viewed-direct-deposit-upsell", request, {...options, rejectOnError: true })