/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyEmailRequest} from '@cash-web/protos/squareup/franklin/app/email.pb'
import {VerifyEmailResponse} from '@cash-web/protos/squareup/franklin/app/email.pb'


export const verifyEmail = (request:VerifyEmailRequest, options?: CashRequestOptions) => post<VerifyEmailRequest, VerifyEmailResponse>("/2.0/cash/verify-email", request, {...options, rejectOnError: true })