/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SubmitBlockerRequest} from '@cash-web/protos/squareup/franklin/app/blocker.pb'
import {SubmitBlockerResponse} from '@cash-web/protos/squareup/franklin/app/blocker.pb'


export const submitBlocker = (request:SubmitBlockerRequest, options?: CashRequestOptions) => post<SubmitBlockerRequest, SubmitBlockerResponse>("/2.0/cash/submit-blocker", request, {...options, rejectOnError: true })