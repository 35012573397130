/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RecordRevokedBackupTagRequest} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'
import {RecordRevokedBackupTagResponse} from '@cash-web/protos/squareup/cash/janus/api/HashedBackupTagsProto.pb'


export const recordRevokedBackupTag = (request:RecordRevokedBackupTagRequest, options?: CashRequestOptions) => post<RecordRevokedBackupTagRequest, RecordRevokedBackupTagResponse>("/2.0/cash/record-revoked-backup-tag", request, {...options, rejectOnError: true })