/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetTaxInformationRequest} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'
import {SetTaxInformationResponse} from '@cash-web/protos/squareup/franklin/app/tax_information.pb'


export const setTaxInformation = (request:SetTaxInformationRequest, options?: CashRequestOptions) => post<SetTaxInformationRequest, SetTaxInformationResponse>("/2.0/cash/set-tax-information", request, {...options, rejectOnError: true })