import React from 'react'

import { Country } from '@cash-web/protos/squareup/common/countries.pb'
import { Region } from '@cash-web/protos/squareup/franklin/region.pb'

import { useAuth } from './hooks/useAuth'

export const useProfileCountry = () => {
  return useAuth().profile?.country_code
}

export const useProfileRegion: () => Region | undefined = () => {
  return useAuth().profile?.region
}

export const useRedirectNonUsCustomerTo = (href: string) => {
  const profileCountry = useProfileCountry()

  React.useEffect(() => {
    if (profileCountry !== undefined && profileCountry !== Country.US) {
      window.location.href = href
    }
  }, [profileCountry, href])
}

export const useRedirectIfNotInSupportedCountries = ({
  href,
  supportedCountries,
}: {
  href: string
  supportedCountries: ReadonlyArray<Country | string>
}) => {
  const profileCountry = useProfileCountry()

  React.useEffect(() => {
    if (profileCountry !== undefined && !supportedCountries.some(country => country === profileCountry)) {
      window.location.href = href
    }
  }, [profileCountry, href, supportedCountries])
}
