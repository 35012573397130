import Bugsnag from '@bugsnag/js'

import { Country } from '@cash-web/protos/squareup/common/countries.pb'

export type Region = `${Country}`

/*
 * We are only approved to use cookies and track customers in the US
 */
export function canTrack(regionCode?: Region): boolean {
  if (!regionCode) {
    Bugsnag.notify(new Error(`region missing in canTrack utility`))
  }
  if (regionCode === 'US') {
    return true
  }
  return false
}
