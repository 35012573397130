/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetIncomingRequestPolicyRequest} from '@cash-web/protos/squareup/franklin/app/incoming_request_policy.pb'
import {SetIncomingRequestPolicyResponse} from '@cash-web/protos/squareup/franklin/app/incoming_request_policy.pb'


export const setIncomingRequestPolicy = (request:SetIncomingRequestPolicyRequest, options?: CashRequestOptions) => post<SetIncomingRequestPolicyRequest, SetIncomingRequestPolicyResponse>("/2.0/cash/set-incoming-request-policy", request, {...options, rejectOnError: true })