/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetTransferRequest} from '@cash-web/protos/squareup/franklin/GetTransferRequestProto.pb'
import {GetTransferResponse} from '@cash-web/protos/squareup/franklin/GetTransferResponseProto.pb'


export const getTransfer = (request:GetTransferRequest, options?: CashRequestOptions) => post<GetTransferRequest, GetTransferResponse>("/2.0/cash/get-transfer", request, {...options, rejectOnError: true })