/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateSupportCaseRequest} from '@cash-web/protos/squareup/franklin/support.pb'
import {CreateSupportCaseResponse} from '@cash-web/protos/squareup/franklin/support.pb'


export const createSupportCase = (request:CreateSupportCaseRequest, options?: CashRequestOptions) => post<CreateSupportCaseRequest, CreateSupportCaseResponse>("/2.0/cash/create-support-case", request, {...options, rejectOnError: true })