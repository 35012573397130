/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RefreshInvoiceRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'
import {RefreshInvoiceResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'


export const refreshInvoice = (request:RefreshInvoiceRequest, options?: CashRequestOptions) => post<RefreshInvoiceRequest, RefreshInvoiceResponse>("/2.0/cash/refresh-invoice", request, {...options, rejectOnError: true })