/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AdminGetCustomerLogRequest} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'
import {AdminGetCustomerLogResponse} from '@cash-web/protos/squareup/cash/activity/api/v1/service.pb'


export const adminGetCustomerLog = (request:AdminGetCustomerLogRequest, options?: CashRequestOptions) => post<AdminGetCustomerLogRequest, AdminGetCustomerLogResponse>("/2.0/cash/admin-get-customer-log", request, {...options, rejectOnError: true })