/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillProductsRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillProducts.pb'
import {BackfillProductsResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillProducts.pb'


export const backfillProducts = (request:BackfillProductsRequest, options?: CashRequestOptions) => post<BackfillProductsRequest, BackfillProductsResponse>("/2.0/cash/backfill-products", request, {...options, rejectOnError: true })