/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ReportSupportFlowActivityRequest} from '@cash-web/protos/squareup/franklin/support.pb'
import {ReportSupportFlowActivityResponse} from '@cash-web/protos/squareup/franklin/support.pb'


export const reportSupportFlowActivity = (request:ReportSupportFlowActivityRequest, options?: CashRequestOptions) => post<ReportSupportFlowActivityRequest, ReportSupportFlowActivityResponse>("/2.0/cash/report-support-flow-activity", request, {...options, rejectOnError: true })