/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AddReactionRequest} from '@cash-web/protos/squareup/franklin/app/reaction.pb'
import {AddReactionResponse} from '@cash-web/protos/squareup/franklin/app/reaction.pb'


export const addReaction = (request:AddReactionRequest, options?: CashRequestOptions) => post<AddReactionRequest, AddReactionResponse>("/2.0/cash/add-reaction", request, {...options, rejectOnError: true })