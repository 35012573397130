/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSearchConfigRequest} from '@cash-web/protos/squareup/cash/customersearch/api/v2/SearchConfig.pb'
import {GetSearchConfigResponse} from '@cash-web/protos/squareup/cash/customersearch/api/v2/SearchConfig.pb'


export const getSearchConfig = (request:GetSearchConfigRequest, options?: CashRequestOptions) => post<GetSearchConfigRequest, GetSearchConfigResponse>("/2.0/cash/get-search-config", request, {...options, rejectOnError: true })