/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetFullNameRequest} from '@cash-web/protos/squareup/franklin/app/full_name.pb'
import {SetFullNameResponse} from '@cash-web/protos/squareup/franklin/app/full_name.pb'


export const setFullName = (request:SetFullNameRequest, options?: CashRequestOptions) => post<SetFullNameRequest, SetFullNameResponse>("/2.0/cash/set-full-name", request, {...options, rejectOnError: true })