/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {TriggerBalanceCheckRequest} from '@cash-web/protos/squareup/franklin/app/balance_check.pb'
import {TriggerBalanceCheckResponse} from '@cash-web/protos/squareup/franklin/app/balance_check.pb'


export const triggerBalanceCheck = (request:TriggerBalanceCheckRequest, options?: CashRequestOptions) => post<TriggerBalanceCheckRequest, TriggerBalanceCheckResponse>("/2.0/cash/trigger-balance-check", request, {...options, rejectOnError: true })