/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetTrustpilotUrlPayloadRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {GetTrustpilotUrlPayloadResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const getTrustpilotUrlPayload = (request:GetTrustpilotUrlPayloadRequest, options?: CashRequestOptions) => post<GetTrustpilotUrlPayloadRequest, GetTrustpilotUrlPayloadResponse>("/2.0/cash/get-trustpilot-url-payload", request, {...options, rejectOnError: true })