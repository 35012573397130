/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyPasscodeRequest} from '@cash-web/protos/squareup/franklin/app/passcode.pb'
import {VerifyPasscodeResponse} from '@cash-web/protos/squareup/franklin/app/passcode.pb'


export const verifyPasscode = (request:VerifyPasscodeRequest, options?: CashRequestOptions) => post<VerifyPasscodeRequest, VerifyPasscodeResponse>("/2.0/cash/verify-passcode", request, {...options, rejectOnError: true })