/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SaveUserAcknowledgedRiskAlertRequest} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'
import {SaveUserAcknowledgedRiskAlertResponse} from '@cash-web/protos/squareup/cash/paychecks/api/v1/paychecks_app_service.pb'


export const saveUserAcknowledgedRiskAlert = (request:SaveUserAcknowledgedRiskAlertRequest, options?: CashRequestOptions) => post<SaveUserAcknowledgedRiskAlertRequest, SaveUserAcknowledgedRiskAlertResponse>("/2.0/cash/save-user-acknowledged-risk-alert", request, {...options, rejectOnError: true })