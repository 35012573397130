/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RefundPaymentRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {RefundPaymentResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const refundPayment = (request:RefundPaymentRequest, options?: CashRequestOptions) => post<RefundPaymentRequest, RefundPaymentResponse>("/2.0/cash/refund-payment", request, {...options, rejectOnError: true })