import { css, Theme } from '@emotion/react'

export const getLinkStyle = (theme: Theme) => css`
  color: ${theme.colors.link};
  cursor: pointer;
  font-weight: ${theme.weights.medium};
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    :hover {
      color: ${theme.colors.primaryButtonHover};
    }
  }
  :active {
    color: ${theme.colors.primaryButtonActive};
  }
`

export const getWarningLinkStyle = (theme: Theme) => css`
  ${getLinkStyle(theme)};
  color: ${theme.colors.bannerTextWarn};
  text-decoration: underline;

  @media (hover: hover) {
    :hover {
      color: ${theme.colors.bannerTextWarn};
      opacity: 0.9;
      background: transparent;
    }
  }

  :active {
    color: ${theme.colors.bannerTextWarn};
    opacity: 0.7;
    background: transparent;
  }
`

export const getErrorLinkStyle = (theme: Theme, isBanner = true) => css`
  ${getLinkStyle(theme)};
  color: ${isBanner ? theme.colors.bannerTextError : theme.colors.error};
  text-decoration: underline;

  @media (hover: hover) {
    :hover {
      color: ${theme.colors.errorHover};
    }
  }

  :active {
    color: ${theme.colors.errorPressed};
  }
`

export const getMarkdownLinkStyle = (theme: Theme) => css`
  a {
    color: ${theme.colors.label};
    word-break: break-word;

    &:hover {
      color: ${theme.colors.secondaryLabel};
    }
  }
`
