import { useContext } from 'react'

import { AuthContext } from '../AuthContext'

/**
 * For use in components which may be used outside of a fully-authed session, but
 * which may want to use Auth'd values if they're available.
 */
export const useOptionalAuth = () => {
  return useContext(AuthContext)
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
