/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RemoveDevicesRequest} from '@cash-web/protos/squareup/cash/devicegrip/app.pb'
import {RemoveDevicesResponse} from '@cash-web/protos/squareup/cash/devicegrip/app.pb'


export const removeDevices = (request:RemoveDevicesRequest, options?: CashRequestOptions) => post<RemoveDevicesRequest, RemoveDevicesResponse>("/cash-app/devices/1.0/remove-devices", request, {...options, rejectOnError: true })