/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBrowseTagsRequest} from '@cash-web/protos/squareup/cash/customersearch/api/GetBrowseTags.pb'
import {GetBrowseTagsResponse} from '@cash-web/protos/squareup/cash/customersearch/api/GetBrowseTags.pb'


export const getBrowseTags = (request:GetBrowseTagsRequest, options?: CashRequestOptions) => post<GetBrowseTagsRequest, GetBrowseTagsResponse>("/2.0/cash/get-browse-tags", request, {...options, rejectOnError: true })