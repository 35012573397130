import { CashRequestMiddleware, CashResponseMiddleware } from './types'

export const csrfTokenRequest: CashRequestMiddleware = request => {
  if (typeof window !== 'undefined' && window.csrfToken && request.method !== 'GET') {
    const headers = request.headers as Record<string, string>
    headers['X-CSRF-Token'] = window.csrfToken
  }
  return request
}

export const csrfTokenResponse: CashResponseMiddleware = response => {
  const csrfToken = response.headers.get('X-CSRF-Token')

  if (typeof window !== 'undefined') {
    if (response.status === 401) {
      window.csrfToken = undefined
    } else if (csrfToken) {
      window.csrfToken = csrfToken
    }
  }

  return response
}
