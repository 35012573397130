import { CashRequestMiddleware } from './types'

/**
 *	Should be assumed present on all API requests made from, but not limited to, cash-web.
 *	Helpful for the deterministic detection of web requests regardless of the User-Agent value.
 */
export const cashWebHeaderRequest: CashRequestMiddleware = request => {
  request.headers = {
    ...request.headers,
    'Cash-Web-Request': 'true',
  }
  return request
}
