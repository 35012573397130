import { CashFaviconAttributes, CashFaviconOptions } from '../../types'
import { FaviconAsset, getAssetDefaults } from '../lib/util'

/**
 * Returns an object that conforms to the NextJS Metadat
 * object type that was introduced in NextJS v13.2.0.
 *
 * TODO: the function signature should be annotated with
 * the `Metadata` return type after upgrading to >= v13.2.0
 * in cash-web.
 */
export const iconMetadata = ({ include = {}, additionalAssets = [] }: CashFaviconOptions = {}) => {
  const { appleTouchIcon = {}, svg = {}, manifest = {}, ico = {} } = include

  const toIconMetadata = (attributes: Partial<CashFaviconAttributes>) => {
    const { href, ...rest } = attributes

    return {
      url: href,
      ...rest,
    }
  }

  return {
    icons: {
      icon: [
        svg && toIconMetadata({ ...getAssetDefaults(FaviconAsset.Svg), ...svg }),
        ico && toIconMetadata({ ...getAssetDefaults(FaviconAsset.Ico), ...ico }),
      ],
      ...(appleTouchIcon && {
        apple: toIconMetadata({ ...getAssetDefaults(FaviconAsset.AppleTouchIcon), ...appleTouchIcon }),
      }),
      ...(additionalAssets.length && { other: additionalAssets.map(attributes => toIconMetadata(attributes)) }),
    },
    ...(manifest && { manifest: toIconMetadata({ ...getAssetDefaults(FaviconAsset.Manifest), ...manifest }).url }),
  }
}
