/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RegisterSmsRequest} from '@cash-web/protos/squareup/franklin/app/sms.pb'
import {RegisterSmsResponse} from '@cash-web/protos/squareup/franklin/app/sms.pb'


export const registerSms = (request:RegisterSmsRequest, options?: CashRequestOptions) => post<RegisterSmsRequest, RegisterSmsResponse>("/2.0/cash/register-sms", request, {...options, rejectOnError: true })