/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {VerifyMagicLinkRequest} from '@cash-web/protos/squareup/franklin/app/magic_link.pb'
import {VerifyMagicLinkResponse} from '@cash-web/protos/squareup/franklin/app/magic_link.pb'


export const verifyMagicLink = (request:VerifyMagicLinkRequest, options?: CashRequestOptions) => post<VerifyMagicLinkRequest, VerifyMagicLinkResponse>("/2.0/cash/verify-magic-link", request, {...options, rejectOnError: true })