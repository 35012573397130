/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RegisterInvitationsRequest} from '@cash-web/protos/squareup/franklin/app/invitations.pb'
import {RegisterInvitationsResponse} from '@cash-web/protos/squareup/franklin/app/invitations.pb'


export const registerInvitations = (request:RegisterInvitationsRequest, options?: CashRequestOptions) => post<RegisterInvitationsRequest, RegisterInvitationsResponse>("/2.0/cash/register-invitations", request, {...options, rejectOnError: true })