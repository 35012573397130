/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CancelExportInternalRequest} from '@cash-web/protos/squareup/cash/activityexport/api/v1/service.pb'
import {CancelExportResponse} from '@cash-web/protos/squareup/cash/activityexport/api/v1/service.pb'


export const cancelExportInternal = (request:CancelExportInternalRequest, options?: CashRequestOptions) => post<CancelExportInternalRequest, CancelExportResponse>("/2.0/cash/cancel-export-internal", request, {...options, rejectOnError: true })