/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BulkAccountHolderIngestRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {BulkAccountHolderIngestResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const bulkAccountHolderIngest = (request:BulkAccountHolderIngestRequest, options?: CashRequestOptions) => post<BulkAccountHolderIngestRequest, BulkAccountHolderIngestResponse>("/2.0/cash/bulk-account-holder-ingest", request, {...options, rejectOnError: true })