import * as React from 'react'

import { useTheme } from '@emotion/react'

import * as tokens from '@cash-design-system/tokens'

import { DollarLogo } from '../../icons/logos/DollarLogo'
import { DollarLogoWithText } from '../../icons/logos/DollarLogoWithText'
import { EuroLogo } from '../../icons/logos/EuroLogo'
import { EuroLogoWithText } from '../../icons/logos/EuroLogoWithText'
import { PoundLogo } from '../../icons/logos/PoundLogo'
import { PoundLogoWithText } from '../../icons/logos/PoundLogoWithText'
import { TaxesLogoWithText } from '../../icons/logos/TaxesLogoWithText'
import { useArcadeMigrationFeatureFlag } from '../ArcadeComponentMigration/ArcadeComponentMigrationProvider'
import ArcadeLogo from './ArcadeLogo'

export type LogoSvgProps = {
  altText?: string
  logoColor: string
  textColor: string
  symbolColor: string
  solidCurrencySymbol?: boolean
} & React.SVGAttributes<SVGElement>

type Logo = (props: LogoSvgProps) => JSX.Element

export type SupportedRegion = 'US' | 'GB' | 'IE'
export const SupportedRegions = new Set<SupportedRegion>(['US', 'GB', 'IE'])
export type ColorVariants =
  | 'default'
  | 'white'
  | 'black'
  | 'investingPurple'
  | 'green'
  | 'greenLogoWhiteText'
  | 'greenLogoBlackText'
  | 'pinkCashAppCard'

export type LogoVariants = 'default' | 'taxes'

const regionToLogo: { [key in SupportedRegion]: { noText: Logo; withText: Logo } } = {
  US: {
    noText: DollarLogo,
    withText: DollarLogoWithText,
  },
  GB: {
    noText: PoundLogo,
    withText: PoundLogoWithText,
  },
  IE: {
    noText: EuroLogo,
    withText: EuroLogoWithText,
  },
} as const

export type LogoProps = {
  colorVariant?: ColorVariants
  region?: SupportedRegion
  variant?: LogoVariants
  withText?: boolean
} & Omit<LogoSvgProps, 'logoColor' | 'symbolColor' | 'textColor'>

const Logo = ({
  altText,
  colorVariant = 'default',
  variant = 'default',
  region = 'US',
  withText,
  solidCurrencySymbol,
  ...props
}: LogoProps) => {
  const theme = useTheme()
  const logoVariant = withText ? 'withText' : 'noText'
  let LogoComponent = regionToLogo[region][logoVariant]

  let logoColor: string = tokens.ColorTintGreenLight
  let textColor: string = tokens.ColorPaletteBlack
  let symbolColor: string = tokens.ColorPaletteWhite

  const shouldUseArcadeLogo = useArcadeMigrationFeatureFlag('logo')

  if (shouldUseArcadeLogo) {
    return (
      <ArcadeLogo
        variant={variant === 'taxes' ? 'taxes' : colorVariant}
        withText={withText}
        altText={altText}
        {...(variant === 'taxes' ? { height: 26 } : {})}
        {...props}
      />
    )
  }

  if (variant === 'taxes') {
    textColor = ''
    logoColor = theme.colors.taxesLogoIcon
    symbolColor = theme.colors.taxesLogoInnerIcon

    if (withText) {
      LogoComponent = TaxesLogoWithText
    }
  }

  if (colorVariant === 'white') {
    logoColor = tokens.ColorPaletteWhite
    textColor = tokens.ColorPaletteWhite
  } else if (colorVariant === 'black') {
    logoColor = tokens.ColorPaletteGray20
    textColor = tokens.ColorPaletteGray20
  } else if (colorVariant === 'investingPurple') {
    logoColor = tokens.ColorPaletteInvestingPurpleLight
    textColor = tokens.ColorPaletteInvestingPurpleLight
  } else if (colorVariant === 'green') {
    logoColor = tokens.ColorTintGreenLight
    textColor = tokens.ColorTintGreenLight
  } else if (colorVariant === 'greenLogoWhiteText') {
    logoColor = tokens.ColorTintGreenLight
    textColor = tokens.ColorPaletteWhite
  } else if (colorVariant === 'greenLogoBlackText') {
    logoColor = tokens.ColorTintGreenLight
    textColor = tokens.ColorPaletteGray20
  } else if (colorVariant === 'pinkCashAppCard') {
    logoColor = '#F50086' // not a design system color
    textColor = tokens.ColorPaletteWhite
  }

  return (
    <LogoComponent
      altText={altText}
      logoColor={logoColor}
      symbolColor={symbolColor}
      textColor={textColor}
      solidCurrencySymbol={solidCurrencySymbol}
      {...props}
    />
  )
}

export default Logo
