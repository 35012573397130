/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetLoginAttemptsRequest} from '@cash-web/protos/squareup/cash/janus/api/GetLoginAttempts.pb'
import {GetLoginAttemptsResponse} from '@cash-web/protos/squareup/cash/janus/api/GetLoginAttempts.pb'


export const getLoginAttempts = (request:GetLoginAttemptsRequest, options?: CashRequestOptions) => post<GetLoginAttemptsRequest, GetLoginAttemptsResponse>("/2.0/cash/get-login-attempts", request, {...options, rejectOnError: true })