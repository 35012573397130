/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {LinkCardRequest} from '@cash-web/protos/squareup/franklin/app/card.pb'
import {LinkCardResponse} from '@cash-web/protos/squareup/franklin/app/card.pb'


export const linkCard = (request:LinkCardRequest, options?: CashRequestOptions) => post<LinkCardRequest, LinkCardResponse>("/2.0/cash/link-card", request, {...options, rejectOnError: true })