/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateCryptocurrencyTransferRequest} from '@cash-web/protos/squareup/franklin/app/cryptocurrency_transfer.pb'
import {InitiateCryptocurrencyTransferResponse} from '@cash-web/protos/squareup/franklin/app/cryptocurrency_transfer.pb'


export const initiateCryptocurrencyTransfer = (request:InitiateCryptocurrencyTransferRequest, options?: CashRequestOptions) => post<InitiateCryptocurrencyTransferRequest, InitiateCryptocurrencyTransferResponse>("/2.0/cash/initiate-cryptocurrency-transfer", request, {...options, rejectOnError: true })