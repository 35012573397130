/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetHelpArticleRequest} from '@cash-web/protos/squareup/cashfrontend/api.pb'
import {GetHelpArticleResponse} from '@cash-web/protos/squareup/cashfrontend/api.pb'


export const getHelpArticle = (request:GetHelpArticleRequest, options?: CashRequestOptions) => post<GetHelpArticleRequest, GetHelpArticleResponse>("/2.0/cash/web/help-article", request, {...options, rejectOnError: true })