/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetStatementRequest} from '@cash-web/protos/squareup/franklin/app/statement.pb'
import {GetStatementResponse} from '@cash-web/protos/squareup/franklin/app/statement.pb'


export const getStatement = (request:GetStatementRequest, options?: CashRequestOptions) => post<GetStatementRequest, GetStatementResponse>("/2.0/cash/get-statement", request, {...options, rejectOnError: true })