/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateAccountRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {CreateAccountResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const createAccount = (request:CreateAccountRequest, options?: CashRequestOptions) => post<CreateAccountRequest, CreateAccountResponse>("/2.0/cash/create-account", request, {...options, rejectOnError: true })