/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetThreadedViewDataRequest} from '@cash-web/protos/squareup/franklin/app/threaded.pb'
import {GetThreadedViewDataResponse} from '@cash-web/protos/squareup/franklin/app/threaded.pb'


export const getThreadedViewData = (request:GetThreadedViewDataRequest, options?: CashRequestOptions) => post<GetThreadedViewDataRequest, GetThreadedViewDataResponse>("/2.0/cash/get-threaded-view-data", request, {...options, rejectOnError: true })