/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ApprovePlatformClientRequest} from '@cash-web/protos/squareup/franklin/app/platform_client.pb'
import {ApprovePlatformClientResponse} from '@cash-web/protos/squareup/franklin/app/platform_client.pb'


export const approvePlatformClient = (request:ApprovePlatformClientRequest, options?: CashRequestOptions) => post<ApprovePlatformClientRequest, ApprovePlatformClientResponse>("/2.0/cash/approve-platform-client", request, {...options, rejectOnError: true })