export const dropShadows = {
  topBar: {
    light: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.06)',
    dark: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.36)',
  },
  bottomBar: {
    light: '0 -0.125rem 0.25rem rgba(0, 0, 0, 0.06);',
    dark: '0 -0.125rem 0.25rem rgba(0, 0, 0, 0.36)',
  },
  tile: {
    light: '0 0.25rem 0.75rem rgba(0, 0, 0, 0.06), 0 0 0.25rem rgba(0, 0, 0, 0.12)',
    dark: '0 0 0.25rem rgba(0, 0, 0, 0.72), 0 0.25rem 0.75rem rgba(0, 0, 0, 0.36)',
  },
  dialog: {
    light: '0 0 0.75rem rgba(0, 0, 0, 0.12), 0 0.75rem 1.5rem rgba(0, 0, 0, 0.06)',
    dark: '0 0 0.75rem rgba(0, 0, 0, 0.72), 0 0.75rem 1.5rem rgba(0, 0, 0, 0.36)',
  },
  sheet: {
    light: '0 -0.3125rem 2.5rem rgba(0, 0, 0, 0.08)',
    dark: '0 -0.3125rem 2.5rem rgba(0, 0, 0, 0.48)',
  },
  footer: {
    light: '0 -0.125rem 0.25rem rgba(0, 0, 0, 0.3)',
    dark: '0 -0.125rem 0.25rem rgba(0, 0, 0, 0.36)',
  },
} as const

export type DropShadows = keyof typeof dropShadows
export type ThemeDropShadows = Record<DropShadows, number>

export default function getDropShadows(isDark: boolean) {
  return Object.entries(dropShadows).reduce<ThemeDropShadows>((acc, [name, { light, dark }]) => {
    return { ...acc, [name]: isDark ? dark : light }
  }, {} as ThemeDropShadows)
}
