/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ActivateDigitalWalletRequest} from '@cash-web/protos/squareup/franklin/app/digital_wallet_token.pb'
import {ActivateDigitalWalletResponse} from '@cash-web/protos/squareup/franklin/app/digital_wallet_token.pb'


export const activateDigitalWallet = (request:ActivateDigitalWalletRequest, options?: CashRequestOptions) => post<ActivateDigitalWalletRequest, ActivateDigitalWalletResponse>("/2.0/cash/activate-digital-wallet", request, {...options, rejectOnError: true })