/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CompleteApplePayProvisioningRequest} from '@cash-web/protos/squareup/franklin/app/apple_pay.pb'
import {CompleteApplePayProvisioningResponse} from '@cash-web/protos/squareup/franklin/app/apple_pay.pb'


export const completeApplePayProvisioning = (request:CompleteApplePayProvisioningRequest, options?: CashRequestOptions) => post<CompleteApplePayProvisioningRequest, CompleteApplePayProvisioningResponse>("/2.0/cash/complete-apple-pay-provisioning", request, {...options, rejectOnError: true })