/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateSearchConfigRequest} from '@cash-web/protos/squareup/cash/customersearch/api/v2/SearchConfig.pb'
import {UpdateSearchConfigResponse} from '@cash-web/protos/squareup/cash/customersearch/api/v2/SearchConfig.pb'


export const updateSearchConfig = (request:UpdateSearchConfigRequest, options?: CashRequestOptions) => post<UpdateSearchConfigRequest, UpdateSearchConfigResponse>("/2.0/cash/update-search-config", request, {...options, rejectOnError: true })