/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SelectActivityRequest} from '@cash-web/protos/squareup/franklin/app/activity.pb'
import {SelectActivityResponse} from '@cash-web/protos/squareup/franklin/app/activity.pb'


export const selectActivity = (request:SelectActivityRequest, options?: CashRequestOptions) => post<SelectActivityRequest, SelectActivityResponse>("/2.0/cash/select-activity", request, {...options, rejectOnError: true })