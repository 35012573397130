/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SetNearbyPrivacyPreferenceRequest} from '@cash-web/protos/squareup/franklin/app/nearby_privacy_preference.pb'
import {SetNearbyPrivacyPreferenceResponse} from '@cash-web/protos/squareup/franklin/app/nearby_privacy_preference.pb'


export const setNearbyPrivacyPreference = (request:SetNearbyPrivacyPreferenceRequest, options?: CashRequestOptions) => post<SetNearbyPrivacyPreferenceRequest, SetNearbyPrivacyPreferenceResponse>("/2.0/cash/set-nearby-privacy-preference", request, {...options, rejectOnError: true })