import { createContext, useContext, useMemo, useState } from 'react'

import { Country } from '@cash-web/protos/squareup/common/countries.pb'
import { useOptionalAuth } from '@cash-web/shared-auth-data-access-context'

export type Region = `${Country}`

// Shared type for an object containing all region configs for a given app, lib, feature, etc.
export type TypedRegionConfig<SingleRegionConfig> = { [key in Region]: SingleRegionConfig }

// Region Context
type RegionConfig = { [key in Region]: unknown }
type RegionContextType = {
  region?: Region
  regionConfig?: RegionConfig
  setContextRegion: React.Dispatch<React.SetStateAction<Region | undefined>>
  supportedRegions?: Region[]
}

const regionContext = createContext<RegionContextType | undefined>(undefined)

export type RegionProviderProps = {
  region?: Region
  regionConfig?: RegionConfig
  defaultRegion?: Region // used as a fallback region to support Login defaulting to US if no supported region found.
  supportedRegions?: Region[]
  children?: React.ReactNode
}

export function RegionProvider({
  region,
  regionConfig,
  defaultRegion,
  supportedRegions,
  children,
}: RegionProviderProps): React.ReactElement {
  // Region values come from the server so we need to validate they are of the correct type.
  if (region && !Object.values(Country).includes(region as Country)) {
    /**
     * The current behavior is that we default to the US experience for unsupported regions.
     * In the near future, we should consider a default configuration that could happen to share values with US,
     * but should stay separate so we don't accidentally show US experiences for regions where we should not be.
     *
     * Throwing an error here now would cause lots of errors for users that are currently
     * able to login via the US experience when in an unsupported region.
     */
  }

  // The "contextRegion" is generally information that is
  // given to us by the country code in an api response.
  const [contextRegion, setContextRegion] = useState<Region>()

  // Profile Region
  const auth = useOptionalAuth()
  const profile = auth ? auth.profile : undefined
  const profileRegion = profile?.country_code

  /**
   * Our apps vary in how they determine region. For example:
   *
   * Login uses a preferred region logic based on a hierarchy from highest to lowest:
   * 1) User's profile country
   * 2) Blocker response country (region stored in the context)
   * 3) IP-based country guess
   *
   * Account determines region based on:
   * 1) User's profile country
   *
   * Some of our other apps determine region based on:
   * 1) IP-based country guess
   *
   * In order to support all these, we will use the same hierachy across apps:
   * 1) User's profile country, if supported
   * 2) Blocker response country (region stored in the context), if supported
   * 3) IP-based country guess, if supported
   * 4) custom default region (fallback) any app can supply at initialization
   */

  const getValidRegion = (region?: Region) => {
    // If region is defined, check to see if supported regions list was passed in.
    if (region && supportedRegions) {
      return supportedRegions.includes(region) ? region : undefined
    }
    // otherwise return the region
    return region
  }

  const preferredRegion: Region | undefined =
    getValidRegion(profileRegion) || getValidRegion(contextRegion) || getValidRegion(region) || defaultRegion

  if (!preferredRegion) {
    // eslint-disable-next-line no-console
    console.warn(
      'No region was found. A region (currently `country_code` until we migrate to `region`) must be provided from the profile,' +
        'set on the region context, or provided on RegionProvider initialization as: ' +
        'region from window variable (region prop) or a default region (defaultRegion prop).'
    )
  }

  const value = useMemo(() => {
    return {
      region: preferredRegion,
      regionConfig,
      supportedRegions,
      setContextRegion,
    }
  }, [preferredRegion, regionConfig, supportedRegions])

  const RegionContext = regionContext

  return <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
}

/**
 * Returns a country code, probably from the region guess, but this
 * is not guaranteed.
 */
export function useCountryFromRegionProvider(): Region | undefined {
  const context = useContext(regionContext)

  if (!context) {
    throw Error('useRegion: must be used within a RegionProvider')
  }

  return context.region
}

/**
 * Returns a country code. Yes, the naming is bad.
 *
 * @deprecated Switch to useCountryFromRegionProvider(), which is more accurately named
 *             and inspires a bit more fear.
 */
export function useRegion(): Region | undefined {
  return useCountryFromRegionProvider()
}

/**
 * All region configurations are memoized and available from the Provider.
 * This method returns a single region configuration from the configuration object.
 *
 * @param customRegion, you can specify a region configuration or the default will be the current region
 * @returns a single region configuration
 */
export function useRegionConfig<SingleRegionConfig>(customRegion?: Region): SingleRegionConfig {
  const context = useContext(regionContext)

  if (!context) {
    throw Error('useRegionConfig: must be used within a RegionProvider')
  }

  // If choosing a custom region config, pass in the custom region
  if (!context.regionConfig) {
    throw Error('useRegionConfig: RegionProvider was initialized without a region config')
  } else {
    if (customRegion) {
      if (context.regionConfig?.[customRegion] === undefined) {
        throw Error('useRegionConfig: must pass in a supported region for your configuration')
      }
      return context.regionConfig?.[customRegion] as SingleRegionConfig
    }
    // Otherwise, the region config from the context region will be returned
    return context.regionConfig[context.region as Region] as SingleRegionConfig
  }
}

export function useSetContextRegion() {
  const context = useContext(regionContext)

  if (!context) {
    throw Error(`useSetContextRegion: must be used within a useRegionProvider`)
  }

  return context.setContextRegion
}
