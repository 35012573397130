/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAppMessageRequest} from '@cash-web/protos/squareup/franklin/app/app_message.pb'
import {GetAppMessageResponse} from '@cash-web/protos/squareup/franklin/app/app_message.pb'


export const getAppMessage = (request:GetAppMessageRequest, options?: CashRequestOptions) => post<GetAppMessageRequest, GetAppMessageResponse>("/2.0/cash/get-app-message", request, {...options, rejectOnError: true })