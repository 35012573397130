/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateGroupOrderParticipantRequest} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'
import {UpdateGroupOrderParticipantResponse} from '@cash-web/protos/squareup/cash/local/client/v1/cash_app_local_client_service.pb'


export const updateGroupOrderParticipant = (request:UpdateGroupOrderParticipantRequest, options?: CashRequestOptions) => post<UpdateGroupOrderParticipantRequest, UpdateGroupOrderParticipantResponse>("/2.0/cash/update-group-order-participant", request, {...options, rejectOnError: true })