/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetBusinessesRequest} from '@cash-web/protos/squareup/cash/customersearch/api/GetBusinesses.pb'
import {GetBusinessesResponse} from '@cash-web/protos/squareup/cash/customersearch/api/GetBusinesses.pb'


export const getBusinesses = (request:GetBusinessesRequest, options?: CashRequestOptions) => post<GetBusinessesRequest, GetBusinessesResponse>("/2.0/cash/get-businesses", request, {...options, rejectOnError: true })