/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ShareReceiptRequest} from '@cash-web/protos/squareup/franklin/app/share_receipt.pb'
import {ShareReceiptResponse} from '@cash-web/protos/squareup/franklin/app/share_receipt.pb'


export const shareReceipt = (request:ShareReceiptRequest, options?: CashRequestOptions) => post<ShareReceiptRequest, ShareReceiptResponse>("/2.0/cash/share-receipt", request, {...options, rejectOnError: true })