import React from 'react'

import { OptionalMiddlewareProvider } from './OptionalMiddlewareProvider'
import { CashRequestMiddleware } from './types'

export const AddPaymentTokensToRequestContextMiddleware = ({
  paymentTokens,
  children,
}: React.PropsWithChildren<{ paymentTokens: string[] }>) => {
  const paymentTokensMiddleware: CashRequestMiddleware = request => {
    if (request.method !== 'GET') {
      request.data.request_context = {
        ...request.data.request_context,
        payment_tokens: paymentTokens,
      }
    }
    return request
  }
  return (
    <OptionalMiddlewareProvider request={middleware => [paymentTokensMiddleware, ...middleware]}>
      {children}
    </OptionalMiddlewareProvider>
  )
}
