import { ResponseContext } from '@cash-web/protos/squareup/franklin/ResponseContextProto.pb'
import { StatusResult } from '@cash-web/protos/squareup/franklin/status_result.pb'

export type StatusResultListener = (statusResult: StatusResult | undefined) => void
let listeners: StatusResultListener[] = []
export const onStatusResultSet = (listener: StatusResultListener) => {
  listeners.push(listener)
  return () => {
    listeners = listeners.filter(l => l !== listener)
  }
}

export const statusResultResponse = (context?: { response_context?: ResponseContext }) => {
  const resultStatus = context?.response_context?.status_result
  if (resultStatus != null) {
    listeners.forEach(l => l(resultStatus))
  }
  return context
}
