/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCashtagStatusRequest} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'
import {GetCashtagStatusResponse} from '@cash-web/protos/squareup/franklin/app/cashtag.pb'


export const getCashtagStatus = (request:GetCashtagStatusRequest, options?: CashRequestOptions) => post<GetCashtagStatusRequest, GetCashtagStatusResponse>("/2.0/cash/get-cashtag-status", request, {...options, rejectOnError: true })