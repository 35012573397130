import { ArcadeLogoSvgProps } from '../../components/Logo/ArcadeLogo'

export const ArcadeDollarLogo = ({
  altText = 'Cash App Logo',
  logoColor,
  symbolColor = '',
  ...props
}: ArcadeLogoSvgProps) => {
  return (
    <svg
      role="img"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{altText}</title>
      {symbolColor ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1444 0C7.94286 0 6.3421 0 4.61943 0.533333C2.72899 1.21905 1.2349 2.71238 0.548876 4.60191C0 6.33905 0 7.93905 0 11.1543V20.8457C0 24.0762 0 25.661 0.533638 27.3829C1.21966 29.2724 2.71375 30.7657 4.60419 31.4514C6.3421 32 7.94286 32 11.1444 32H20.8404C24.0571 32 25.6579 32 27.3806 31.4514C29.271 30.7657 30.7651 29.2724 31.4511 27.3829C32 25.6457 32 24.0457 32 20.8457V11.1695C32 7.95429 32 6.35429 31.4511 4.63238C30.7803 2.74286 29.2864 1.24952 27.396 0.56381C25.6731 0 24.0876 0 20.8556 0H11.1444Z"
            fill={logoColor}
          />
          <path
            d="M17.3189 14.4457C20.6882 15.1619 22.2279 16.5638 22.2279 18.9257C22.2279 21.8819 19.8191 24.0609 16.0536 24.3047L15.6877 26.0571C15.6572 26.2247 15.5047 26.3466 15.3218 26.3466H12.4252C12.1813 26.3466 12.0136 26.1181 12.0594 25.8895L12.5167 23.939C10.6567 23.4057 9.14736 22.3695 8.27848 21.1047C8.17181 20.9371 8.20229 20.7238 8.35467 20.6019L10.367 19.0323C10.5348 18.8952 10.7786 18.9409 10.9007 19.1085C11.9678 20.6019 13.6143 21.4857 15.5963 21.4857C17.38 21.4857 18.7216 20.6171 18.7216 19.3676C18.7216 18.4076 18.0508 17.9657 15.7793 17.4933C11.907 16.6552 10.3672 15.2228 10.3672 12.8609C10.3672 10.1181 12.6692 8.06092 16.1451 7.78663L16.5263 5.9733C16.5567 5.80568 16.7093 5.68378 16.8921 5.68378H19.743C19.9717 5.68378 20.1546 5.89711 20.1089 6.12568L19.6668 8.15235C21.1609 8.60949 22.3804 9.43235 23.1428 10.4533C23.2647 10.6057 23.2342 10.8343 23.0818 10.9562L21.2371 12.4647C21.0693 12.6019 20.8408 12.5714 20.7187 12.4038C19.7735 11.2457 18.3099 10.5904 16.7091 10.5904C14.9253 10.5904 13.8125 11.3676 13.8125 12.4647C13.782 13.379 14.6511 13.8666 17.3189 14.4457Z"
            fill={symbolColor}
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.61943 0.533333C6.3421 0 7.94286 0 11.1444 0H20.8556C24.0876 0 25.6731 0 27.396 0.56381C29.2864 1.24952 30.7803 2.74286 31.4511 4.63238C32 6.35429 32 7.95429 32 11.1695V20.8457C32 24.0457 32 25.6457 31.4511 27.3829C30.7651 29.2724 29.271 30.7657 27.3806 31.4514C25.6579 32 24.0571 32 20.8404 32H11.1444C7.94286 32 6.3421 32 4.60419 31.4514C2.71375 30.7657 1.21966 29.2724 0.533638 27.3829C0 25.661 0 24.0762 0 20.8457V11.1543C0 7.93905 0 6.33905 0.548876 4.6019C1.2349 2.71238 2.72899 1.21905 4.61943 0.533333ZM22.2279 18.9257C22.2279 16.5638 20.6882 15.1619 17.3189 14.4457C14.6511 13.8666 13.782 13.379 13.8125 12.4647C13.8125 11.3676 14.9253 10.5904 16.7091 10.5904C18.3099 10.5904 19.7735 11.2457 20.7187 12.4038C20.8408 12.5714 21.0693 12.6019 21.2371 12.4647L23.0818 10.9562C23.2342 10.8343 23.2647 10.6057 23.1428 10.4533C22.3804 9.43235 21.1609 8.60949 19.6668 8.15235L20.1089 6.12568C20.1546 5.89711 19.9717 5.68378 19.743 5.68378H16.8921C16.7093 5.68378 16.5567 5.80568 16.5263 5.9733L16.1451 7.78663C12.6692 8.06092 10.3672 10.1181 10.3672 12.8609C10.3672 15.2228 11.907 16.6552 15.7793 17.4933C18.0508 17.9657 18.7216 18.4076 18.7216 19.3676C18.7216 20.6171 17.38 21.4857 15.5963 21.4857C13.6143 21.4857 11.9678 20.6019 10.9007 19.1085C10.7786 18.9409 10.5348 18.8952 10.367 19.0323L8.35467 20.6019C8.20229 20.7238 8.17181 20.9371 8.27848 21.1047C9.14736 22.3695 10.6567 23.4057 12.5167 23.939L12.0594 25.8895C12.0136 26.1181 12.1813 26.3466 12.4252 26.3466H15.3218C15.5047 26.3466 15.6572 26.2247 15.6877 26.0571L16.0536 24.3047C19.8191 24.0609 22.2279 21.8819 22.2279 18.9257Z"
          fill={logoColor}
        />
      )}
    </svg>
  )
}
