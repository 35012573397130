import { ResponseContext } from '@cash-web/protos/squareup/franklin/ResponseContextProto.pb'

import { CashResponseMiddleware } from './types'

export type ResponseContextListener = (statusResult: ResponseContext | undefined) => void

let listeners: ResponseContextListener[] = []

export const onResponseContextSet = (listener: ResponseContextListener) => {
  listeners.push(listener)
  return () => {
    listeners = listeners.filter(l => l !== listener)
  }
}
export const clearContext = () => {
  listeners.forEach(l => l(undefined))
}

export const responseContextResponse: CashResponseMiddleware = (
  response?: { response_context?: ResponseContext },
  context?: { request?: { data?: { flow_type?: string } } }
) => {
  const responseContext = response?.response_context
  if (responseContext != null && context?.request?.data?.flow_type !== 'POST_SIGN_IN') {
    listeners.forEach(l => l(responseContext))
  }
  return response
}
