import { createContext } from 'react'

import { CashRequestMiddleware, CashResponseMiddleware } from './types'

export type OptionalMiddlewareRequest = (middleware: CashRequestMiddleware[]) => CashRequestMiddleware[]
export type OptionalMiddlewareResponse = (middleware: CashResponseMiddleware[]) => CashResponseMiddleware[]

export type OptionalMiddlewareContextProps = {
  response?: OptionalMiddlewareResponse[]
  request?: OptionalMiddlewareRequest[]
}

export const OptionalMiddlewareContext = createContext<OptionalMiddlewareContextProps>({})
