/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSettingsPageDetailsRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {GetSettingsPageDetailsResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const getSettingsPageDetails = (request:GetSettingsPageDetailsRequest, options?: CashRequestOptions) => post<GetSettingsPageDetailsRequest, GetSettingsPageDetailsResponse>("/2.0/cash/get-settings-page-details", request, {...options, rejectOnError: true })