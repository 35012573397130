/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetClaimablePaymentRequest} from '@cash-web/protos/squareup/franklin/app/payment.pb'
import {GetClaimablePaymentResponse} from '@cash-web/protos/squareup/franklin/app/payment.pb'


export const getClaimablePayment = (request:GetClaimablePaymentRequest, options?: CashRequestOptions) => post<GetClaimablePaymentRequest, GetClaimablePaymentResponse>("/2.0/cash/get-claimable-payment", request, {...options, rejectOnError: true })