const weights = {
  regular: 400,
  medium: 500,
  bold: 700,
} as const

type Weights = keyof typeof weights
export type ThemeWeights = Record<Weights, number>

export default weights
