/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetMainAccountRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {GetMainAccountResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const getMainAccountInternal = (request:GetMainAccountRequest, options?: CashRequestOptions) => post<GetMainAccountRequest, GetMainAccountResponse>("/2.0/cash/get-main-account-internal", request, {...options, rejectOnError: true })