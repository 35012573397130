/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ValidateLightningDepositRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'
import {ValidateLightningDepositResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/deposits/public.pb'


export const validateLightningDeposit = (request:ValidateLightningDepositRequest, options?: CashRequestOptions) => post<ValidateLightningDepositRequest, ValidateLightningDepositResponse>("/2.0/cash/validate-lightning-deposit", request, {...options, rejectOnError: true })