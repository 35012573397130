/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {BackfillOffersRequest} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillOffers.pb'
import {BackfillOffersResponse} from '@cash-web/protos/squareup/cash/customersearch/api/BackfillOffers.pb'


export const backfillOffers = (request:BackfillOffersRequest, options?: CashRequestOptions) => post<BackfillOffersRequest, BackfillOffersResponse>("/2.0/cash/backfill-offers", request, {...options, rejectOnError: true })