/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {IssueAuthenticatedWebSessionRequest} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'
import {IssueAuthenticatedWebSessionResponse} from '@cash-web/protos/squareup/cash/janus/api/JanusService.pb'


export const issueAuthenticatedWebSession = (request:IssueAuthenticatedWebSessionRequest, options?: CashRequestOptions) => post<IssueAuthenticatedWebSessionRequest, IssueAuthenticatedWebSessionResponse>("/cash-app/security/1.0/issue-authenticated-web-session", request, {...options, rejectOnError: true })