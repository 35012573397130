/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CheckDepositRequest} from '@cash-web/protos/squareup/franklin/rpc/check_deposit.pb'
import {CheckDepositResponse} from '@cash-web/protos/squareup/franklin/rpc/check_deposit.pb'


export const depositCheck = (request:CheckDepositRequest, options?: CashRequestOptions) => post<CheckDepositRequest, CheckDepositResponse>("/2.0/cash/deposit-check", request, {...options, rejectOnError: true })