/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetLocationPermissionRequest} from '@cash-web/protos/squareup/franklin/app/location_permission.pb'
import {GetLocationPermissionResponse} from '@cash-web/protos/squareup/franklin/app/location_permission.pb'


export const getLocationPermission = (request:GetLocationPermissionRequest, options?: CashRequestOptions) => post<GetLocationPermissionRequest, GetLocationPermissionResponse>("/2.0/cash/get-location-permission", request, {...options, rejectOnError: true })