/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCurrentHoldingsRequest} from '@cash-web/protos/squareup/cash/portfolios/service.pb'
import {GetCurrentHoldingsResponse} from '@cash-web/protos/squareup/cash/portfolios/service.pb'


export const getCurrentHoldings = (request:GetCurrentHoldingsRequest, options?: CashRequestOptions) => post<GetCurrentHoldingsRequest, GetCurrentHoldingsResponse>("/2.0/cash/get-current-holdings", request, {...options, rejectOnError: true })